import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import AccountsList from './AccountsList';

export default function LeftSideBar(props) {
  const {
    windowWidth, handleClickOutside, hideMenu, showMenu,
  } = props;

  const profile = useSelector((store) => store.profileInfo.profile);
  const fixSidebar = windowWidth > 1600;

  useEffect(() => {
    window.addEventListener('click', handleClickOutside, false);

    return () => window.removeEventListener('click', handleClickOutside, false);
  }, []);

  return showMenu || fixSidebar ? (
    <div className="left-sidebar">
      <div className="user-block">
        {!fixSidebar && (
          <span
            className="button-hide__sideBar"
            onClick={hideMenu}
          >
            <i className="fa fa-chevron-left" aria-hidden="true" />
          </span>
        )}
        {profile.photo
          ? <Link to="/profile" className="user-block__photo" style={{ backgroundImage: `url("${profile.photo}")`, borderRadius: '50%' }} />
          : <Link to="/profile" className="user-block__photo" style={{ backgroundImage: 'url("./images/photo-white.svg")' }} />}
        {profile.email && (
          <Link to="/profile" className="user-block__name">
            {profile.fio || profile.email}
          </Link>
        )}
      </div>
      <ul className="user-menu left-sidebar__menu">
        {/* <li className="user-menu__item"> */}
        {/*  <NavLink */}
        {/*    to="/profile" */}
        {/*    className="user-menu__link" */}
        {/*    activeClassName="user-menu__link_active" */}
        {/*    onClick={hideMenu} */}
        {/*  > */}
        {/*    <i className="fa fa-user" /> */}
        {/*    &nbsp; Профиль */}
        {/*  </NavLink> */}
        {/* </li> */}
        <li className="user-menu__item">
          <NavLink
            to="/categories"
            className="user-menu__link"
            activeClassName="user-menu__link_active"
            onClick={hideMenu}
          >
            <i className="fa fa-list-ul" />
            &nbsp; Категории
          </NavLink>
        </li>
        <li className="user-menu__item">
          <NavLink
            to="/corrections"
            className="user-menu__link"
            activeClassName="user-menu__link_active"
            onClick={hideMenu}
          >
            <i className="fa fa-edit" />
            &nbsp; Корректировки
          </NavLink>
        </li>
      </ul>
      <ul className="user-menu additional-menu">
        <li className="user-menu__item">
          <NavLink
            exact
            to="/"
            className="user-menu__link"
            activeClassName="user-menu__link_active"
            onClick={hideMenu}
          >
            <i className="fa fa-book" aria-hidden="true" />
            &nbsp; Журнал операций
          </NavLink>
        </li>
        <li className="user-menu__item">
          <NavLink
            exact
            to="/reports"
            className="user-menu__link"
            activeClassName="user-menu__link_active"
            onClick={hideMenu}
          >
            <i className="fa fa-pie-chart" aria-hidden="true" />
            &nbsp; Отчет
          </NavLink>
        </li>
        <li className="user-menu__item">
          <NavLink
            exact
            to="/calendar"
            className="user-menu__link"
            activeClassName="user-menu__link_active"
            onClick={hideMenu}
          >
            <i className="fa fa-calendar" aria-hidden="true" />
            &nbsp; Календарь
          </NavLink>
        </li>
      </ul>
      <AccountsList />
    </div>
  ) : null;
}
