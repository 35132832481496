// import logger from 'redux-logger'; // события action в консоли
import { createStore, applyMiddleware } from 'redux'; // создание стор
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk'; // возможность дебажить и видеть дерево redux
import { composeWithDevTools } from '@redux-devtools/extension'; // улучшенный метод compose с добавлением devTools ко всему что передано

import reducer from './reducers';

let logoutRequest = false;

const actionListener = (store) => (next) => (action) => {
  if ((action.type === '@@redux-form/CHANGE' || action.type === '@@redux-form/DESTROY') && (store.getState().user.errorMessage || store.getState().user.errors)) store.dispatch({ type: 'USER_HIDE_MESSAGE' });
  if (action.type === 'USER_LOGIN_SUCCESS') logoutRequest = false;

  if (!localStorage.getItem('jwt') && store.getState().user.token && !logoutRequest) {
    logoutRequest = true;
    return store.dispatch({ type: 'USER_LOGOUT_SUCCESS' });
  }

  return next(action);
};

const middlewares = applyMiddleware(
  // logger,
  promise,
  thunk,
  actionListener,
);

export default createStore(reducer, composeWithDevTools(middlewares));
