import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  fetchExpenseDonutTurnover,
  fetchProfitDonutTurnover,
  hideDonutTurnover,
} from '../../../actions/donutTurnoverActions';
import {
  fetchLineTurnover,
  hideLineTurnover,
} from '../../../actions/lineTurnoverActions';
import DonutChart from '../../../components/DonutChart/DonutChart';
import LineChart from '../../../components/LineChart/LineChart';

const periodFilter = [
  {
    from: moment().format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    name: 'Сегодня',
    nameValue: 'today',
  },
  {
    from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    name: 'Неделя',
    nameValue: 'week',
  },
  {
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    name: 'Месяц',
    nameValue: 'month',
  },
  {
    from: moment().startOf('year').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    name: 'Год',
    nameValue: 'year',
  },
];

const MenuPeriod = styled.div`
            
`;

const MenuPeriodLine = styled.div`
            
`;

const LinkPeriod = styled.a`
  font-size: 16px;
  padding: 1px 5px 2px;
  border-radius: 5px;
  margin: 5px;
`;

const ButtonSwitch = styled.a`
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  width: 40px;
  height: 23px;
  position: relative;
  border-radius: 15px;
  display: inline-block;
  cursor: pointer;
  vertical-align: text-bottom;
`;

const Icon = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  
  &.fa-line-chart {
    left: 3px;
    top: 6px;
    font-size: 12px;
  }
  
  &.fa-pie-chart {
    font-size: 14px;
    top: 4px;
    left: 22px;
  }
`;

export default function Reports() {
  const dispatch = useDispatch();
  const donutTurnover = useSelector((store) => store.donutTurnover);
  const lineTurnover = useSelector((store) => store.lineTurnover);

  const [activeFilter, setActiveFilter] = useState('month');

  useEffect(() => {
    dispatch(fetchExpenseDonutTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
    dispatch(fetchProfitDonutTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
    dispatch(fetchLineTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
  }, []);

  const onFilteredChart = (period) => {
    setActiveFilter(period.nameValue);
    dispatch(fetchExpenseDonutTurnover({ from: period.from, to: period.to, period: period.nameValue }));
    dispatch(fetchProfitDonutTurnover({ from: period.from, to: period.to, period: period.nameValue }));
    dispatch(fetchLineTurnover({ from: period.from, to: period.to, period: period.nameValue }));
  };

  return (
    <div className="transactions-content_right">
      <div className="transactions-content">
        <div className="content-head">
          <span className="content-caption">Отчет</span>

          {!lineTurnover.active && (
            <MenuPeriod className="menu-period">
              {periodFilter.map((period, i) => (
                <LinkPeriod
                  key={Math.sqrt(i)}
                  href="#"
                  onClick={() => onFilteredChart(period)}
                  className={activeFilter === period.nameValue ? 'link-period_active' : null}
                >
                  {period.name}
                </LinkPeriod>
              ))}
              <ButtonSwitch href="#" onClick={() => dispatch(hideDonutTurnover({}))} className="buttonSwitch">
                <Icon className=" fa fa-pie-chart " aria-hidden="true" />
              </ButtonSwitch>
            </MenuPeriod>
          )}

          {lineTurnover.active && (
            <MenuPeriodLine className="menu-period__line">
              {periodFilter.map((period, i) => (
                <LinkPeriod
                  key={Math.sqrt(i)}
                  href="#"
                  onClick={() => onFilteredChart(period)}
                  className={activeFilter === period.nameValue ? 'link-period_active' : null}
                >
                  {period.name}
                </LinkPeriod>
              ))}
              <ButtonSwitch
                href="#"
                onClick={() => dispatch(hideLineTurnover({}))}
                className="buttonSwitch buttonSwitch__active"
              >
                <Icon className=" fa fa-line-chart " aria-hidden="true" />
              </ButtonSwitch>
            </MenuPeriodLine>
          )}
        </div>

        {!lineTurnover.active && (
          <>
            <DonutChart
              amount={donutTurnover.expenseSum}
              data={donutTurnover.expenseDonutTurnover}
            />
            <DonutChart
              amount={donutTurnover.profitSum}
              data={donutTurnover.profitDonutTurnover}
              isProfit
            />
          </>
        )}

        {lineTurnover.active && (
          <LineChart
            data={lineTurnover.lineTurnover}
            width={550}
          />
        )}
      </div>
    </div>
  );
}
