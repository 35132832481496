export default [
  {
    name: 'Январь',
    number: '01',
  },
  {
    name: 'Февраль',
    number: '02',
  },
  {
    name: 'Март',
    number: '03',
  },
  {
    name: 'Апрель',
    number: '04',
  },
  {
    name: 'Май',
    number: '05',
  },
  {
    name: 'Июнь',
    number: '06',
  },
  {
    name: 'Июль',
    number: '07',
  },
  {
    name: 'Август',
    number: '08',
  },
  {
    name: 'Сентябрь',
    number: '09',
  },
  {
    name: 'Октябрь',
    number: '10',
  },
  {
    name: 'Ноябрь',
    number: '11',
  },
  {
    name: 'Декабрь',
    number: '12',
  },
];
