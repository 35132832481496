import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import { fetchCategories } from '../../actions/categoriesActions';
import { fetchProfileInfo } from '../../actions/profileActions';
import { fetchWeekCalendarCarousel } from '../../actions/weekCalendarCarouselActions';
import Amount from '../../components/UI/Amount';
import getWeekDayName from '../../data/weekDayNames';

import Carousel from './components/Carousel';
import Reports from './components/Reports';
import Transactions from './components/Transactions';

const ActiveDay = styled.div`
  display: inline-block;
  width: 10px; 
  height: 10px;
  border-radius: 50%;
  background-color: rgb(0, 194, 100);
  position: relative;
`;

let weekDayFrom = moment().day(1).subtract(7, 'd').format('YYYY-MM-DD');
let weekDayTo = moment().day(7).add(7, 'd').format('YYYY-MM-DD');

export default function Home(props) {
  const {
    onDeleteTransactionClick,
    onEditTransactionClick,
    doQuery,
    reloadPieChart,
    showMenuFixed,
    windowHeight,
  } = props;

  const dispatch = useDispatch();
  const weekCalendar = useSelector((store) => store.weekCalendar);

  const history = useHistory();
  const { pathname, state } = useLocation();
  const wrapperRef = useRef(null);

  const [widthOneCell, setWidthOneCell] = useState(null);
  const [dateFromCalendarCell, setDateFromCalendarCell] = useState(state && state.transactionDay ? state.transactionDay : null);
  const [idFromCalendarCell, setIdFromCalendarCell] = useState(state && state.transactionId ? state.transactionId : null);
  const [targetId] = useState(state && state.transactionId ? state.transactionId : state && state.transactionDayFirstId ? state.transactionDayFirstId : null);
  const [hideMonth, setHideMonth] = useState(false);

  const updateCellSize = () => {
    const parent = wrapperRef.current;

    setWidthOneCell(((parent.offsetWidth) / 7).toFixed() - 1);

    if (window.innerWidth < 1720 && window.innerWidth > 1600) {
      setHideMonth(true);
    } else if (window.innerWidth < 1600 && window.innerWidth > 1350) {
      setHideMonth(false);
    } else if (window.innerWidth > 1720) {
      setHideMonth(false);
    } else if (window.innerWidth < 1350) {
      setHideMonth(true);
    }
  };

  useEffect(() => {
    if (state) history.replace(pathname, undefined);
    dispatch(fetchWeekCalendarCarousel(weekDayFrom, weekDayTo));
    dispatch(fetchCategories());
    dispatch(fetchProfileInfo());
    updateCellSize();
    window.addEventListener('resize', updateCellSize);

    return () => window.removeEventListener('resize', updateCellSize);
  }, []);

  const ScrollToDateReports = (dateCell) => {
    setDateFromCalendarCell(dateCell);
  };

  // Функция загрузки предыдущей недели для недельной карусели #redux connect
  const loadedTablePrev = () => {
    weekDayFrom = moment(weekDayFrom, 'YYYY-MM-DD').subtract(7, 'd').format('YYYY-MM-DD');
    weekDayTo = moment(weekDayTo, 'YYYY-MM-DD').subtract(7, 'd').format('YYYY-MM-DD');

    dispatch(fetchWeekCalendarCarousel(weekDayFrom, weekDayTo));
  };

  // Функция загрузки следующей недели для недельной карусели #redux connect
  const loadedTableNext = () => {
    weekDayFrom = moment(weekDayFrom, 'YYYY-MM-DD').add(7, 'd').format('YYYY-MM-DD');
    weekDayTo = moment(weekDayTo, 'YYYY-MM-DD').add(7, 'd').format('YYYY-MM-DD');

    dispatch(fetchWeekCalendarCarousel(weekDayFrom, weekDayTo));
  };

  function renderLastWeek() {
    const lastWeek = weekCalendar.weekCalendar.slice(0, 7);
    const widthCell = widthOneCell;

    return (lastWeek.map((day, i) => (
      <div
        className="table__cell_week"
        key={Math.sqrt(i)}
        style={{ minWidth: `${widthCell}px` }}
        data-date={day.date}
        onClick={() => ScrollToDateReports(day.date)}
      >
        <div className="calendar-cell calendar-cell__head week__head">

          {hideMonth === true
            ? (
              <div className="left">
                {day.date === moment().format('YYYY-MM-DD')
                  ? (
                    <span>
                      <ActiveDay />
                        &nbsp;
                      {moment(day.date).locale('ru').format('D.MM')}
                    </span>
                  )
                  : <span>{moment(day.date).locale('ru').format('D.MM')}</span>}
              </div>
            )
            : (
              <div className="left">
                {day.date === moment().format('YYYY-MM-DD')
                  ? (
                    <span>
                      <ActiveDay />
                        &nbsp;
                      {moment(day.date).locale('ru').format('D MMMM')}
                    </span>
                  )
                  : <span>{moment(day.date).locale('ru').format('D MMMM')}</span>}
              </div>
            )}
          <div className="right week-day__name">
            <span>{getWeekDayName(day.date)}</span>
          </div>
        </div>
        <div className="calendar-cell calendar-cell__content scroll-day__item">
          {day.expense === 0 ? ''
            : (
              <Amount
                amount={day.expense}
                className="expense-scroll__item"
                type="expense"
                showCurrency
              />
            )}
          {day.profit === 0 ? ''
            : (
              <Amount
                amount={day.profit}
                className="profit-scroll__item"
                type="profit"
                showCurrency
              />
            )}
        </div>
      </div>
    ))
    );
  }

  function renderCurrentWeek() {
    const currentWeek = weekCalendar.weekCalendar.slice(7, 14);
    const widthCell = widthOneCell;
    return (currentWeek.map((day, i) => (
      <div
        className="table__cell_week"
        key={Math.sqrt(i)}
        style={{ minWidth: `${widthCell}px` }}
        data-date={day.date}
        onClick={() => ScrollToDateReports(day.date)}
      >
        <div className="calendar-cell calendar-cell__head week__head">
          {hideMonth === true
            ? (
              <div className="left">
                {day.date === moment().format('YYYY-MM-DD')
                  ? (
                    <span>
                      <ActiveDay />
                        &nbsp;
                      {moment(day.date).locale('ru').format('D.MM')}
                    </span>
                  )
                  : <span>{moment(day.date).locale('ru').format('D.MM')}</span>}
              </div>
            )
            : (
              <div className="left">
                {day.date === moment().format('YYYY-MM-DD')
                  ? (
                    <span>
                      <ActiveDay />
                        &nbsp;
                      {moment(day.date).locale('ru').format('D MMMM')}
                    </span>
                  )
                  : <span>{moment(day.date).locale('ru').format('D MMMM')}</span>}
              </div>
            )}
          <div className="right week-day__name">
            <span>{getWeekDayName(day.date)}</span>
          </div>
        </div>
        <div className="calendar-cell calendar-cell__content scroll-day__item">
          {day.expense === 0 ? ''
            : (
              <Amount
                amount={day.expense}
                className="expense-scroll__item"
                type="expense"
                showCurrency
              />
            )}
          {day.profit === 0 ? ''
            : (
              <Amount
                amount={day.profit}
                className="profit-scroll__item"
                type="profit"
                showCurrency
              />
            )}
        </div>
      </div>
    ))
    );
  }

  function renderNextWeek() {
    const nextWeek = weekCalendar.weekCalendar.slice(14);
    const widthCell = widthOneCell;
    return (nextWeek.map((day, i) => (
      <div
        className="table__cell_week"
        key={Math.sqrt(i)}
        style={{ minWidth: `${widthCell}px` }}
        data-date={day.date}
        onClick={() => ScrollToDateReports(day.date)}
      >
        <div className="calendar-cell calendar-cell__head week__head">
          {hideMonth === true
            ? (
              <div className="left">
                {day.date === moment().format('YYYY-MM-DD')
                  ? (
                    <span>
                      <ActiveDay />
                        &nbsp;
                      {moment(day.date).locale('ru').format('D.MM')}
                    </span>
                  )
                  : <span>{moment(day.date).locale('ru').format('D.MM')}</span>}
              </div>
            )
            : (
              <div className="left">
                {day.date === moment().format('YYYY-MM-DD')
                  ? (
                    <span>
                      <ActiveDay />
                        &nbsp;
                      {moment(day.date).locale('ru').format('D MMMM')}
                    </span>
                  )
                  : <span>{moment(day.date).locale('ru').format('D MMMM')}</span>}
              </div>
            )}
          <div className="right week-day__name">
            <span>{getWeekDayName(day.date)}</span>
          </div>
        </div>
        <div className="calendar-cell calendar-cell__content scroll-day__item">
          {day.expense === 0 ? ''
            : (
              <Amount
                amount={day.expense}
                className="expense-scroll__item"
                type="expense"
                showCurrency
              />
            )}
          {day.profit === 0 ? ''
            : (
              <Amount
                amount={day.profit}
                className="profit-scroll__item"
                type="profit"
                showCurrency
              />
            )}
        </div>
      </div>
    ))
    );
  }

  return (
    <>
      <div className="week__scroll" ref={wrapperRef} id="week__scroll">
        <Carousel
          loadedTablePrev={loadedTablePrev}
          loadedTableNext={loadedTableNext}
          renderLastWeek={renderLastWeek}
          renderCurrentWeek={renderCurrentWeek}
          renderNextWeek={renderNextWeek}
        />
      </div>
      <div className="container_full-width transactions__app_wrap">
        <Transactions
          onDeleteTransactionClick={onDeleteTransactionClick}
          onEditTransactionClick={onEditTransactionClick}
          doQuery={doQuery}
          dateFromCalendarCell={dateFromCalendarCell}
          defaultDateFromCalendarCell={() => setDateFromCalendarCell(null)}
          idFromCalendarCell={idFromCalendarCell}
          defaultIdFromCalendarCell={() => setIdFromCalendarCell(null)}
          targetId={targetId}
        />

        <Reports
          reloadPieChart={reloadPieChart}
          showMenuFixed={showMenuFixed}
          windowHeight={windowHeight}
        />
      </div>
    </>
  );
}
