import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tree, { TreeNode } from 'rc-tree';

import { fetchAccounts } from '../../actions/accountsActions';
import { showCreateAccountCorrection, showDeleteAccountCorrection } from '../../actions/modalActions';

export default function Corrections() {
  const dispatch = useDispatch();
  const accounts = useSelector((store) => store.accounts.accounts);

  useEffect(() => {
    dispatch(fetchAccounts());
  }, []);

  const renderAccount = (account) => (
    <div className="categories__item">
      <span
        className="categories__icon"
      >
        <i className="fa fa-money" aria-hidden="true" />
      </span>
      {account.name}
      <div className="controls categories__controls">
        <span
          className="controls__btn add-account"
          onClick={() => dispatch(showCreateAccountCorrection(account))}
        >
          <i className="fa fa-plus" aria-hidden="true" />
        </span>
      </div>
    </div>
  );

  const renderCorrection = (account, correction) => (
    <div className="categories__item categories__item">
      {moment(correction.date).format('DD.MM.YYYY')}
      <div className="controls categories__controls">
        <span
          className="controls__btn delete-account"
          onClick={() => dispatch(showDeleteAccountCorrection({ account, correction }))}
        >
          <i className="fa fa-times" aria-hidden="true" />
        </span>
      </div>
      <div className="controls categories__type">{correction.balance}</div>
    </div>
  );

  return (
    <>
      <div className="categories-header">
        <span className="categories-header__title">
          <i className="fa fa-edit" />
          &nbsp;Корректировки
        </span>
        <button
          type="button"
          className="btn add-category__btn"
          onClick={() => dispatch(showCreateAccountCorrection())}
        >
          <i className="fa fa-plus-circle" aria-hidden="true" />
          &nbsp;&nbsp;Добавить корректировку
        </button>
      </div>

      {!!accounts.length && (
      <Tree
        defaultExpandAll
        showIcon={false}
        prefixCls="categories"
      >
        {accounts.map((account) => (
          <TreeNode
            key={account.id}
            title={renderAccount(account)}
          >
            {account.openingBalances && account.openingBalances.map((correction) => <TreeNode key={correction.id} title={renderCorrection(account, correction)} />)}
          </TreeNode>
        ))}
      </Tree>
      )}
    </>
  );
}
