import React from 'react';
import styled from 'styled-components';

const LoaderWrap = styled.span`
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loader = () => (
  <LoaderWrap><i className="fa fa-spinner fa-pulse fa-fw" /></LoaderWrap>
);

export default Loader;
