import _ from 'lodash';
import moment from 'moment';
import React, {
  Fragment, useEffect, useState,
} from 'react';
import DatePicker from 'react-datetime';
import {
  Field, change, reduxForm, reset,
} from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import 'moment/locale/ru';

import { fetchAccounts } from '../../../actions/accountsActions';
import { fetchCalendar } from '../../../actions/calendarActions';
import { fetchExpenseDonutTurnover, fetchProfitDonutTurnover } from '../../../actions/donutTurnoverActions';
import { fetchLineTurnover } from '../../../actions/lineTurnoverActions';
import {
  hideCreateTransaction, hideEditTransaction, showCreateAccount, showCreateCategory,
} from '../../../actions/modalActions';
import { createTransaction, editTransaction } from '../../../actions/transactionsActions';
import {
  setActiveAccount,
  setActiveType,
  clearActiveType,
  setTransitionType,
  clearTransitionType,
} from '../../../actions/formTransactionsActions';
import { fetchWeekCalendarCarousel } from '../../../actions/weekCalendarCarouselActions';
import { transactionTypes as types } from '../../../util/types';

import TextField from '../../UI/TextField';

const NoCategory = styled.span`
  display: inline-block;
  margin-left: 8px;
  color: #d5d5d5;
  cursor: default;
`;

const Balloon = styled.span`
  display: none;
  background-color: #fff;
  color: #000;
  border: 1px solid #d9e2e5;
  border-radius: 7px;
  padding: 5px 10px;
  width: 165px;
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
`;

const SpanNoItem = styled.span`
  font-size: 18px;
  background-color: #3e6573;
  color: #fff;
  border-radius: 10px;
  padding: 9px 8px;
  margin-top: 35px;
  display: inline-block;
  width: 180px;
  position: relative;
  text-align: center;
  cursor: pointer;
  
  &:hover {
    ${Balloon} {
      display: block;
    }
  }
`;

const SpanNoItemAccount = styled(SpanNoItem)`
  margin-top: 0;
  margin-bottom: 35px
`;

const TypeTransition = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  width: 380px;
  
  select {
    margin-bottom: 36px;
  }
`;

const validate = (values) => {
  const errors = {};

  if (!values.contractor_name) {
    errors.contractor_name = 'Обязательно для заполнения';
  }

  if (!values.amount) {
    errors.amount = 'Обязательно для заполнения';
  } else {
    if (values.amount.match('.')) {
      values.amount = values.amount.replace('.', ',');
    }
    if (!/^-?\d{1,18}(,\d{2})?$/.test(values.amount)) {
      errors.amount = 'Введите сумму в формате 100,00';
    }
  }

  return errors;
};

let dateBeforeChange = null;

function TransactionForm(props) {
  const {
    edit, handleSubmit, initialize, popularTransaction,
  } = props;

  const dispatch = useDispatch();
  const accounts = useSelector((store) => store.accounts.accounts);
  const categories = useSelector((store) => store.categories.categories);
  const accountsFiltered = useSelector((store) => store.formGlobal.accountsFiltered);
  const activeAccount = useSelector((store) => store.formGlobal.activeAccount);
  const activeType = useSelector((store) => store.formGlobal.activeType);
  const categoriesFiltered = useSelector((store) => store.formGlobal.categoriesFiltered);
  const filterCalendar = useSelector((store) => store.calendar.filterParam);
  const transactionDay = useSelector((store) => store.modalDialog.transactionDay);
  const transactionAccount = useSelector((store) => store.modalDialog.transactionAccount);
  const transactionEdit = useSelector((store) => store.modalDialog.transactionEdit);
  const transitionType = useSelector((store) => store.formGlobal.transitionType);
  const weekCalendar = useSelector((store) => store.weekCalendar);

  const [receiptFile, setReceiptFile] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAccountNotice, setShowAccountNotice] = useState(false);
  const [showCategoryNotice, setShowCategoryNotice] = useState(false);

  const currentTransaction = popularTransaction || transactionEdit;

  // Инициализация новой транзакции
  const setInitValues = (dayDate, accountID) => {
    initialize({
      accounts: accountID ? accountID.toString() : '',
      date: dayDate ? moment(dayDate) : moment(),
      formChange: false,
      type: '1',
    });

    if (+activeType !== 1) dispatch(clearActiveType());
    if (accounts.length) dispatch(setActiveAccount(accountID || accounts[0].id, accounts));
    if (categories.length) dispatch(setActiveType(1, categories));
    if (transitionType) dispatch(clearTransitionType());
  };

  // Инициализация редактируемой транзакции
  const setInitValuesEdit = (transactionEdit) => {
    initialize({
      accounts: transactionEdit.account_id.toString(),
      account_to: transactionEdit.account_receiver_id,
      amount: transactionEdit.amount,
      category: transactionEdit.category_id,
      comment: transactionEdit.comment,
      contractor_name: transactionEdit.contractor_name,
      date: transactionEdit.date
        ? moment(transactionEdit.date, 'DD.MM.YYYY')
        : moment(transactionEdit.dateOther, 'DD.MM.YYYY'),
      fetching: true,
      formChange: false,
      id: transactionEdit.id,
      type: transactionEdit.type.toString(),
    });

    if (transactionEdit.receipt) {
      setReceiptFile(
        {
          data: transactionEdit.receipt,
          fileName: transactionEdit.receipt.substring(transactionEdit.receipt.lastIndexOf('/') + 1),
        },
      );
    }

    if (accounts.length) dispatch(setActiveAccount(transactionEdit.account_id, accounts));
    if (categories.length) dispatch(setActiveType(transactionEdit.type, categories));
    transactionEdit.type === 3
      ? dispatch(setTransitionType())
      : dispatch(clearTransitionType());
  };

  // При создании первого счёта устанавливаем его активным
  useEffect(() => {
    if (accounts.length) dispatch(setActiveAccount(accounts[0].id, accounts));
  }, [accounts.length]);

  // При создании первой категории активного типа устанавливаем её активной
  useEffect(() => {
    if (categories.length) dispatch(setActiveType(activeType, categories));
  }, [categories.length]);

  // При смене активного счёта фильтруем счета для селекта
  useEffect(() => {
    if (accountsFiltered.length) dispatch(change('transaction', 'account_to', accountsFiltered[0].id));
  }, [activeAccount]);

  // При смене активного типа фильтруем категории для селекта
  useEffect(() => {
    if (categoriesFiltered.length && currentTransaction) {
      currentTransaction.type === activeType
        ? dispatch(change('transaction', 'category', currentTransaction.category_id))
        : dispatch(change('transaction', 'category', categoriesFiltered[0].id));
    }
  }, [activeType]);

  useEffect(() => {
    if (edit) {
      setInitValuesEdit(transactionEdit);
    } else if (transactionDay) {
      setInitValues(transactionDay.date);
    } else if (transactionAccount) {
      setInitValues(null, transactionAccount.id);
    } else {
      setInitValues();
    }
  }, []);

  const saveDateBeforeChange = (val) => {
    dateBeforeChange = val;
  };

  const toBase64 = (uploadedFile) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleFileChange = (e) => {
    const [uploadedFile] = e.target.files;

    if (uploadedFile) {
      setShowSpinner(true);
      toBase64(uploadedFile)
        .then(
          (data) => setReceiptFile({
            data,
            fileName: uploadedFile.name,
          }),
        )
        .finally(() => setShowSpinner(false));
    }
  };

  const handleFileDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setReceiptFile(null);
  };

  function fetchAllAccounts() {
    dispatch(fetchAccounts());
  }

  function fetchAllCalendar() {
    dispatch(fetchCalendar({ month: filterCalendar[0], year: filterCalendar[1] }));
  }

  function fetchCarousel() {
    dispatch(fetchWeekCalendarCarousel(weekCalendar.weekDayFrom, weekCalendar.weekDayTo));
  }

  function fetchDonutAndLineChart() {
    dispatch(fetchExpenseDonutTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
    dispatch(fetchProfitDonutTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
    dispatch(fetchLineTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
  }

  const handleSubmitForm = (values) => {
    if (!categoriesFiltered.length && !transitionType) {
      setShowCategoryNotice(true);
      return;
    }

    if (!accounts.length) {
      setShowAccountNotice(true);
      return;
    }

    const newCategories = [];
    categoriesFiltered.forEach((cat) => {
      newCategories.push(cat);
      if (cat.children.length) {
        cat.children.forEach((catChild) => {
          newCategories.push(catChild);
        });
      }
    });

    const account = values.accounts
      ? _.find(accounts, { id: +values.accounts })
      : _.find(accounts, { id: +accounts[0].id });
    const accountTo = !transitionType
      ? null
      : values.account_to
        ? _.find(accountsFiltered, { id: +values.account_to })
        : _.find(accountsFiltered, { id: +accountsFiltered[0].id });
    const category = transitionType
      ? null
      : values.category
        ? _.find(newCategories, { id: +values.category })
        : _.find(newCategories, { id: +newCategories[0].id });

    const transactionData = {
      account: account.id,
      account_id: account.id,
      account_name: account.name,
      account_receiver: !transitionType ? null : accountTo.id,
      account_receiver_id: !transitionType ? null : accountTo.id,
      account_to: !transitionType ? null : accountTo.id,
      amount: values.amount,
      category: transitionType ? null : category.id,
      category_id: transitionType ? null : category.id,
      category_name: transitionType ? null : category.name,
      comment: values.comment ? values.comment : '',
      contractor_name: transitionType ? accountTo.name : values.contractor_name,
      date: moment(values.date).format('DD.MM.YY'),
      dateOther: moment(values.date).format('DD.MM.YY'),
      dateTogether: moment(values.date).format('YYYY-MM-DD'),
      receiptFile,
      type: +values.type,
    };

    if (edit) {
      transactionData.id = values.id;
      dateBeforeChange = dateBeforeChange !== null ? moment(dateBeforeChange).format('DD.MM.YYYY') : null;
      dispatch(editTransaction(transactionData, transactionEdit, dateBeforeChange))
        .then(fetchAllAccounts)
        .then(fetchAllCalendar)
        .then(fetchCarousel)
        .then(fetchDonutAndLineChart);
      dispatch(hideEditTransaction());
    } else {
      dispatch(createTransaction(transactionData))
        .then(fetchAllAccounts)
        .then(fetchAllCalendar)
        .then(fetchCarousel)
        .then(fetchDonutAndLineChart);
      dispatch(hideCreateTransaction());
      dispatch(reset('transaction'));
    }
  };

  const options = categoriesFiltered.map((option) => {
    if (option.children.length) {
      return (
        <Fragment key={option.id}>
          <option
            key={option.id}
            value={option.id}
          >
            {option.name}
          </option>
          {option.children.map((child) => (
            <option
              key={child.id}
              value={child.id}
            >
                  &nbsp;&nbsp;&nbsp;&nbsp;
              {child.name}
            </option>
          ))}
        </Fragment>
      );
    }
    return (
      <option
        key={option.id}
        value={option.id}
      >
        {option.name}
      </option>
    );
  });

  const renderDatePicker = ({
    input, meta: { touched, error },
  }) => (
    <div>
      <DatePicker
        {...input}
        dateFormat="DD.MM.YYYY"
        timeFormat={false}
        closeOnSelect
        onFocus={() => saveDateBeforeChange(input.value)}
        selected={input.value ? moment(input.value) : null}
      />
      {touched && error && <span>{error}</span>}
    </div>
  );

  return (
    <form
      className="main-form"
      onSubmit={handleSubmit((values) => handleSubmitForm(values))}
    >
      <div className="form-group">
        <span className="form-group__type">Тип:</span>
        {types.map((type, i) => {
          if (i === 2) {
            if (accounts.length < 2) {
              return <NoCategory key={Math.sqrt(i)}>{type.label}</NoCategory>;
            }

            return (
              <label
                key={Math.sqrt(i)}
                htmlFor={`type-${i}`}
                className={`label-type type${type.value}`}
              >
                <Field
                  id={`type-${i}`}
                  component="input"
                  type="radio"
                  name="type"
                  value={type.value.toString()}
                  title={type.label}
                  onChange={() => dispatch(setTransitionType())}
                />
                <span>{type.label}</span>
              </label>
            );
          }
          return (
            <label
              key={Math.sqrt(i)}
              htmlFor={`type-${i}`}
              className={`label-type type${type.value}`}
            >
              <Field
                id={`type-${i}`}
                component="input"
                type="radio"
                name="type"
                value={type.value.toString()}
                title={type.label}
                onChange={() => {
                  dispatch(clearTransitionType());
                  dispatch(setActiveType(type.value, categories));
                }}
              />
              <span>{type.label}</span>
            </label>
          );
        })}
      </div>

      {transitionType
        ? (
          <TypeTransition>
            <span className="form-group__type">На счет:</span>
            <Field component="select" type="text" name="account_to">
              {accountsFiltered.map((account, i) => (
                <option key={Math.sqrt(i)} value={account.id.toString()} title={account.name}>
                  {account.name}
                </option>
              ))}
            </Field>
          </TypeTransition>
        )
        : (
          <div className="form-group wide-form">
            <Field
              name="contractor_name"
              type="text"
              required
              component={TextField}
              label="Получатель:"
            />
          </div>
        )}

      <div className="form-group">
        <span className="form-group__type">Дата:</span>
        <Field
          component={renderDatePicker}
          name="date"
        />
      </div>

      <div className="form-group">
        <Field
          name="amount"
          type="text"
          required
          component={TextField}
          label="Сумма, ₽:"
        />
      </div>

      {!categoriesFiltered.length && !transitionType
        ? (
          <SpanNoItem onClick={() => dispatch(showCreateCategory())}>
            Создать категорию
            <Balloon>Для того, что-бы добавить операцию, необходимо создать категорию.</Balloon>
          </SpanNoItem>
        )
        : (!transitionType && (
        <div className="form-group">
          <span className="form-group__type">Категория:</span>
          <Field
            name="category"
            component="select"
          >
            {options}
          </Field>
        </div>
        ))}
      {!transactionAccount && (
        <div className="form-group form-group__accounts">
          {!accounts.length
            ? (
              <SpanNoItemAccount onClick={() => dispatch(showCreateAccount())}>
                Создать счет
                <Balloon>Для того что-бы добавить операцию, необходимо создать счет</Balloon>
              </SpanNoItemAccount>
            )
            : (
              <>
                <span className="form-group__type type-accounts">Счёт:</span>
                {accounts.map((account, i) => (
                  <label
                    htmlFor={`account-${i}`}
                    key={Math.sqrt(i)}
                    className="account-name"
                  >
                    <Field
                      id={`account-${i}`}
                      type="radio"
                      component="input"
                      name="accounts"
                      value={account.id.toString()}
                      title={account.name}
                      onChange={() => dispatch(setActiveAccount(account.id, accounts))}
                      checked={+activeAccount === account.id}
                    />
                    <span>
                      {account.icon
                        ? <i className={`fa ${account.icon}`} style={{ color: account.color }} />
                        : ''}
                      {account.name}
                    </span>
                  </label>
                ))}
              </>
            )}
        </div>
      )}

      <div className="form-group form-group_file">
        <span className="form-group__type">Чек</span>
        {receiptFile ? (
          <span className="form-group__type">
            <a
              className="form-group__file-name"
              href={receiptFile.data}
              download={receiptFile.fileName}
              target="_blank"
              rel="noreferrer"
            >
              {receiptFile.fileName}
            </a>
            <span className="form-group__file-delete" onClick={handleFileDelete}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </span>
        ) : (
          <label htmlFor="file" className="form-group__type">
            {showSpinner
              ? <i className="fa fa-spinner fa-pulse fa-fw" />
              : (
                <>
                  <span className="form-group__file-name">Добавьте файл</span>
                  <input id="file" type="file" onChange={handleFileChange} />
                </>
              )}
          </label>
        )}
      </div>

      <div className="main-form__comment">
        <span className="form-group__type">Комментарий:</span>
        <Field component="textarea" type="text" name="comment" row={4} />
      </div>

      {showCategoryNotice && (
        <div className="main-form__notice">
          <p>Сначала нужно создать категорию</p>
          <button
            type="button"
            onClick={() => setShowCategoryNotice(false)}
          >
            Понятно
          </button>
        </div>
      )}

      {showAccountNotice && (
        <div className="main-form__notice">
          <p>Сначала нужно создать счёт</p>
          <button
            type="button"
            onClick={() => setShowAccountNotice(false)}
          >
            Понятно
          </button>
        </div>
      )}

      {!showCategoryNotice && !showAccountNotice && (
        <div className="main-form__buttons">
          <button type="submit" disabled={showSpinner}>{edit ? 'Сохранить' : 'Добавить'}</button>
              &nbsp;
          <button
            className="btn_cancel"
            type="button"
            onClick={() => {
              if (edit) {
                dispatch(hideEditTransaction());
              } else {
                dispatch(hideCreateTransaction());
              }
            }}
          >
            Отмена
          </button>
        </div>
      )}
    </form>
  );
}

export default reduxForm({
  form: 'transaction',
  validate,
  touchOnChange: true,
})(TransactionForm);
