const initialProfile = {
  email: '',
  emailConfirmed: false,
  fio: '',
  firstname: '',
  id: null,
  lastname: '',
  middlename: '',
  photo: null,
};

export default function reducer(state = {
  profile: initialProfile,
  fetching: false,
  fetched: false,
  error: null,
  showError: false,
  needToReset: false,
}, action) {
  switch (action.type) {
    case 'FETCH_PROFILE': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_PROFILE_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        profile: action.payload,
        error: null,
        showError: false,
      };
    }
    case 'FETCH_PROFILE_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
        showError: true,
      };
    }
    case 'UPDATE_PROFILE_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        profile: action.payload,
        error: null,
        showError: false,
      };
    }
    case 'UPDATE_PROFILE_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
        showError: true,
      };
    }
    case 'UPDATE_PROFILE_PHOTO_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        profile: {
          ...state.profile,
          photo: action.payload.path,
        },
      };
    }
    case 'UPDATE_PROFILE_PHOTO_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
        showError: true,
      };
    }
    case 'DELETE_PROFILE_PHOTO_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        profile: {
          ...state.profile,
          photo: null,
        },
      };
    }
    case 'DELETE_PROFILE_PHOTO_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
        showError: true,
      };
    }
    case 'RESET_PROFILE': {
      return {
        ...state,
        profile: initialProfile,
      };
    }
    case 'RESET_PROFILE_FORM': {
      return {
        ...state,
        needToReset: action.payload,
      };
    }
    case 'RESET_PROFILE_ERROR': {
      return {
        ...state,
        error: null,
        showError: false,
      };
    }
    default:
      return state;
  }
}
