import applicationAPI from '../api/applicationAPI';

export function fetchExpenseDonutTurnover(data) {
  const apiDonutTurnover = process.env.MOCK_VERSION
    ? '../../mock/proportional.json'
    : `${process.env.API_URL}/api/turnover/proportional/1${data && data.from && data.to ? `?period_from=${data.from}&period_to=${data.to}` : ''}`;

  return async (dispatch) => {
    await applicationAPI({
      method: 'GET',
      url: apiDonutTurnover,
    }).then((response) => {
      dispatch({ type: 'FETCH_EXPENSE-DONUT-TURNOVER_FULFILLED', payload: response.data, period: data.period });
    }).catch((err) => {
      dispatch({ type: 'FETCH_EXPENSE-DONUT-TURNOVER_REJECTED', payload: err, period: 'month' });
    });
  };
}

export function fetchProfitDonutTurnover(data) {
  const apiDonutTurnover = process.env.MOCK_VERSION
    ? '../../mock/proportional.json'
    : `${process.env.API_URL}/api/turnover/proportional/2${data && data.from && data.to ? `?period_from=${data.from}&period_to=${data.to}` : ''}`;

  return async (dispatch) => {
    await applicationAPI({
      method: 'GET',
      url: apiDonutTurnover,
    }).then((response) => {
      dispatch({ type: 'FETCH_PROFIT-DONUT-TURNOVER_FULFILLED', payload: response.data, period: data.period });
    }).catch((err) => {
      dispatch({ type: 'FETCH_PROFIT-DONUT-TURNOVER_REJECTED', payload: err, period: 'month' });
    });
  };
}

export function hideDonutTurnover() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_DONUT-TURNOVER' });
    dispatch({ type: 'SHOW_LINE-TURNOVER' });
  };
}
