import applicationAPI from '../api/applicationAPI';

export const fetchWeekCalendarCarousel = (weekDayFrom, weekDayTo) => {
  const apiWeekCalendarWeek = process.env.MOCK_VERSION ? '../../mock/calendarWeek.json' : `${process.env.API_URL}/api/turnover/week/${weekDayFrom}/${weekDayTo}`;

  return async (dispatch) => {
    dispatch({ type: 'SET_WEEK_DATES', payload: { weekDayFrom, weekDayTo } });
    dispatch({ type: 'FETCH_WEEK-CALENDAR' });
    await applicationAPI({
      url: apiWeekCalendarWeek,
      method: 'GET',
    }).then((response) => {
      dispatch({ type: 'FETCH_WEEK-CALENDAR_FULFILLED', payload: response.data });
    }).catch((err) => {
      dispatch({ type: 'FETCH_WEEK-CALENDAR_REJECTED', payload: err });
    });
  };
};

export default fetchWeekCalendarCarousel;
