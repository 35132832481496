export default function reducer(state = {
  windowHeight: 0,
  windowWidth: 0,
}, action) {
  switch (action.type) {
    case 'SET_WINDOW_HEIGHT': {
      return {
        ...state,
        windowHeight: action.payload,
      };
    }
    case 'SET_WINDOW_WIDTH': {
      return {
        ...state,
        windowWidth: action.payload,
      };
    }
    default:
      return state;
  }
}
