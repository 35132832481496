import React from 'react';
import Datetime from 'react-datetime';

import 'moment/locale/ru';

export default function DateInput(props) {
  const {
    title, value, name, onChangeValue,
  } = props;

  return (
    <div className="main-form__date-picker bg_white">
      {title && <label htmlFor="date">{title}</label>}
      <Datetime
        value={value}
        inputProps={{ name }}
        dateFormat="DD.MM.YYYY"
        timeFormat={false}
        closeOnSelect
        closeOnTab
        onChange={onChangeValue}
      />
    </div>
  );
}
