import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datetime';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { createAccountCorrection } from '../../../actions/accountsActions';
import { hideCreateAccountCorrection } from '../../../actions/modalActions';

import { Error } from '../../UI/Styled';
import TextField from '../../UI/TextField';

import 'moment/locale/ru';

const validate = (values) => {
  const errors = {};

  if (!values.date) {
    errors.date = 'Введите дату';
  } else if (moment(values.date).diff(moment()) > 0) {
    errors.date = 'Введите дату не позднее текущей';
  }

  if (!values.balance) {
    errors.balance = 'Введите сумму';
  } else {
    if (typeof values.balance === 'string') {
      if (values.balance.match('.')) {
        values.balance = values.balance.replace(/[.]/g, ',');
      }
    }
    if (!/^-?\d{1,18}(,\d{2})?$/.test(values.balance)) {
      errors.balance = 'Введите сумму в формате 100,00 или -100,00';
    }
  }

  return errors;
};

function CorrectionForm(props) {
  const { initialize, handleSubmit } = props;

  const dispatch = useDispatch();
  const accounts = useSelector((store) => store.accounts.accounts);
  const accountCorrection = useSelector((store) => store.modalDialog.accountCorrectionCreate);

  const setInitValues = () => {
    initialize({
      account: accountCorrection ? accountCorrection.id.toString() : null,
      date: moment(),
    });
  };

  useEffect(() => {
    setInitValues();
  }, []);

  const handleSubmitForm = (values) => {
    const account = values.account
      ? _.find(accounts, { id: +values.account })
      : _.find(accounts, { id: +accounts[0].id });

    const correctionData = {
      account: account.id,
      date: moment(values.date).format('YYYY-MM-DD'),
      balance: values.balance,
    };

    dispatch(createAccountCorrection(correctionData));
    dispatch(hideCreateAccountCorrection());
  };

  const renderDatePicker = ({
    input, meta: { touched, error },
  }) => (
    <>
      <DatePicker
        {...input}
        dateFormat="DD.MM.YYYY"
        timeFormat={false}
        closeOnSelect
        selected={input.value ? moment(input.value) : null}
      />
      {touched && (error && <Error>{error}</Error>)}
    </>
  );

  return (
    <form
      className="main-form center__button-form"
      onSubmit={handleSubmit((values) => handleSubmitForm(values))}
    >
      <div className="form-group">
        <span className="form-group__type">Счёт:</span>
        <Field
          component="select"
          type="text"
          name="account"
          disabled={accountCorrection}
        >
          {accounts.map((account) => <option key={account.id} value={account.id}>{account.name}</option>)}
        </Field>
      </div>

      <div className="form-group">
        <span className="form-group__type">Дата:</span>
        <Field
          component={renderDatePicker}
          name="date"
        />
      </div>

      <div className="form-group">
        <Field
          component={TextField}
          type="text"
          name="balance"
          label="Баланс:"
        />
      </div>

      <div className="main-form__buttons">
        <button type="submit">Добавить</button>
          &nbsp;
        <button
          className="btn_cancel"
          type="button"
          onClick={() => dispatch(hideCreateAccountCorrection())}
        >
          Отмена
        </button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'correction',
  validate,
})(CorrectionForm);
