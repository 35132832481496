import axios from 'axios';

import applicationAPI from '../api/applicationAPI';

let cancelTokenSource;

export function fetchTransactions(params, more) {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('Операция отменена из-за нового запроса');
  }
  cancelTokenSource = axios.CancelToken.source();
  return async (dispatch) => {
    dispatch({ type: 'FETCH_TRANSACTIONS' });
    await applicationAPI({
      method: 'GET',
      url: '/api/transactions/list',
      params: params || { page: 1, perPage: 15 },
      cancelToken: cancelTokenSource.token,
    }).then((response) => {
      if (more) {
        dispatch({ type: 'FETCH_TRANSACTIONS_MORE_FULFILLED', payload: response.data });
      } else {
        dispatch({ type: 'FETCH_TRANSACTIONS_FULFILLED', payload: response.data });
      }
    }).catch((error) => {
      if (axios.isCancel(error)) {
        console.error('cancelError', error);
      } else {
        dispatch({ type: 'FETCH_TRANSACTIONS_REJECTED', payload: error });
      }
    });
  };
}

export function createTransaction(transaction) {
  const apiCreateTransaction = process.env.MOCK_VERSION
    ? '../../mock/transactions/createTransaction.json'
    : `${process.env.API_URL}/api/transactions/`;

  return async (dispatch) => {
    dispatch({ type: 'CREATE_TRANSACTION_FETCHING', payload: transaction });

    await applicationAPI({
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
      url: apiCreateTransaction,
      data: transaction,
    }).then((response) => {
      dispatch({ type: 'CREATE_TRANSACTION_SUCCESS', payload: response.data });
    }).catch((err) => {
      dispatch({ type: 'CREATE_TRANSACTION_REJECTED', payload: err });
      if (err.response) {
        dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
      }
    });
  };
}

export function deleteTransaction(transaction) {
  return async (dispatch) => {
    // if (process.env.NODE_ENV === "development") {
    //   dispatch({type: 'DELETE_TRANSACTION_SUCCESS', payload: transaction});
    //   dispatch({type: 'HIDE_MODAL-DELETE-TRANSACTION'});
    // } else {
    await applicationAPI({
      method: 'DELETE',
      url: `${process.env.API_URL}/api/transactions/${transaction.id}`,
    })
      .then(() => {
        dispatch({ type: 'DELETE_TRANSACTION_SUCCESS', payload: transaction });
        dispatch({ type: 'HIDE_MODAL-DELETE-TRANSACTION' });
      }).catch((err) => {
        console.log(err);
        dispatch({ type: 'DELETE_TRANSACTION_REJECTED', payload: err });
        if (err.response) {
          dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
        }
      });
    // }
  };
}

export function editTransaction(transaction, prevTransaction, dateBeforeChange) {
  return async (dispatch) => {
    const apiEditTransaction = process.env.MOCK_VERSION
      ? '../../mock/transactions/editTransaction.json'
      : `${process.env.API_URL}/api/transactions/${transaction.id}`;
    dispatch({
      type: 'EDIT_TRANSACTION_FETCHING', payload: transaction, payloadPrev: prevTransaction, dateBeforeChange,
    });
    await applicationAPI({
      method: process.env.MOCK_VERSION ? 'GET' : 'PATCH',
      url: apiEditTransaction,
      data: transaction,
    })
      .then((response) => {
        dispatch({
          type: 'EDIT_TRANSACTION_SUCCESS',
          payload: response.data,
          payloadPrev: prevTransaction,
          response,
          dateBeforeChange,
        });
      }).catch((err) => {
        dispatch({ type: 'EDIT_TRANSACTION_REJECTED', payload: err });
        if (err.response) {
          dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
        }
      });
  };
}

export function setTransactionsListPosition(top) {
  return function (dispatch) {
    dispatch({ type: 'SET_TRANSACTIONS_LIST_POSITION_TOP', payload: top });
  };
}
