import applicationAPI from '../api/applicationAPI';

export function fetchCalendar(data) {
  const apiCalendar = process.env.MOCK_VERSION ? '../../mock/calendar.json' : `${process.env.API_URL}/api/calendar${data && data.year && data.month ? `/${data.year}/${data.month}` : ''}`;

  return async (dispatch) => {
    await applicationAPI({
      url: apiCalendar,
      method: 'GET',
    })
      .then((response) => {
        dispatch({ type: 'FETCH_CALENDAR_FULFILLED', payload: response.data });
      }).catch((err) => {
        dispatch({ type: 'FETCH_CALENDAR_REJECTED', payload: err });
      });
  };
}

export function setFilterCalendar(data) {
  return function (dispatch) {
    dispatch({ type: 'SET_FILTER_CALENDAR', payload: data });
  };
}

export function clearFilterCalendar(data) {
  return function (dispatch) {
    dispatch({ type: 'CLEAR_FILTER_CALENDAR', payload: data });
  };
}
