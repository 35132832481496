import applicationAPI from '../api/applicationAPI';

export function fetchLineTurnover(data) {
  const apiLineTurnover = process.env.MOCK_VERSION
    ? '../../mock/linear.json'
    : `${process.env.API_URL}/api/turnover/linear${data && data.from && data.to ? `/${data.from}/${data.to}` : ''}`;

  return async (dispatch) => {
    await applicationAPI({
      url: apiLineTurnover,
      method: 'GET',
    }).then((response) => {
      dispatch({ type: 'FETCH_LINE-TURNOVER_FULFILLED', payload: response.data, period: data.period });
    }).catch((err) => {
      dispatch({ type: 'FETCH_LINE-TURNOVER_REJECTED', payload: err, period: 'month' });
    });
  };
}

export function hideLineTurnover() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_LINE-TURNOVER' });
    dispatch({ type: 'SHOW_DONUT-TURNOVER' });
  };
}
