export function showCreateTransaction(transactionDay) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_CREATE-TRANSACTION', payload: transactionDay });
  };
}

export function showCreateAccountTransaction(transactionAccount) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_CREATE-ACCOUNT-TRANSACTION', payload: transactionAccount });
  };
}

export function hideCreateTransaction() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_CREATE-TRANSACTION' });
  };
}

export function showDeleteTransaction(transaction) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_DELETE-TRANSACTION', payload: transaction });
  };
}

export function hideDeleteTransaction() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_DELETE-TRANSACTION' });
  };
}

export function showEditTransaction(transaction) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_EDIT-TRANSACTION', payload: transaction });
  };
}

export function hideEditTransaction() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_EDIT-TRANSACTION' });
  };
}

export function showCreateCategory() {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_CREATE-CATEGORY' });
  };
}

export function showCreateChildCategory(parentCategory) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_CREATE-CHILD-CATEGORY', payload: parentCategory });
  };
}

export function hideCreateCategory() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_CREATE-CATEGORY' });
  };
}

export function showCreateAccount() {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_CREATE-ACCOUNT' });
  };
}

export function hideCreateAccount() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_CREATE-ACCOUNT' });
  };
}

export function showCreateAccountCorrection(account) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_CREATE-ACCOUNT-CORRECTION', payload: account });
  };
}

export function hideCreateAccountCorrection() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_CREATE-ACCOUNT-CORRECTION' });
  };
}

export function showEditAccount(account) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_EDIT-ACCOUNT', payload: account });
  };
}

export function hideEditAccount() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_EDIT-ACCOUNT' });
  };
}

export function showEditCategory(category, parentCategory) {
  if (parentCategory) category.parent_id = parentCategory.id;
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_EDIT-CATEGORY', payload: category });
  };
}

export function hideEditCategory() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_EDIT-CATEGORY' });
  };
}

export function showDeleteAccount(account) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_DELETE-ACCOUNT', payload: account });
  };
}

export function hideDeleteAccount() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_DELETE-ACCOUNT' });
  };
}

export function showDeleteAccountCorrection({ account, correction }) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_DELETE-ACCOUNT-CORRECTION', payload: { account, correction } });
  };
}

export function hideDeleteAccountCorrection() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_DELETE-ACCOUNT-CORRECTION' });
  };
}

export function showDeleteCategory(category, parentCategory) {
  if (parentCategory) category.parent_id = parentCategory.id;
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_DELETE-CATEGORY', payload: category });
  };
}

export function hideDeleteCategory() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_DELETE-CATEGORY' });
  };
}

export function showErrorModal() {
  return function (dispatch) {
    dispatch({ type: 'SHOW_ERROR_MODAL' });
  };
}

export function closeErrorModal() {
  return function (dispatch) {
    dispatch({ type: 'CLOSE_ERROR_MODAL' });
  };
}

export function showSuccessModal() {
  return function (dispatch) {
    dispatch({ type: 'SHOW_SUCCESS_MODAL' });
  };
}

export function closeSuccessModal() {
  return function (dispatch) {
    dispatch({ type: 'CLOSE_SUCCESS_MODAL' });
  };
}

export function showOnboarding() {
  return function (dispatch) {
    dispatch({ type: 'SHOW_MODAL_ONBOARDING' });
  };
}

export function hideOnboarding() {
  return function (dispatch) {
    dispatch({ type: 'HIDE_MODAL_ONBOARDING' });
  };
}

export function showCropPhotoModal(imageUrl) {
  return function (dispatch) {
    dispatch({ type: 'SHOW_CROP_PHOTO_MODAL', payload: imageUrl });
  };
}

export function hideCropPhotoModal() {
  return function (dispatch) {
    dispatch({ type: 'CLOSE_CROP_PHOTO_MODAL' });
  };
}
