import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { createAccount, editAccount } from '../../../actions/accountsActions';
import { hideCreateAccount, hideEditAccount } from '../../../actions/modalActions';
import { accountTypes as types } from '../../../util/types';

import TextField from '../../UI/TextField';

const validate = (value) => {
  const errors = {};

  if (!value.account_name) {
    errors.account_name = 'Введите название счета';
  } else if (value.account_name.length >= 256) {
    errors.account_name = 'Название счета не может быть длиннее 255 символов';
  }

  if (!value.account_balance) {
    errors.account_balance = 'Введите сумму';
  } else {
    if (typeof value.account_balance === 'string') {
      if (value.account_balance.match('.')) {
        value.account_balance = value.account_balance.replace(/[.]/g, ',');
      }
    }
    if (!/^-?\d{1,18}(,\d{2})?$/.test(value.account_balance)) {
      errors.account_balance = 'Введите сумму в формате 100,00';
    }
  }

  return errors;
};

function AccountForm(props) {
  const { edit, handleSubmit, initialize } = props;

  const dispatch = useDispatch();
  const accountEdit = useSelector((store) => store.modalDialog.accountEdit);

  const setInitValues = () => {
    initialize({
      category: types[0].label,
    });
  };

  const setInitValuesEdit = (accountEdit) => {
    initialize({
      account_balance: accountEdit.start_balance,
      account_name: accountEdit.name,
      icon: accountEdit.icon,
      id: accountEdit.id,
    });
  };

  useEffect(() => {
    if (edit) {
      setInitValuesEdit(accountEdit);
    } else {
      setInitValues();
    }
  }, []);

  const handleSubmitForm = (values) => {
    const parsedBalance = parseFloat(values.account_balance);
    let typeIcon = '';

    types.forEach((type) => {
      if (type.label === values.category) {
        typeIcon = type.icon;
      }
    });

    const accountData = {
      balance: edit ? +accountEdit.balance + parsedBalance - +accountEdit.start_balance : parsedBalance,
      icon: edit ? values.icon : typeIcon,
      name: values.account_name,
      start_balance: parsedBalance,
    };

    if (edit) {
      accountData.id = values.id;
      dispatch(editAccount(accountData));
      dispatch({ type: 'UPDATE_AMOUNT_ACCOUNTS_OF_EDIT_ACCOUNT', payload: parsedBalance - +accountEdit.start_balance });
      dispatch(hideEditAccount());
    } else {
      dispatch(createAccount(accountData));
      dispatch(hideCreateAccount());
    }
  };

  return (
    <form
      className="main-form center__button-form"
      onSubmit={handleSubmit((values) => handleSubmitForm(values))}
    >
      <div className="form-group">
        <Field
          component={TextField}
          type="text"
          name="account_name"
          label="Название:"
        />
      </div>

      {edit || (
        <div className="form-group">
          <span className="form-group__type">Тип счета:</span>
          <Field
            component="select"
            type="text"
            name="category"
          >
            {types.map((account, i) => <option key={Math.sqrt(i)} value={account.label}>{account.label}</option>)}
          </Field>
        </div>
      )}

      <div className="form-group">
        <Field
          component={TextField}
          type="text"
          name="account_balance"
          label="Начальный баланс:"
        />
      </div>
      <div className="main-form__buttons">
        <button type="submit">{edit ? 'Сохранить' : 'Добавить'}</button>
          &nbsp;
        <button
          className="btn_cancel"
          type="button"
          onClick={() => {
            if (edit) {
              dispatch(hideEditAccount());
            } else {
              dispatch(hideCreateAccount());
            }
          }}
        >
          Отмена
        </button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'account',
  validate,
})(AccountForm);
