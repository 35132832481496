import Formsy from 'formsy-react';
import Tree from 'rc-tree';
import TreeNode from 'rc-tree/lib/TreeNode';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCategories } from '../../actions/categoriesActions';
import {
  showCreateCategory, showCreateChildCategory, showEditCategory, showDeleteCategory,
} from '../../actions/modalActions';
import SelectCategory from '../../components/UI/SelectCategory';
import { categoryTypes as types } from '../../util/types';

export default function CategoriesList() {
  const dispatch = useDispatch();
  const categories = useSelector((store) => store.categories.categories);

  const [displayedCategories, setDisplayedCategories] = useState([]);
  const [categoryType, setCategoryType] = useState(3);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  useEffect(() => {
    if (categories !== displayedCategories) setDisplayedCategories(categories);
  }, [categories]);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const parsedCategories = JSON.parse(JSON.stringify(categories)); // Глубокое клонирование категорий, чтобы избежать мутаций пропсов
    const filteredCategories = [];

    // Перебираем категории в поисках соответствия поисковому запросу
    parsedCategories.forEach((category) => {
      let filteredChildren = [];
      // Если есть подкатегории, то их фильтруем по поисковому запросу
      if (category.children && category.children.length !== 0) {
        filteredChildren = category.children.filter((subCategory) => subCategory.name.toLowerCase().indexOf(searchQuery) !== -1);
      }

      // Если категория подходит под запрос,
      if (category.name.toLowerCase().indexOf(searchQuery) !== -1) {
        // то заменяем подкатегории на отфильтрованные
        category.children = filteredChildren;
        // и добавляем в новый массив категорий.
        filteredCategories.push(category);
        // Если категория не попала под запрос,
      } else if (filteredChildren.length) {
        // а какие-то элементы подкатегории попали,
        filteredChildren.forEach((item) => {
          // то метим их как подкатегория, чтобы не отображать иконку при выводе,
          item.sub = true;
          // и добавляем в новый массив без родительской категории.
          filteredCategories.push(item);
        });
      }
    });

    setDisplayedCategories(filteredCategories);
  };

  const handleSelect = (categoryType) => {
    setCategoryType(+categoryType.categories);
  };

  const renderItem = (category, parentCategory) => {
    const isControlled = categoryType === 3 || categoryType === category.type;

    return (
      <div className="categories__item">
        {!parentCategory && (
          <span
            className="categories__icon"
          >
            <i className="fa fa-money" aria-hidden="true" />
          </span>
        )}
        {category.name}
        {isControlled && (
          <>
            <div className="controls categories__controls">
              {!parentCategory && (
                <span
                  className="controls__btn add-account"
                  onClick={() => dispatch(showCreateChildCategory(category))}
                >
                  <i className="fa fa-plus" aria-hidden="true" />
                </span>
              )}
              <span
                className="controls__btn edit-account"
                onClick={() => dispatch(showEditCategory(category, parentCategory))}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
              </span>
              {(category.children && !!category.children.length) || (
                <span
                  className="controls__btn delete-account"
                  onClick={() => dispatch(showDeleteCategory(category, parentCategory))}
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
              )}
            </div>
            <div className="controls categories__type">
              {category.type === 1 && <i className="fa fa-plus categories__type_profit" aria-hidden="true" />}
              {category.type === 0 && <i className="fa fa-minus categories__type_expense" aria-hidden="true" />}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="categories-header">
        <span className="categories-header__title">
          <i className="fa fa-list-ul" />
            &nbsp;Категории
        </span>
        <button
          type="button"
          className="btn add-category__btn"
          onClick={() => dispatch(showCreateCategory())}
        >
          <i className="fa fa-plus-circle" aria-hidden="true" />
            &nbsp;&nbsp;Добавить категорию
        </button>
      </div>
      <div className="categories-filter">
        <span className="categories-filter__title">Категория</span>
        <span className="categories-filter__search">
          <input
            type="search"
            placeholder="Поиск"
            onChange={handleSearch}
          />
          <i className="fa fa-search" aria-hidden="true" />
        </span>
        <Formsy
          className="categories-filter__form"
          onChange={handleSelect}
        >
          <SelectCategory
            name="categories"
            options={types.filter((type) => type.value !== 2)}
            defaultValue={types[0]}
          />
        </Formsy>
      </div>

      {displayedCategories.length > 0
        ? (
          <Tree
            defaultExpandAll
            showIcon={false}
            prefixCls="categories"
          >
            {displayedCategories.map((category) => ((categoryType === 3 // Показать, если выбраны все
                || categoryType === category.type // Показать, если соответствует выбранному
                || (category.children && category.children.length // Показать, если есть потомки
                  && category.children.find((item) => item.type === categoryType))) ? ( // и хоть один из потомков доступен для показа
                    <TreeNode
                      key={category.id}
                      title={renderItem(category)}
                    >
                      {category.children && category.children.length // Показать потомков, если они есть
                  && category.children.map((subCategory) => ((categoryType === 3 // Показать, если выбраны все
                    || categoryType === subCategory.type) ? ( // Показать, если соответствует выбранному
                      <TreeNode
                        key={subCategory.id}
                        title={renderItem(subCategory, category)}
                      />
                    ) : ''))}
                    </TreeNode>
              ) : ''))}
          </Tree>
        )
        : ''}
    </>
  );
}
