import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

import d3_locale_ru_RU from '../../data/locales';

import Axis from './components/Axis';
import Grid from './components/Grid';
import Dots from './components/Dots';
import Legend from './components/Legend';
import ToolTip from './components/ToolTip';

const initialTooltip = {
  display: false,
  data: {
    key: '',
    value: '',
  },
};

export default function LineChart(props) {
  const {
    data, width = 700, height = 250, noProfit = false,
  } = props;

  const chartParentRef = useRef(null);

  const [chartWidth, setChartWidth] = useState(width);
  const [tooltip, setTooltip] = useState(initialTooltip);

  const margin = {
    top: 50, right: 30, bottom: 20, left: 70,
  };
  const w = chartWidth - (margin.left + margin.right);
  const h = height - (margin.top + margin.bottom);
  const parseDate = d3.timeParse('%d.%m.%Y');

  d3.timeFormatDefaultLocale(d3_locale_ru_RU);

  data.forEach((d) => d.dates = parseDate(d.date));

  const updateSize = () => {
    const parentWidth = chartParentRef.current.clientWidth;
    if (width < parentWidth) {
      setChartWidth(parentWidth - 20);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const showToolTip = (color, proxy) => {
    setTooltip({
      display: true,
      color,
      data: {
        key: proxy.target.getAttribute('data-key'),
        value: proxy.target.getAttribute('data-value'),
      },
      pos: {
        x: proxy.target.getAttribute('cx'),
        y: proxy.target.getAttribute('cy'),
      },
    });
  };

  const hideToolTip = () => setTooltip(initialTooltip);

  // Координаты по горизонтали
  const x = d3.scaleTime()
    .domain(d3.extent(data, (d) => d.dates))
    .range([0, w]);

  // Координаты по вертикали
  const y = d3.scaleLinear()
    .domain([0, d3.max(data, (d) => (+d.profit > +d.expense ? +d.profit : +d.expense))])
    .range([h, 0]);

  // Горизонтальная ось
  const xAxis = d3.axisBottom(x)
    .tickFormat(d3.timeFormat('%d %b'));

  // Вертикальная ось
  const yAxis = d3.axisLeft(y)
    .tickFormat((val) => `${val} ₽`);

  // Сетка по горизонтали
  // const xGrid = d3.axisBottom(x)
  //   .tickSize(-h, 0, 0)
  //   .tickFormat('');

  // Сетка по вертикали
  const yGrid = d3.axisLeft(y)
    .tickSize(-w)
    .tickFormat('');

  // Линия дохода
  const line = d3.line()
    .x((d) => x(d.dates))
    .y((d) => y(d.profit));

  // Линия расхода
  const lineRed = d3.line()
    .x((d) => x(d.dates))
    .y((d) => y(d.expense));

  const transform = `translate(${margin.left},${margin.top})`;

  return (
    <div className="line-chart" ref={chartParentRef}>
      <svg
        width={chartWidth}
        height={height}
      >
        <g transform={transform}>
          <Grid
            h={h}
            grid={yGrid}
            gridType="y"
          />

          {/* <Grid h={h} grid={xGrid} gridType="x" /> */}
          <Axis
            h={h}
            axis={yAxis}
            axisType="y"
          />

          <Axis
            h={h}
            axis={xAxis}
            axisType="x"
          />

          {noProfit || (
            <path
              className="line shadow"
              d={line(data)}
              color="#00c264"
              strokeLinecap="square"
            />
          )}

          <path
            className={`${noProfit ? 'lineBrand' : 'lineRed'} shadow`}
            d={lineRed(data)}
            color={noProfit ? '#1d6d7e' : '#ff151e'}
            strokeLinecap="square"
          />

          <Dots
            data={data}
            x={x}
            y={y}
            showToolTip={showToolTip}
            hideToolTip={hideToolTip}
            noProfit={noProfit}
          />

          <ToolTip tooltip={tooltip} />

          {noProfit || (
            <Legend
              width={chartWidth}
              height={height}
            />
          )}
        </g>
      </svg>
    </div>
  );
}
