export default function reducer(state = {
  lineTurnover: [],
  period: 'month',
  active: false,
  fetching: false,
  fetched: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'FETCH_LINE-TURNOVER': {
      return { ...state, fetching: true };
    }
    case 'FETCH_LINE-TURNOVER_REJECTED': {
      return { ...state, fetched: false, error: action.payload };
    }
    case 'FETCH_LINE-TURNOVER_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        lineTurnover: action.payload,
        period: action.period,
        active: state.active,
      };
    }
    case 'HIDE_LINE-TURNOVER': {
      return {
        ...state,
        active: false,
      };
    }
    case 'SHOW_LINE-TURNOVER': {
      return {
        ...state,
        active: true,
      };
    }
    default:
      return state;
  }
}
