import axios from 'axios';

const mockInstance = axios.create({
  baseURL: '/mock',
});

/*
* Для получения доступа к mock файлу его нужно положить в папку mock по пути,
* который задан папками с теми же названиями, что и url запроса.
* Например, url = '/api/transactions/list'
* Путь к mock файлу будет /api/transactions/list.json
* -----
* Для удобства работы с пагинацией предусмотрен расширенный вариант моков.
* Если в передаваемых параметрах запроса есть page, то он подставляется в путь к файлу.
* Например, url = '/api/transactions/list', page = 2
* Путь к mock файлу будет /api/transactions/list-2.json
* */
const mockAPI = {
  get(url, config) {
    const page = config && config.params && config.params.page ? config.params.page : null;
    return mockInstance.get(`${url}${page ? `-${page}` : ''}.json`);
  },
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

const backendAPI = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

backendAPI.interceptors.response.use(
  (response) => response,

  (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.data && error.response.data.message === 'Expired JWT Token' && !originalRequest.retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        }).catch((error) => Promise.reject(error));
      }

      originalRequest.retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        axios.get(`${process.env.API_URL}/api/token/refresh`, {
          params: { refreshToken: localStorage.getItem('rjwt') },
        })
          .then(({ data }) => {
            localStorage.setItem('jwt', data.token);
            localStorage.setItem('rjwt', data.refreshToken);
            backendAPI.defaults.headers.Authorization = `Bearer ${data.token}`;
            originalRequest.headers.Authorization = `Bearer ${data.token}`;
            processQueue(null, data.token);
            resolve(axios(originalRequest));
          })
          .catch((error) => {
            window.location.href = '/login';
            processQueue(error, null);
            reject(error);
          })
          .finally(() => isRefreshing = false);
      });
    }

    if (axios.isCancel(error)) return Promise.reject(error);

    return Promise.reject(error);
  },
);

const applicationAPI = process.env.MOCK_VERSION ? mockAPI : backendAPI;

const jwt = localStorage.getItem('jwt');
if (jwt) applicationAPI.defaults.headers.Authorization = `Bearer ${jwt}`;

export default applicationAPI;
