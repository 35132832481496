import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import getCategoryColor from '../../../util/getCategoryColor';

const HistoryRow = styled.span`
  display: block;
  margin-right: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ColorTick = styled.div`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
  display: inline-block;   
  margin-right: 10px; 
`;

const RowCategory = styled.span`
  font-size: 18px;
`;

export default function Legend(props) {
  const { data, onLeaveHistoryRow, onEnterHistoryRow } = props;

  const categoriesColors = useSelector((store) => store.categories.categoriesColors);

  return (
    <>
      {data.map((dataItem) => (
        <HistoryRow
          key={Math.sqrt(dataItem.category_id)}
          onMouseEnter={() => onEnterHistoryRow(dataItem)}
          onMouseLeave={() => onLeaveHistoryRow()}
        >
          <ColorTick style={{ backgroundColor: getCategoryColor(categoriesColors, +dataItem.category_id) }} />
          <RowCategory>{dataItem.category}</RowCategory>
        </HistoryRow>
      ))}
    </>
  );
}
