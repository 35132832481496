import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchUser, resetPassword } from '../../actions/userActions';
import TextField from '../../components/UI/TextField';

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Обязательно для заполнения';
  }

  if (!values.repeatPassword) {
    errors.repeatPassword = 'Обязательно для заполнения';
  } else if (values.password !== values.repeatPassword) {
    errors.repeatPassword = 'Введенные пароли не совпадают';
  }

  return errors;
};

const Reset = reduxForm({
  form: 'userNewPassword',
  validate,
})((props) => {
  useEffect(() => {
    props.dispatch(fetchUser());
  }, []);

  const { token } = useParams();

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('plainPassword[first]', values.password);
    formData.append('plainPassword[second]', values.repeatPassword);
    props.dispatch(resetPassword(formData, token));
  };

  return (
    <div className="sign-form">
      <h1 className="sign-form__header">Новый пароль</h1>
      <div className="error" />
      <form onSubmit={props.handleSubmit((values) => handleSubmit(values))}>
        <Field name="password" type="password" required component={TextField} label="Введите пароль" />
        <Field name="repeatPassword" type="password" required component={TextField} label="Повторите пароль" />
        <div>
          <div className="sign-form__submit">
            <button type="submit">Задать пароль</button>
          </div>
        </div>
        {!!props.user.successMessage && <span className="sign-form__message sign-form__message_success">{props.user.successMessage}</span>}
        {!!props.user.errorMessage && <span className="sign-form__message">{props.user.errorMessage}</span>}
        {!!props.user.errors && (
        <span className="sign-form__message">
          { props.user.errors[Object.keys(props.user.errors)] !== null && typeof props.user.errors[Object.keys(props.user.errors)] === 'object'
            ? props.user.errors[Object.keys(props.user.errors)[0]][Object.keys(props.user.errors[Object.keys(props.user.errors)[0]])[0]][0]
            : props.user.errors[Object.keys(props.user.errors)]}
        </span>
        )}
      </form>
    </div>
  );
});

export default connect((store) => ({
  user: store.user,
}))(Reset);
