export default function reducer(state = {
  token: null,
  fetching: true,
  fetched: false,
  errors: null,
  successMessage: '',
  errorMessage: '',
}, action) {
  switch (action.type) {
    case 'FETCH_USER': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'USER_LOGIN_SUCCESS': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        token: action.token,
      };
    }
    case 'USER_LOGIN_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        token: null,
        errorMessage: action.message,
      };
    }
    case 'USER_CREATE_SUCCESS': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        token: action.token,
      };
    }
    case 'USER_CREATE_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        token: action.token,
        errors: action.errors,
      };
    }
    case 'USER_LOGOUT_SUCCESS': {
      return {
        ...state,
        token: null,
      };
    }
    case 'USER_LOGOUT_REJECTED': {
      return {
        ...state,
      };
    }
    case 'USER_SEND_RESET_TOKEN_SUCCESS': {
      return {
        ...state,
        successMessage: action.message,
        errorMessage: '',
      };
    }
    case 'USER_SEND_RESET_TOKEN_ERROR': {
      return {
        ...state,
        successMessage: '',
        errorMessage: action.message,
      };
    }
    case 'USER_RESET_PASSWORD_SUCCESS': {
      return {
        ...state,
        successMessage: action.message,
        errorMessage: '',
        errors: null,
      };
    }
    case 'USER_RESET_PASSWORD_ERROR': {
      return {
        ...state,
        successMessage: '',
        errorMessage: action.message,
        errors: action.payload,
      };
    }
    case 'USER_SHOW_MESSAGE': {
      return {
        ...state,
        errorMessage: action.message,
      };
    }
    case 'USER_HIDE_MESSAGE': {
      return {
        ...state,
        errorMessage: '',
        errors: null,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
