import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProfilePhotoUpdate } from '../../../actions/profileActions';

import 'react-image-crop/dist/ReactCrop.css';

const basicWidth = 200;
const basicHeight = 200;

export default function CropPhotoForm() {
  const dispatch = useDispatch();
  const photoUrl = useSelector((store) => store.modalDialog.photoUrl);

  const [crop, setCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageRef, setImageRef] = useState('');

  function getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = basicWidth;
    canvas.height = basicHeight;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      basicWidth,
      basicHeight,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          resolve(blob);
        },
      );
    });
  }

  async function makeClientCrop(image, crop) {
    setCroppedImage(await getCroppedImg(
      image,
      crop,
    ));
  }

  const onImageLoaded = (image) => {
    setImageRef(image);
    const pictureWidth = image.getBoundingClientRect().width;
    const pictureHeight = image.getBoundingClientRect().height;
    const cropData = {
      aspect: 1,
      unit: 'px',
      x: pictureWidth <= basicWidth ? 0 : (pictureWidth - basicWidth) / 2,
      y: pictureHeight <= basicHeight ? 0 : (pictureHeight - basicHeight) / 2,
      width: pictureWidth <= basicWidth ? pictureWidth : basicWidth,
      height: pictureHeight <= basicHeight ? pictureHeight : basicHeight,
    };
    setCrop(cropData);
    makeClientCrop(image, cropData);
  };

  const onClickHandler = () => {
    const data = new FormData();
    data.append('file', croppedImage);
    dispatch(fetchProfilePhotoUpdate(data));
  };

  return (
    <>
      <ReactCrop
        src={photoUrl}
        crop={crop}
        onChange={(newCrop) => {
          if (!imageRef) return;
          setCrop(newCrop);
        }}
        onImageLoaded={onImageLoaded}
        onComplete={(crop) => {
          if (!imageRef) return;
          if (!crop.width || !crop.height) {
            setCroppedImage(null);
          } else {
            makeClientCrop(imageRef, crop);
          }
        }}
      />
      <button
        type="button"
        disabled={!croppedImage}
        onClick={onClickHandler}
        className="btn_block"
      >
        Сохранить
      </button>
    </>
  );
}
