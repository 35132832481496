import * as d3 from 'd3';
import React from 'react';
import { useSelector } from 'react-redux';

import getCategoryColor from '../../../util/getCategoryColor';

export default function Path(props) {
  const {
    height, pie, data, showToolTip, hideToolTip, hoverLegend,
  } = props;

  const categoriesColors = useSelector((store) => store.categories.categoriesColors);

  const transform = `translate(${440},${125})`;

  const createChart = () => (pie(data)).map((d) => {
    let outerRadiusKoef = 2.2;
    let innerRadiusKoef = 2.8;
    if (+hoverLegend === +d.data.category_id) {
      outerRadiusKoef = 2.1;
      innerRadiusKoef = 2.7;
    }
    const arc = d3.arc()
      .outerRadius(height / outerRadiusKoef)
      .innerRadius(height / innerRadiusKoef);

    return (
      <path
        fill={getCategoryColor(categoriesColors, +d.data.category_id)}
        d={arc(d)}
        key={d.data.category_id}
        data-value={d.data.amount}
        data-key={d.data.category}
        onMouseEnter={showToolTip}
        onMouseLeave={hideToolTip}
      />
    );
  });

  const paths = createChart(this);

  return (
    <g transform={transform}>
      {paths}
    </g>
  );
}
