import React, { useEffect, useState } from 'react';
import { change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  setActiveAccount,
  setActiveType,
  setTransitionType,
  clearTransitionType,
} from '../../../actions/formTransactionsActions';
import {
  focusPopularTransactions,
  fetchPopularTransactions,
} from '../../../actions/popularTransactionsActions';
import getCategoryColor from '../../../util/getCategoryColor';

import Amount from '../../UI/Amount';

import TransactionForm from './TransactionForm';

const Wrap = styled.div`
  display: block;
  position: relative;
  margin-left: 30px;
`;

const ExpressTransactions = styled.div`
  display: block;
  position: relative;
  margin-bottom: 25px;
  width: 100%;
`;

const ButtonTransaction = styled.button`
  height: auto;
  color: #000;
  background-color: transparent;
  border: 1px solid #dedede;
  width: 180px !important;
  padding: 10px 12px;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  margin-right: 20px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  
  &:focus {
    border: 1px solid #3e6573;            
  }

  &:hover {
    background-color: #d9e2e5
  }
  
  &.popular-transaction__active {
    background-color: #d9e2e5 !important;
  }
`;

const ContractorName = styled.span`
  display: block;
  font-size: 18px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CategoryName = styled.span`
  display: block;
  min-height: 16px;
  padding-left: 15px;
  font-size: 16px;
  color: #757575;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CurrentAmount = styled.span`
  display: block;
  font-size: 21px;
  padding: 6px 0;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AccountName = styled.span`
  display: block;
  font-size: 18px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

 .fa {
    position: absolute;
    left: 17px;
  }
`;

const ColorTick = styled.div`
  position: absolute;
  top: 12px;
  left: 23px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export default function TransactionFormWrap(props) {
  const { edit } = props;

  const dispatch = useDispatch();
  const accounts = useSelector((store) => store.accounts.accounts);
  const categories = useSelector((store) => store.categories.categories);
  const categoriesColors = useSelector((store) => store.categories.categoriesColors);
  const popularTransactions = useSelector((store) => store.popularTransactions.popularTransactions);
  const transactionAccount = useSelector((store) => store.modalDialog.transactionAccount);

  const [popularTransaction, setPopularTransaction] = useState(null);

  useEffect(() => {
    dispatch(fetchPopularTransactions());
  }, []);

  // функция проверки на совпадения
  const testingValueType = (value) => {
    if (popularTransactions.length) {
      if (+value.account_id === popularTransactions[0].account_id
          && value.amount === popularTransactions[0].amount
          && +value.category_id === popularTransactions[0].category_id
          && value.contractor_name === popularTransactions[0].contractor_name
      ) {
        dispatch(focusPopularTransactions(0));
      }
      if (popularTransactions.length > 1) {
        if (+value.account_id === popularTransactions[1].account_id
            && value.amount === popularTransactions[1].amount
            && +value.category_id === popularTransactions[1].category_id
            && value.contractor_name === popularTransactions[1].contractor_name
        ) {
          dispatch(focusPopularTransactions(1));
        }
      }
      if (popularTransactions.length > 2) {
        if (+value.account_id === popularTransactions[2].account_id
            && value.amount === popularTransactions[2].amount
            && +value.category_id === popularTransactions[2].category_id
            && value.contractor_name === popularTransactions[2].contractor_name
        ) {
          dispatch(focusPopularTransactions(2));
        }
      }
    }
  };

  const handleSetPopularTransaction = (popularTransaction) => {
    //  проблема известна, много на stack-flow, что бы обновить несколько полей, прописывать и диспачить нужно каждое поле/
    dispatch(change('transaction', 'type', popularTransaction.type.toString()));
    dispatch(change('transaction', 'category', popularTransaction.category_id ? popularTransaction.category_id : null));
    dispatch(change('transaction', 'accounts', popularTransaction.account_id.toString()));
    dispatch(change('transaction', 'amount', popularTransaction.amount));
    dispatch(change('transaction', 'contractor_name', popularTransaction.contractor_name));

    // делаем фильтрацию счетов, что бы нельзя было перевести на свой же счет
    dispatch(setActiveAccount(+popularTransaction.account_id, accounts));
    dispatch(setActiveType(+popularTransaction.type, categories));

    // если тип популярной 'перевод' то заменяем имя на счет перевода
    popularTransaction.type === 3
      ? dispatch(setTransitionType())
      : dispatch(clearTransitionType());

    setPopularTransaction(popularTransaction);

    testingValueType(popularTransaction);
  };

  const buttonTransactions = popularTransactions.map((_, i) => (
    <ButtonTransaction
      key={Math.sqrt(i)}
      className={popularTransactions.focusPopularTransaction === 0 ? ' popular-transaction__active ' : null}
      onClick={() => handleSetPopularTransaction(popularTransactions[i])}
    >
      <ColorTick
        style={{
          top: 30,
          left: 30,
          backgroundColor: getCategoryColor(categoriesColors, +popularTransactions[i].category_id),
        }}
      />
      <Wrap>
        {+popularTransactions[i].type === 1 && (
          <i className="fa fa-minus popular popular--expense" aria-hidden="true" />
        )}
        {+popularTransactions[i].type === 2 && (
          <i className="fa fa-plus popular popular--profit" aria-hidden="true" />
        )}
        {+popularTransactions[i].type === 3 && (
          <i className="fa fa-exchange popular popular--transfer" aria-hidden="true" />
        )}
        <ContractorName>{popularTransactions[i].contractor_name}</ContractorName>
        <CategoryName>{popularTransactions[i].category_name}</CategoryName>
        <CurrentAmount>
          {popularTransactions[i].type === 1 && (
            <Amount
              amount={popularTransactions[i].amount}
              type="expense"
              showCurrency
            />
          )}
          {popularTransactions[i].type === 2 && (
            <Amount
              amount={popularTransactions[i].amount}
              className="profit__color"
              type="profit"
              showCurrency
            />
          )}
          {popularTransactions[i].type === 3 && (
            <Amount
              amount={popularTransactions[i].amount}
              showCurrency
            />
          )}
        </CurrentAmount>

        <AccountName>
          <i
            className={`fa ${popularTransactions[i].icon}`}
            style={{ color: popularTransactions[i].color }}
          />
          {' '}
          {popularTransactions[i].account_name}
        </AccountName>
      </Wrap>
    </ButtonTransaction>
  ));

  return (
    <div className="main-form">
      {!edit && !transactionAccount && !!popularTransactions.length && popularTransactions[0].type && (
        <ExpressTransactions className="express-transactions">
          <span className="form-group__type popular-transaction-title">Популярные операции:</span>
          {buttonTransactions}
        </ExpressTransactions>
      )}

      <TransactionForm popularTransaction={popularTransaction} />
    </div>
  );
}
