import React, { useEffect, useState } from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { createCategory, editCategory } from '../../../actions/categoriesActions';
import { hideCreateCategory, hideEditCategory } from '../../../actions/modalActions';
import { categoryTypes as types } from '../../../util/types';

import TextField from '../../UI/TextField';

const validate = (value) => {
  const errors = {};

  if (!value.category_name) {
    errors.category_name = 'Введите название категории';
  } else if (value.category_name.length >= 256) {
    errors.category_name = 'Название категории не может содержать больше 255 символов';
  }

  return errors;
};

function CategoryForm(props) {
  const { handleSubmit, edit, initialize } = props;

  const dispatch = useDispatch();
  const activeType = useSelector((store) => store.formGlobal.activeType);
  const categories = useSelector((store) => store.categories.categories);
  const categoryEdit = useSelector((store) => store.modalDialog.categoryEdit);
  const parent = useSelector((store) => store.modalDialog.parent);
  const showModalCreateTransaction = useSelector((store) => store.modalDialog.showModalCreateTransaction);
  const showModalEditTransaction = useSelector((store) => store.modalDialog.showModalEditTransaction);
  const showModal = showModalCreateTransaction || showModalEditTransaction;

  const [currentParent, setCurrentParent] = useState(parent);

  const setInitValues = () => {
    initialize({
      category_parent: parent ? parent.id : null,
      category_type:
          showModal
            ? +activeType - 1
            : parent && parent.type < 2
              ? parent.type
              : 0,
    });
  };

  const setInitValuesEdit = (categoryEdit) => {
    initialize({
      category_name: categoryEdit.name,
      category_parent: categoryEdit.parent_id,
      category_type: categoryEdit.type,
      id: categoryEdit.id,
    });
  };

  useEffect(() => {
    if (edit) {
      setInitValuesEdit(categoryEdit);
    } else {
      setInitValues();
    }
  }, []);

  useEffect(() => {
    if (currentParent && currentParent.type === 1) dispatch(change('category', 'category_type', 1));
  }, [currentParent]);

  const handleSubmitForm = (values) => {
    const categoryData = {
      name: values.category_name,
      parent_id: values.category_parent,
      type: +values.category_type,
    };

    if (edit) {
      categoryData.id = values.id;
      dispatch(editCategory(categoryData));
      dispatch(hideEditCategory());
    } else {
      dispatch(createCategory(categoryData));
      dispatch(hideCreateCategory());
    }
  };

  return (
    <form
      className="main-form center__button-form"
      onSubmit={handleSubmit((values) => handleSubmitForm(values))}
    >
      <div className="form-group">
        <Field
          component={TextField}
          type="text"
          name="category_name"
          label="Название:"
        />
      </div>
      {edit || (
        <div className="form-group">
          <span className="form-group__type">Родительская категория:</span>
          <Field
            component="select"
            type="text"
            name="category_parent"
            onChange={(e) => setCurrentParent(categories.find((category) => category.id === +e.target.value))}
            disabled={!categories.length || parent || showModal}
          >
            {parent ? (
              <option value={parent.id}>{parent.name}</option>
            ) : (
              <>
                <option value="">Нет</option>
                {categories.map((category) => <option key={category.id} value={category.id}>{category.name}</option>)}
              </>
            )}
          </Field>
        </div>
      )}
      {edit || (
        <div className="form-group">
          <span className="form-group__type">Тип:</span>
          <Field
            component="select"
            type="text"
            name="category_type"
            disabled={(currentParent && currentParent.type < 2) || showModal}
          >
            {
              currentParent
                ? currentParent.type < 2
                  ? types
                    .filter((type) => type.value !== 2)
                    .map((t) => t.value === currentParent.type && <option key={t.value} value={t.value}>{t.label}</option>)
                  : types
                    .filter((type) => type.value !== 2)
                    .map((t) => t.value < 2 && <option key={t.value} value={t.value}>{t.label}</option>)
                : showModal
                  ? types
                    .filter((type) => type.value !== 2)
                    .map((t) => t.value === +activeType - 1 && <option key={t.value} value={t.value}>{t.label}</option>)
                  : types
                    .filter((type) => type.value !== 2)
                    .map((t) => t.value < 3 && <option key={t.value} value={t.value}>{t.label}</option>)
            }
          </Field>
        </div>
      )}
      <div className="main-form__buttons">
        <button type="submit">{edit ? 'Сохранить' : 'Добавить'}</button>
          &nbsp;
        <button
          className="btn_cancel"
          type="button"
          onClick={() => {
            if (edit) {
              dispatch(hideEditCategory());
            } else {
              dispatch(hideCreateCategory());
            }
          }}
        >
          Отмена
        </button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: 'category',
  validate,
})(CategoryForm);
