import moment from 'moment/moment';

const weekDayNames = [
  {
    name: 'Понедельник',
    number: 1,
  },
  {
    name: 'Вторник',
    number: 2,
  },
  {
    name: 'Среда',
    number: 3,
  },
  {
    name: 'Четверг',
    number: 4,
  },
  {
    name: 'Пятница',
    number: 5,
  },
  {
    name: 'Суббота',
    number: 6,
  },
  {
    name: 'Воскресенье',
    number: 7,
  },
];

// Дата в формате "2022-05-16"
const getWeekDayName = (date) => weekDayNames[moment(date).isoWeekday() - 1].name;

export default getWeekDayName;
