import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import TransactionsDate from './components/TransactionsDate';

export default function TransactionsList(props) {
  const {
    dateFromCalendarCell,
    defaultDateFromCalendarCell,
    idFromCalendarCell,
    defaultIdFromCalendarCell,
    onDeleteTransactionClick,
    onEditTransactionClick,
    setFilters,
  } = props;

  const app = useSelector((store) => store.app);

  const transactionsWrapper = useRef(null);
  const [transactionIsFound, setTransactionIsFound] = useState(null);
  const scrollableElem = transactionsWrapper.current ? transactionsWrapper.current.parentElement : null;
  const transactionsList = useSelector((store) => store.transactionsList);

  const scrollToElement = (targetPos) => {
    scrollableElem.scrollTo({
      top: targetPos,
      behavior: 'smooth',
    });
  };

  const scrollToTransaction = () => {
    if (scrollableElem) {
      scrollToElement(transactionIsFound.current.getBoundingClientRect().top - scrollableElem.getBoundingClientRect().top);
      defaultDateFromCalendarCell();
      defaultIdFromCalendarCell();
      setTransactionIsFound(null);
    }
  };

  useEffect(() => {
    if (transactionIsFound) scrollToTransaction();
  }, [transactionIsFound]);

  const uniqueDates = transactionsList.transactionsList
    .reduce((uniqueArr, transaction) => {
      if (!uniqueArr.find((elem) => elem === transaction.dateTogether)) {
        uniqueArr.push(transaction.dateTogether);
      }
      return uniqueArr;
    }, [])
    .sort((a, b) => (a > b ? -1 : 1));

  const sortedTransactions = uniqueDates.map((dateTogether) => ({
    dateTogether,
    transactions: transactionsList.transactionsList.filter((elem) => elem.dateTogether === dateTogether),
  }));

  return (
    <InfiniteScroll
      dataLength={transactionsList.transactionsList.length}
      next={() => {
        setFilters((prevState) => ({
          ...prevState,
          page: transactionsList.pager.page + 1,
        }));
      }}
      style={{
        paddingRight: 15,
        marginTop: 25,
      }}
      height={app.windowHeight - 365}
      hasMore={transactionsList.pager.page < transactionsList.pager.lastPage}
      loader={null}
      scrollThreshold={0.99}
    >
      <div ref={transactionsWrapper}>
        {sortedTransactions.map((transactions) => (
          <TransactionsDate
            key={transactions.dateTogether}
            transactions={transactions}
            dateFromCalendarCell={dateFromCalendarCell}
            idFromCalendarCell={idFromCalendarCell}
            onDeleteTransactionClick={onDeleteTransactionClick}
            onEditTransactionClick={onEditTransactionClick}
            transactionIsFound={transactionIsFound}
            setTransactionIsFound={setTransactionIsFound}
          />
        ))}
      </div>
    </InfiniteScroll>
  );
}
