import React from 'react';

export default function Legend(props) {
  const { width, height } = props;

  const style = {
    visibility: 'visible',
  };

  if (width <= height + 70) {
    style.visibility = 'hidden';
  }

  return (
    <g transform="translate(-50,-45)" style={style}>
      <rect width="12" height="12" style={{ fill: '#00c264' }} x="0" y="0" rx={100} ry={100} />
      <text x="20" y="12" className="line-chart-legend">Доход</text>

      <rect width="12" height="12" style={{ fill: '#ff151e' }} x="75" y="0" rx={100} ry={100} />
      <text x="95" y="12" className="line-chart-legend">Расход</text>
    </g>
  );
}
