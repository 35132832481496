import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { showCreateTransaction, showDeleteTransaction, showEditTransaction } from '../../../actions/modalActions';

import Amount from '../../../components/UI/Amount';

const AccountAmountTD = styled.td`
    color: #000;
    font-size: 16px;
    
    span {
        padding: 5px;
        color: #000;
        margin-top: -10px;
        display: block;
        font-size: 16px;
        
        .fa {
            color: #000;
            font-size: 13px
        }
    }
`;

const ActiveDay = styled.div`
   position: relative;
   left: -5px;
   margin-left: 5px;
   display: inline-block;
   width: 10px; 
   height: 10px;
   border-radius: 50%;
   background-color: rgb(0, 194, 100);
`;

const ButtonCreateTransaction = styled.div`
    position: absolute;
    top: -13px;
    right: 10px;
    width: 25px;
    height: 25px;
    background-color: #ff5722;
    border-radius: 50%;
    cursor: pointer;
    
    .fa {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-size: 16px;
    }
`;

const ButtonEditTransaction = styled.div`
    background-color: #436d7c;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin-right: 3px;
    z-index: 2;
    .fa {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-size: 12px;
    }
`;
const ButtonDeleteTransaction = styled.div`
        background-color: #436d7c;
        width: 20px;
        height: 20px;
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        z-index: 2;
        .fa {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            font-size: 12px;
        }
`;

const ButtonControls = styled.div`
    position: absolute;
    right: 10px;
    top: 27px;
    z-index: 1000;
`;

const WrapHideTransaction = styled.div`
  position: relative;
  
  
  &:hover {
    
    .calendar-transaction-controls {
      display: block;
    }
  }
`;

export default function CalendarCell(props) {
  const { day, keyWeek /* , weeks */ } = props;

  const showFullRef = useRef(null);
  const [fullContentDate, setFullContentDate] = useState(null);

  const dispatch = useDispatch();
  const filterCalendar = useSelector((store) => store.calendar.filterParam);

  const hideFullContent = (e) => {
    if (showFullRef.current && !showFullRef.current.contains(e.target)) setFullContentDate(null);
  };

  useEffect(() => {
    window.addEventListener('click', hideFullContent);

    return () => window.removeEventListener('click', hideFullContent);
  }, []);

  return (
    <td
      className={filterCalendar && filterCalendar[0] !== moment(day.date).format('MM') ? 'calendar-table__cell calendar-table__cell_disable-date' : fullContentDate !== day.date ? 'calendar-table__cell' : 'calendar-table__cell calendar-table__cell_active'}
      id={day.date === moment().format('YYYY-MM-DD') ? 'table-cell_today' : ''}
    >
      <Link
        className="calendar-cell calendar-cell__head"
        to={{
          pathname: '/',
          state: day.transactions.length ? { transactionDay: day.date, transactionDayFirstId: day.transactions[0].id } : null,
        }}
      >
        <div className="left">
          {moment(day.date).locale('ru').format('DD MMMM YYYY') === moment().locale('ru').format('DD MMMM YYYY')
            ? (
              <span>
                <ActiveDay />
                {moment(day.date).format('D')}
              </span>
            )
            : <span>{moment(day.date).locale('ru').format('D')}</span>}
        </div>
        <div className="right">
          {!day.profit ? ''
            : <span className="cell-head__profit"><Amount amount={day.profit} type="profit" showCurrency /></span>}
          {!day.expense ? ''
            : (
              <span className="cell-head__expense">
                <Amount
                  amount={day.expense}
                  type="expense"
                  showCurrency
                />
              </span>
            )}
        </div>
        <ButtonCreateTransaction
          className="create-transaction"
          onClick={() => dispatch(showCreateTransaction(day))}
        >
          <i
            className="fa fa-plus"
          />
        </ButtonCreateTransaction>
      </Link>
      <div
        className={day.visibleTransactions.length ? 'calendar-cell calendar-cell__content calendar-cell__onTransaction' : 'calendar-cell calendar-cell__content'}
      >
        {day.transactions.length - day.visibleTransactions.length === 0 ? ''
          : (
            <span
              ref={showFullRef}
              className="calendar-cell__show-full"
              onClick={() => setFullContentDate(day.date)}
            >
              Еще&nbsp;
              {day.transactions.length - day.visibleTransactions.length}
              &nbsp;операции
            </span>
          )}
        {day.visibleTransactions.map((transaction, i) => (
          <div key={Math.sqrt(i)} className="visible-transaction">
            <ButtonControls className="calendar-transaction-controls">
              <ButtonEditTransaction onClick={() => dispatch(showEditTransaction(transaction))}>
                <i className="fa fa-pencil" />
              </ButtonEditTransaction>
              <ButtonDeleteTransaction onClick={() => dispatch(showDeleteTransaction(transaction))}>
                <i className="fa fa-times" />
              </ButtonDeleteTransaction>
            </ButtonControls>
            <Link
              className="control-link"
              to={{
                pathname: '/',
                state: { transactionId: day.transactions[i].id },
              }}
            >
              <table className="transaction-item">
                <tbody>
                  <tr className="table-row">
                    <td className="cell-calendar_contractor_name">
                      {transaction.type === 3 ? null : transaction.category_name}
                    </td>
                    {transaction.type === 1
                      ? (
                        <td className="cell-calendar-content_expense">
                          <Amount amount={transaction.amount} type="expense" showCurrency />
                        </td>
                      )
                      : transaction.type === 3
                        ? (
                          <td className="cell-calendar-content_expense">
                            <Amount amount={transaction.amount} />
                            ₽
                          </td>
                        )
                        : (
                          <td className="cell-calendar-content_profit">
                            <Amount
                              amount={transaction.amount}
                              type="profit"
                              showCurrency
                            />
                          </td>
                        )}
                  </tr>
                  {transaction.type === 3
                    ? (
                      <tr>
                        <AccountAmountTD>
                          <span>
                            {transaction.account_name}
                            &nbsp;&nbsp;
                            <i className="fa fa-long-arrow-right" aria-hidden="true" />
                          </span>
                        </AccountAmountTD>
                        <AccountAmountTD>
                          <span>{transaction.paymentReceiver}</span>
                        </AccountAmountTD>
                      </tr>
                    )
                    : (
                      <tr className="cell-calendar_name">
                        <td>
                          <span>{transaction.account_name}</span>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </Link>
          </div>

        ))}
        {fullContentDate !== day.date ? ''
          : (
            <div
              className={day.transactions.length > 5 ? 'content__full_scroll' : 'content__full'}
              id={keyWeek >= 3 ? 'content__full_up' : 'content__full'}
            >
              {day.transactions.map((transaction, i) => (
                <WrapHideTransaction key={Math.sqrt(i)}>
                  <ButtonControls className="calendar-transaction-controls">
                    <ButtonEditTransaction onClick={() => dispatch(showEditTransaction(transaction))}>
                      <i className="fa fa-pencil" />
                    </ButtonEditTransaction>
                    <ButtonDeleteTransaction onClick={() => dispatch(showDeleteTransaction(transaction))}>
                      <i className="fa fa-times" />
                    </ButtonDeleteTransaction>
                  </ButtonControls>
                  <Link
                    className="hide-transaction"
                    to={{
                      pathname: '/',
                      state: { transactionId: day.transactions[i].id },
                    }}
                  >

                    <table className="transaction-item">
                      <tbody>
                        <tr className="table-row">
                          <td className="cell-calendar_contractor_name">
                            {transaction.type === 3 ? null : transaction.contractor_name}
                          </td>
                          {transaction.type === 1
                            ? (
                              <td className="cell-calendar-content_expense">
                                <Amount
                                  amount={transaction.amount}
                                  type="expense"
                                  showCurrency
                                />
                              </td>
                            )
                            : transaction.type === 3
                              ? (
                                <td className="cell-calendar-content_expense">
                                  <Amount amount={transaction.amount} />
                                  ₽
                                </td>
                              )
                              : (
                                <td className="cell-calendar-content_profit">
                                  <Amount
                                    amount={transaction.amount}
                                    type="profit"
                                    showCurrency
                                  />
                                </td>
                              )}
                        </tr>
                        {transaction.type === 3
                          ? (
                            <tr>
                              <AccountAmountTD>
                                <span>
                                  {transaction.account_name}
                                  &nbsp;&nbsp;
                                  <i
                                    className="fa fa-long-arrow-right"
                                    aria-hidden="true"
                                  />
                                </span>
                              </AccountAmountTD>
                              <AccountAmountTD>
                                <span>{transaction.contractor_name}</span>
                              </AccountAmountTD>
                            </tr>
                          )
                          : (
                            <tr className="cell-calendar_name">

                              <td>
                                <span>{transaction.account_name}</span>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </Link>
                </WrapHideTransaction>
              ))}
            </div>
          )}
      </div>
    </td>
  );
}
