import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import getCategoryColor from '../../../../../util/getCategoryColor';
import Amount from '../../../../../components/UI/Amount';

const Transaction = styled.li`
  display: flex;
  &:hover .comment-wrap__text {
    background-color: #ffffff;
    color: #000000;
  }     
  &:hover .comment-triangle {
    border-color: transparent transparent transparent #ffffff;           
  }
`;

const TransactionRowStart = styled.div`
  position: relative;
  min-width: 100px;
  flex-grow: 1;
  padding: 10px 66px 10px 30px;
`;

const TransactionComment = styled.div`
  font-size: 16px;
  position: relative;
  padding: 10px 15px;
  min-width: 180px;
`;

const CommentWrapText = styled.div`
  border-radius: 7px;
  background-color: #d9e2e5;
  padding: 5px 10px;
  position: relative;
  line-height: 18px;
  text-align: left;
  width: 100%;
`;

const TransactionImg = styled.div`
  font-size: 16px;
  position: relative;
  padding: 10px 15px;
  max-width: 75px;
  flex-shrink: 0;
  
  & img {
    display: block;
    max-width: 100%;
  }
`;

const TransactionRowEnd = styled.div`
  min-width: 100px;
  text-align: right;
  padding: 10px 15px;
`;

const CellText = styled.span`
  flex-flow: row;
  display: block;
  position: relative;
`;

const Triangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent #d9e2e5;
  position: absolute;
  right: -8px;
  top: 2px;
`;

const Arrow = styled.i`
  font-size: 16px;
  line-height: 17px;
`;

const Expand = styled.div`
  overflow: hidden;
  height: 100%;
  display: inline-block;
  width: 130px;
`;

const More = styled.a`
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 5px;
  width: 20px;
  height: 20px;
  font-size: 10px;
`;

const TurnAside = styled.a`
  padding: 0 5px;
  width: 20px;
  height: 20px;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 10px;
`;

const ExpandText = styled.span`
            
`;

const ColorTick = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translateY(-50%);
`;

const NoColorTick = styled.div`
  color: #436d7c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
`;

const showMinSymbol = 20;

export default function TransactionsItem(props) {
  const {
    transaction,
    idFromCalendarCell,
    transactionIsFound,
    setTransactionIsFound,
    onEditTransactionClick,
    onDeleteTransactionClick,
  } = props;

  const categoriesColors = useSelector((store) => store.categories.categoriesColors);
  const fetching = useSelector((store) => store.transactionsList.fetching);

  const transactionRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const longComment = String(transaction.comment).length > showMinSymbol;

  useEffect(() => {
    if (idFromCalendarCell === transaction.id) {
      if (!transactionIsFound) setTransactionIsFound(transactionRef);
    }
  }, [idFromCalendarCell]);

  return (
    <Transaction className="transaction" ref={transactionRef}>
      {transaction.type === 3
        ? (
          <TransactionRowStart className="margin-tick">
            <NoColorTick><i className="fa fa-exchange" /></NoColorTick>
            <CellText className="transactions__name">
              {transaction.contractor_name}
            </CellText>
            {fetching || (
              <div className="transactions__controls controls">
                <span
                  className="controls__btn edit-transaction"
                  onClick={() => onEditTransactionClick(transaction)}
                >
                  <i className="fa fa-pencil" aria-hidden="true" />
                </span>
                <span
                  className="controls__btn delete-transaction"
                  onClick={() => onDeleteTransactionClick(transaction)}
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
              </div>
            )}
          </TransactionRowStart>
        )
        : (
          <TransactionRowStart className="margin-tick">
            <ColorTick style={{ backgroundColor: getCategoryColor(categoriesColors, +transaction.category_id) }} />
            <CellText className="transactions__name">
              {transaction.contractor_name}
            </CellText>
            {transaction.category_name && (
              <CellText className="transactions__contractor_name">
                {transaction.category_name}
              </CellText>
            )}
            {fetching || (
              <div className="transactions__controls controls">
                <span
                  className="controls__btn edit-transaction"
                  onClick={() => onEditTransactionClick(transaction)}
                >
                  <i className="fa fa-pencil" aria-hidden="true" />
                </span>
                <span
                  className="controls__btn delete-transaction"
                  onClick={() => onDeleteTransactionClick(transaction)}
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
              </div>
            )}
          </TransactionRowStart>
        )}
      <TransactionComment className="transaction__comment">
        {transaction.comment && (
          <CommentWrapText
            className={showMore && longComment ? 'comment-wrap__text comment-wrap__text_expanded' : 'comment-wrap__text'}
          >
            <Triangle className="comment-triangle" />
            <Expand>
              <ExpandText>
                {((showMore && longComment) || !longComment) ? transaction.comment : `${String(transaction.comment).slice(0, 20)}...`}
                {showMore && (
                  <TurnAside onClick={() => setShowMore(false)}>
                    <i
                      className="fa fa-chevron-up"
                      aria-hidden="true"
                    />
                  </TurnAside>
                )}
                {!showMore && longComment && (
                  <More onClick={() => setShowMore(true)} className="show-more">
                    <i
                      className="fa fa-chevron-down"
                      aria-hidden="true"
                    />
                  </More>
                )}
              </ExpandText>
            </Expand>
          </CommentWrapText>
        )}
      </TransactionComment>
      <TransactionImg className="transaction__photo">
        {transaction.img && (
          <img src={transaction.img} alt="Чек" />
        )}
      </TransactionImg>
      <TransactionRowEnd>
        {transaction.type === 1 && (
          <CellText>
            <Amount
              amount={transaction.amount.indexOf(',') ? parseFloat(transaction.amount).toFixed(2) : parseInt(transaction.amount, 10)}
              className="transaction__amount"
              type="expense"
              showCurrency
            />
          </CellText>
        )}
        {transaction.type === 2 && (
          <CellText>
            <Amount
              amount={transaction.amount.indexOf(',') ? parseFloat(transaction.amount).toFixed(2) : parseInt(transaction.amount, 10)}
              className="transaction__amount transaction__amount_profit"
              type="profit"
              showCurrency
            />
          </CellText>
        )}
        {transaction.type === 3 && (
          <CellText>
            <Amount
              amount={transaction.amount.indexOf(',') ? parseFloat(transaction.amount).toFixed(2) : parseInt(transaction.amount, 10)}
              className="transaction__amount"
              showCurrency
            />
          </CellText>
        )}
        {transaction.type === 3
          ? (
            <span className="sender-receiver">
              {transaction.account_name}
              &nbsp;
              <Arrow
                className="fa fa-long-arrow-right"
                aria-hidden="true"
              />
              &nbsp;
              {transaction.contractor_name}
            </span>
          )
          : <span className="sender-receiver">{transaction.account_name}</span>}
      </TransactionRowEnd>
    </Transaction>
  );
}
