export default function reducer(state = {
  showModalCreateTransaction: false,
  showModalDeleteTransaction: false,
  showModalEditTransaction: false,
  showModalEditCategory: false,
  showModalCreateAccount: false,
  showModalCreateAccountCorrection: false,
  showModalCreateCategory: false,
  showModalDeleteCategory: false,
  showModalEditAccount: false,
  showModalDeleteAccount: false,
  showModalDeleteAccountCorrection: false,
  showOnboarding: false,
  showModalCropPhoto: false,
  showModalError: {
    message: '',
    show: false,
  },
  showModalSuccess: {
    message: '',
    show: false,
  },
  transactionDelete: [],
  transactionDay: null,
  transactionAccount: null,
  parent: null,
  photoUrl: null,
}, action) {
  switch (action.type) {
    case 'SHOW_MODAL_DELETE-TRANSACTION': {
      return {
        ...state,
        showModalDeleteTransaction: true,
        transactionDelete: action.payload,
      };
    }
    case 'HIDE_MODAL_DELETE-TRANSACTION': {
      return {
        ...state,
        showModalDeleteTransaction: false,
      };
    }
    case 'SHOW_MODAL_CREATE-TRANSACTION': {
      return {
        ...state,
        showModalCreateTransaction: true,
        transactionDay: action.payload,
      };
    }
    case 'SHOW_MODAL_CREATE-ACCOUNT-TRANSACTION': {
      return {
        ...state,
        showModalCreateTransaction: true,
        transactionAccount: action.payload,
      };
    }
    case 'HIDE_MODAL_CREATE-TRANSACTION': {
      return {
        ...state,
        showModalCreateTransaction: false,
        transactionDay: null,
        transactionAccount: null,
      };
    }
    case 'SHOW_MODAL_EDIT-TRANSACTION': {
      return {
        ...state,
        showModalEditTransaction: true,
        transactionEdit: action.payload,
      };
    }
    case 'HIDE_MODAL_EDIT-TRANSACTION': {
      return {
        ...state,
        showModalEditTransaction: false,
      };
    }
    case 'SHOW_MODAL_CREATE-CATEGORY': {
      return {
        ...state,
        showModalCreateCategory: true,
      };
    }
    case 'SHOW_MODAL_CREATE-CHILD-CATEGORY': {
      return {
        ...state,
        showModalCreateCategory: true,
        parent: action.payload,
      };
    }
    case 'HIDE_MODAL_CREATE-CATEGORY': {
      return {
        ...state,
        showModalCreateCategory: false,
        parent: null,
      };
    }
    case 'SHOW_MODAL_CREATE-ACCOUNT': {
      return {
        ...state,
        showModalCreateAccount: true,
      };
    }
    case 'HIDE_MODAL_CREATE-ACCOUNT': {
      return {
        ...state,
        showModalCreateAccount: false,
      };
    }
    case 'SHOW_MODAL_CREATE-ACCOUNT-CORRECTION': {
      return {
        ...state,
        showModalCreateAccountCorrection: true,
        accountCorrectionCreate: action.payload,
      };
    }
    case 'HIDE_MODAL_CREATE-ACCOUNT-CORRECTION': {
      return {
        ...state,
        showModalCreateAccountCorrection: false,
      };
    }
    case 'SHOW_MODAL_EDIT-ACCOUNT': {
      return {
        ...state,
        showModalEditAccount: true,
        accountEdit: action.payload,
      };
    }
    case 'HIDE_MODAL_EDIT-ACCOUNT': {
      return {
        ...state,
        showModalEditAccount: false,
      };
    }
    case 'SHOW_MODAL_EDIT-CATEGORY': {
      return {
        ...state,
        showModalEditCategory: true,
        categoryEdit: action.payload,
      };
    }
    case 'HIDE_MODAL_EDIT-CATEGORY': {
      return {
        ...state,
        showModalEditCategory: false,
      };
    }
    case 'SHOW_MODAL_DELETE-ACCOUNT': {
      return {
        ...state,
        showModalDeleteAccount: true,
        accountDelete: action.payload,
      };
    }
    case 'HIDE_MODAL_DELETE-ACCOUNT': {
      return {
        ...state,
        showModalDeleteAccount: false,
      };
    }
    case 'SHOW_MODAL_DELETE-ACCOUNT-CORRECTION': {
      return {
        ...state,
        showModalDeleteAccountCorrection: true,
        accountCorrectionDelete: action.payload,
      };
    }
    case 'HIDE_MODAL_DELETE-ACCOUNT-CORRECTION': {
      return {
        ...state,
        showModalDeleteAccountCorrection: false,
      };
    }
    case 'SHOW_MODAL_DELETE-CATEGORY': {
      return {
        ...state,
        showModalDeleteCategory: true,
        categoryDelete: action.payload,
      };
    }
    case 'HIDE_MODAL_DELETE-CATEGORY': {
      return {
        ...state,
        showModalDeleteCategory: false,
      };
    }
    case 'SHOW_ERROR_MODAL': {
      switch (action.payload.status) {
        case 400:
        case 404:
          return {
            ...state,
            showModalError: {
              show: true,
              message: action.payload.data.message
                ? action.payload.data.message
                : action.payload.data.error
                  ? action.payload.data.error
                  : action.payload.data.errors && typeof action.payload.data.errors === 'object'
                    ? Object.values(action.payload.data.errors)[0]
                    : action.payload.data.errors,
            },
          };
        case 401:
          return {
            ...state,
            showModalError: {
              show: true,
              message: 'Вы не авторизованы',
            },
          };
        case 403:
          return {
            ...state,
            showModalError: {
              show: true,
              message: 'Доступ запрещен',
            },
          };
        case 405:
          return {
            ...state,
            showModalError: {
              show: true,
              message: 'Method not allowed',
            },
          };
        case 500:
          return {
            ...state,
            showModalError: {
              show: true,
              message: 'Внутренняя ошибка сервера.',
            },
          };

        default:
          return state;
      }
    }
    case 'CLOSE_ERROR_MODAL': {
      return {
        ...state,
        showModalError: { show: false },
      };
    }
    case 'SHOW_SUCCESS_MODAL': {
      return {
        ...state,
        showModalSuccess: {
          show: true,
          message: action.payload.message,
        },
      };
    }
    case 'CLOSE_SUCCESS_MODAL': {
      return {
        ...state,
        showModalSuccess: { show: false },
      };
    }
    case 'SHOW_MODAL_ONBOARDING': {
      return {
        ...state,
        showOnboarding: true,
      };
    }
    case 'HIDE_MODAL_ONBOARDING': {
      return {
        ...state,
        showOnboarding: false,
      };
    }
    case 'SHOW_CROP_PHOTO_MODAL': {
      return {
        ...state,
        showModalCropPhoto: true,
        photoUrl: action.payload,
      };
    }
    case 'CLOSE_CROP_PHOTO_MODAL': {
      return {
        ...state,
        showModalCropPhoto: false,
        photoUrl: null,
      };
    }
    default:
      return state;
  }
}
