import React from 'react';

import Accounting from '../../../util/accounting';

Accounting.settings = {
  currency: {
    symbol: '₽', // default currency symbol is 'RUB'
    format: '%v %s', // controls output: %s = symbol, %v = value/number (can be object: see below)
    decimal: ',', // decimal point separator
    thousand: ' ', // thousands separator
    precision: 2, // decimal places
  },
  number: {
    precision: 2, // default precision on numbers is 0
    thousand: ' ',
    decimal: ',',
  },
};

Number.prototype.toCurrencyString || (Number.prototype.toCurrencyString = function () {
  /* precision, thousand, decimal */
  const val = (this instanceof Number) ? this.valueOf() : this;
  return Accounting.formatNumber.apply(this, [val].concat(Array.prototype.slice.call(arguments, 0)));
});

export default function ToolTip(props) {
  const { tooltip } = props;

  let visibility = 'hidden';
  let transform = '';
  let x = 0;
  let y = 0;
  const width = 60;
  const
    height = 32;
  const transformText = `translate(${width / 2},${height / 2 - 5})`;
  let transformArrow = '';

  const amount = +tooltip.data.value;

  if (tooltip.display === true) {
    const position = tooltip.pos;

    x = position.x;
    y = position.y;
    visibility = 'visible';

    if (y > height) {
      transform = `translate(${x - width / 2},${y - height - 20})`;
      transformArrow = `translate(${width / 2 - 20},${height - 2})`;
    } else if (y < height) {
      transform = `translate(${x - width / 2},${Math.round(y) + 20})`;
      transformArrow = `translate(${width / 2 - 20},${0}) rotate(180,20,0)`;
    }
  } else {
    visibility = 'hidden';
  }

  return (
    <g transform={transform} dy="10">
      <rect
        className="shadow"
        width={width}
        height={height}
        rx="5"
        ry="5"
        x="10"
        dy="10"
        visibility={visibility}
        fill={tooltip.color}
          // fill={_self.props.color(i)}
        opacity="1"
      />

      <polygon
        className="shadow"
        points="20,0  30,0  20,10"
        transform={transformArrow}
        fill={tooltip.color}
        opacity="1"
        visibility={visibility}
        rx="0"
        ry="0"
        x="10"
        y="0"
      />

      <text
        visibility={visibility}
        transform={transformText}
      >
        {/* <tspan is x="0" textAnchor="middle" fontSize="15px" fill="#ffffff">{this.props.tooltip.data.key}</tspan> */}
        <tspan
          x="10"
          textAnchor="middle"
          dy="10"
          fontSize="14px"
          fill="#ffffff"
        >
          {amount.toCurrencyString()}
        </tspan>
      </text>
    </g>
  );
}
