import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAccounts } from '../../../actions/accountsActions';
import {
  showCreateAccount, showEditAccount, showDeleteAccount, showCreateAccountTransaction,
} from '../../../actions/modalActions';

import Amount from '../../UI/Amount';

import AccountsItem from './AccountsItem';

export default function AccountsList() {
  const dispatch = useDispatch();
  const accounts = useSelector((store) => store.accounts.accounts);
  const amount = useSelector((store) => store.amountAccounts.amount);

  useEffect(() => {
    dispatch(fetchAccounts());
  }, []);

  return (
    <div className="accounts">
      <div className="accounts__head">
        <div className="accounts__name">
          Все счета:
        </div>
        <div className="accounts__balance">
          {amount !== '' || !isNaN(parseFloat(amount)) ? (
            <span>
              <Amount amount={amount} />
                &nbsp;&#8381;
            </span>
          ) : (
            <span>
              <i className="fa fa-spinner fa-pulse fa-fw" />
              <span className="sr-only">Loading...</span>
            </span>
          )}
        </div>
      </div>

      <div className="accounts__list">
        {accounts.map((account) => (
          <AccountsItem
            key={account.id}
            name={account.name}
            balance={account.balance}
            color={account.color}
            icon={account.icon}
            onAdd={() => dispatch(showCreateAccountTransaction(account))}
            onEdit={() => dispatch(showEditAccount(account))}
            onDelete={() => dispatch(showDeleteAccount(account))}
          />
        ))}
      </div>
      <div className="accounts__new">
        <button
          type="button"
          className="add-new-account"
          onClick={() => dispatch(showCreateAccount())}
        >
          <i className="fa fa-plus-circle" aria-hidden="true" />
            &nbsp;Добавить счет
        </button>
      </div>
    </div>
  );
}
