import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { fetchUser, sendResetToken } from '../../actions/userActions';
import TextField from '../../components/UI/TextField';

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Обязательно для заполнения';
  } else if (!/^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
    errors.username = 'Неверный e-mail';
  }

  return errors;
};

const Reset = reduxForm({
  form: 'userPasswordReset',
  validate,
})((props) => {
  useEffect(() => {
    props.dispatch(fetchUser());
  }, []);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append('username', values.username);
    props.dispatch(sendResetToken(formData));
  };

  return (
    <div className="sign-form">
      <h1 className="sign-form__header">Восстановить пароль</h1>
      <div className="error" />
      <form onSubmit={props.handleSubmit((values) => handleSubmit(values))}>
        <Field name="username" type="email" required component={TextField} label="E-mail:" />
        <div className="sign-form__submit">
          <input type="submit" className="submit" value="Восстановить пароль" />
        </div>
        {!!props.user.successMessage && <span className="sign-form__message sign-form__message_success">{props.user.successMessage}</span>}
        {!!props.user.errorMessage && <span className="sign-form__message">{props.user.errorMessage}</span>}
      </form>
    </div>
  );
});

export default connect((store) => ({
  user: store.user,
}))(Reset);
