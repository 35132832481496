import Formsy from 'formsy-react';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showCropPhotoModal } from '../../actions/modalActions';
import { sendConfirmEmail, fetchProfilePhotoDelete, fetchProfileUpdate } from '../../actions/profileActions';
import TextInput from '../../components/UI/TextInput';

export default function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector((store) => store.profileInfo.profile);
  const needToReset = useSelector((store) => store.profileInfo.needToReset);
  const showError = useSelector((store) => store.profileInfo.showError);
  const error = useSelector((store) => store.profileInfo.error);

  const ref = useRef(null);

  const [canSubmit, setCanSubmit] = useState(false);
  const [needToChangePassword, setNeedToChangePassword] = useState(false);

  const onChangeProfilePhoto = (e) => {
    const [file] = e.target.files;
    if (file) {
      dispatch(showCropPhotoModal(URL.createObjectURL(file)));
      e.target.value = '';
    }
  };

  const onRemoveProfilePhoto = () => {
    dispatch(fetchProfilePhotoDelete());
  };

  const submitUpdateInfo = (data) => {
    if (!needToChangePassword) {
      delete data.plainPassword;
      delete data.repeatPlainPassword;
      delete data.lastPassword;
    }
    dispatch(fetchProfileUpdate(data));
  };

  return (
    <div className="profile">
      <h1 className="profile__title">
        <i className="fa fa-user" />
          &nbsp; Профиль
      </h1>
      <div className="profile__content">
        <div className="profile__photo">
          <div className="profile__photo-wrapper">
            {profile.photo
              ? (
                <div className="profile__photo-image" style={{ backgroundImage: `url("${profile.photo}")`, borderRadius: '50%' }}>
                  <span
                    className="profile__photo-delete"
                    onClick={onRemoveProfilePhoto}
                  >
                    <span className="controls__btn">
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                      />
                    </span>

                  </span>
                </div>
              )
              : <div className="profile__photo-image" style={{ backgroundImage: 'url("./images/photo.svg")' }} />}
          </div>
          <label
            htmlFor="file"
            className="profile__photo-edit"
          >
            <span className="controls__btn">
              <i
                className="fa fa-pencil"
                aria-hidden="true"
              />
            </span>
            {profile.photo ? 'Изменить фото' : 'Загрузить фото'}
            <input
              ref={ref}
              style={{ display: 'none' }}
              type="file"
              id="file"
              name="file"
              onChange={onChangeProfilePhoto}
                /*
                * При каждом вызове createObjectURL(), создаётся новый URL объект,
                * даже если он уже был ранее создан для того же самого объекта.
                * Память для каждого из них нужно освобождать с помощью URL.revokeObjectURL()
                * */
              onLoad={() => URL.revokeObjectURL(ref.current.src)}
            />
          </label>
        </div>
        <Formsy
          className="profile__form"
          onChange={() => {
            dispatch({ type: 'RESET_PROFILE_ERROR' });
            dispatch({ type: 'RESET_PROFILE_FORM', payload: false });
          }}
          onValidSubmit={submitUpdateInfo}
          onValid={() => setCanSubmit(true)}
          onInvalid={() => setCanSubmit(false)}
          id="profile-form"
          name="profile-form"
        >
          <div className="profile__form-block">
            <TextInput
              name="lastname"
              title="*Фамилия:"
              value={profile.lastname || ''}
              required
              validationErrors={{
                isDefaultRequiredValue: '✗ Поле не должно быть пустым',
              }}
            />
            <TextInput
              name="firstname"
              title="*Имя:"
              value={profile.firstname || ''}
              required
              validationErrors={{
                isDefaultRequiredValue: '✗ Поле не должно быть пустым',
              }}
            />
            <TextInput
              name="middlename"
              title="Отчество:"
              value={profile.middlename || ''}
            />
          </div>
          <div className="profile__form-block">
            <h3 className="profile__form-block__title">Смена пароля</h3>
            <TextInput
              name="plainPassword"
              title={`${needToChangePassword ? '*' : ''}Новый пароль:`}
              type="password"
              placeholder="Начните вводить новый пароль"
              required={needToChangePassword}
              validations={needToChangePassword ? {
                minLength: 6,
              } : {}}
              validationErrors={{
                isDefaultRequiredValue: '✗ Поле не должно быть пустым',
                minLength: '✗ Минимальная длина пароля 6 символов',
              }}
              isValue={setNeedToChangePassword}
              needToReset={{
                resetValue: needToReset,
              }}
            />
            <TextInput
              name="repeatPlainPassword"
              title={`${needToChangePassword ? '*' : ''}Повторите пароль:`}
              type="password"
              required={needToChangePassword}
              validations={needToChangePassword ? {
                minLength: 6,
                equalsField: 'plainPassword',
              } : {}}
              validationErrors={{
                isDefaultRequiredValue: '✗ Поле не должно быть пустым',
                minLength: '✗ Минимальная длина пароля 6 символов',
                equalsField: '✗ Пароли не совпадают',
              }}
              needToReset={{
                resetValue: needToReset,
              }}
            />
          </div>
          <div className="profile__form-block">
            <h3 className="profile__form-block__title">Смена e-mail</h3>
            <TextInput
              name="email"
              title="*E-mail:"
              type="email"
              value={profile.email || ''}
              required
              validations="isEmail"
              validationErrors={{
                isDefaultRequiredValue: '✗ Поле не должно быть пустым',
                isEmail: '✗ E-mail неверный',
              }}
            />
            {profile.emailConfirmed || (
              <div className="form-group wide-form">
                <span
                  className="profile__form-block__button"
                  onClick={() => dispatch(sendConfirmEmail())}
                >
                  Отправить письмо для подтверждения
                </span>
              </div>
            )}
          </div>
          <TextInput
            name="lastPassword"
            title={`${needToChangePassword ? '*' : ''}Текущий пароль:`}
            type="password"
            required={needToChangePassword}
            validationErrors={{
              isDefaultRequiredValue: '✗ Поле не должно быть пустым',
            }}
            needToReset={{
              resetValue: needToReset,
            }}
          />
          <div className="profile__form__buttons">
            <button
              type="submit"
              disabled={!canSubmit}
            >
              Сохранить изменения
            </button>
          </div>
          {showError && <p className="profile__form__error">{error}</p>}
        </Formsy>
      </div>
    </div>
  );
}
