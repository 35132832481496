import * as d3 from 'd3';
import React, { useState } from 'react';
import styled from 'styled-components';

import Amount from '../UI/Amount';

import Path from './components/Path';
import Legend from './components/Legend';
import ToolTip from './components/ToolTip';

const ChartWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

const ChartLegendWrap = styled.div`
  flex-basis: 35%;
  width: 155px;
  max-height: 220px;
  margin: 15px 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ChartPathWrap = styled.div`
  position: relative;
  flex-basis: 65%;
`;

const TurnoverWrap = styled.div`
  position: absolute;
  top: 110px;
  left: 105px;
  width: 180px;
`;

const Turnover = styled.span`
  display: block;
  font-size: 31px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => (props.isProfit ? '#00a13d' : '')};
  overflow: hidden;
`;

const initialTooltip = {
  display: false,
  data: {
    key: '',
    value: '',
  },
};

const defaultData = [
  {
    category_id: '0',
    category: 'Нет данных',
    amount: '0.001',
    part: '0',
  },
];

const transform = 'translate(-245, 0)';
const width = 320;
const height = 250;

export default function DonutChart(props) {
  const { amount, data, isProfit } = props;

  const [hoverLegend, setHoverLegend] = useState(null);
  const [amountPathCount, setAmountPathCount] = useState('');
  const [tooltip, setTooltip] = useState(initialTooltip);

  const pie = d3.pie().value((d) => d.amount).padAngle(0).sort(null);

  // Вынести сектор диаграммы при наведении мыши на соответствующую категорию в Отчёте
  const onEnterHistoryRow = (row) => {
    setHoverLegend(row.category_id);
    setAmountPathCount(row.amount);
  };

  // Вставить сектор диаграммы на место, когда мышь ушла с соответствующей категории в Отчёте
  const onLeaveHistoryRow = () => {
    setHoverLegend(null);
    setAmountPathCount(null);
  };

  /*
  * Вставить сектор диаграммы на место, когда мышь ушла из контейнера с категориями.
  * Используется как резервный вариант, когда при быстрых премещениях мыши не сработало
  * событие onMouseLeave при уходе мыши с категории в Отчёте.
  */
  const onLeaveColHistory = () => {
    if (hoverLegend) onLeaveHistoryRow();
  };

  // Функции появления/исчезания тултипа
  const showToolTip = (e) => {
    setTooltip({
      display: true,
      data: {
        value: e.target.getAttribute('data-value'),
        key: e.target.getAttribute('data-key'),
      },
      pos: {
        x: 100,
        y: 100,
      },
    });
  };

  const hideToolTip = () => setTooltip(initialTooltip);

  return (
    <ChartWrap>
      <ChartLegendWrap onMouseLeave={onLeaveColHistory}>
        <Legend
          data={data.length ? data : defaultData}
          onEnterHistoryRow={onEnterHistoryRow}
          onLeaveHistoryRow={onLeaveHistoryRow}
        />
      </ChartLegendWrap>
      <ChartPathWrap>
        <svg
          width={width}
          height={height}
          onMouseLeave={hideToolTip}
        >
          <ToolTip tooltip={tooltip} />
          <g transform={transform}>
            <Path
              height={height}
              pie={pie}
              data={data.length ? data : defaultData}
              showToolTip={showToolTip}
              hideToolTip={hideToolTip}
              hoverLegend={hoverLegend}
            />
          </g>
        </svg>
        <TurnoverWrap>
          <Turnover isProfit={isProfit}>
            <Amount
              amount={amountPathCount || amount}
              type={isProfit ? 'profit' : 'expense'}
              showCurrency
            />
          </Turnover>
        </TurnoverWrap>
      </ChartPathWrap>
    </ChartWrap>
  );
}
