import applicationAPI from '../api/applicationAPI';

// Проверка токена
export function fetchUser() {
  // Это первый fetch для проверки действия токена
  const apiCheckToken = process.env.MOCK_VERSION ? '/mock/profile/profileInfo.json' : `${process.env.API_URL}/api/profile/info`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_USER' });
    await applicationAPI({
      url: apiCheckToken,
    }).then(({ data }) => {
      // в случае если токен есть, и он действующий, просто его вернём,
      dispatch({ type: 'USER_LOGIN_SUCCESS', token: localStorage.getItem('jwt') });
      // и дополнительно сохраним полученные данные пользователя.
      dispatch({ type: 'FETCH_PROFILE_FULFILLED', payload: data });
    }).catch((error) => {
      localStorage.removeItem('jwt');
      dispatch({ type: 'USER_LOGIN_REJECTED', payload: error });
    });
  };
}

// Вход пользователя
export function loginUser(data) {
  const apiLoginUser = process.env.MOCK_VERSION ? '/mock/login.json' : `${process.env.API_URL}/api/login_check`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_USER' });
    await applicationAPI({
      url: apiLoginUser,
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
      data,
    }).then(({ data }) => {
      localStorage.setItem('jwt', data.token);
      localStorage.setItem('rjwt', data.refreshToken);
      applicationAPI.defaults.headers.Authorization = `Bearer ${data.token}`;
      dispatch({ type: 'USER_LOGIN_SUCCESS', payload: data, token: data.token });
    })
      .catch((error) => {
        console.error('loginUser error.response', error.response);
        localStorage.removeItem('jwt');
        dispatch({ type: 'USER_LOGIN_REJECTED', payload: error, message: error.response.data ? error.response.data.message : error.message });
      });
  };
}

// Выход пользователя
export function logoutUser() {
  return function (dispatch) {
    localStorage.removeItem('jwt');
    localStorage.removeItem('rjwt');
    delete applicationAPI.defaults.headers.Authorization;
    dispatch({ type: 'USER_LOGOUT_SUCCESS' });
    dispatch({ type: 'RESET_PROFILE' });
  };
}

// Создание пользователя
export function createUser(data) {
  const apiCreateUser = process.env.MOCK_VERSION ? '/mock/createUser.json' : `${process.env.API_URL}/api/register`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_USER' });
    await applicationAPI({
      url: apiCreateUser,
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
      data,
    })
      .then(({ data }) => {
        localStorage.setItem('jwt', data.token);
        localStorage.setItem('rjwt', data.refreshToken);
        applicationAPI.defaults.headers.Authorization = `Bearer ${data.token}`;
        dispatch({ type: 'USER_CREATE_SUCCESS', payload: data, token: data.token });
        dispatch({ type: 'SHOW_MODAL_ONBOARDING' });
      })
      .catch((error) => {
        console.error('createUser error.response', error.response);
        dispatch({ type: 'USER_CREATE_REJECTED', payload: error, errors: error.response.data.errors });
      });
  };
}

// Отправить пользователю на почту токен для смены пароля
export function sendResetToken(data) {
  const apiSendResetToken = process.env.MOCK_VERSION ? '/mock/resetToken.json' : `${process.env.API_URL}/api/reset/send-email`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_USER' });
    await applicationAPI({
      url: apiSendResetToken,
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
      data,
    })
      .then(({ data }) => {
        dispatch({ type: 'USER_SEND_RESET_TOKEN_SUCCESS', payload: data, message: 'Проверьте почту и следуйте инструкциям' });
      })
      .catch((error) => {
        console.error('sendResetToken error.response', error.response);
        dispatch({ type: 'USER_SEND_RESET_TOKEN_ERROR', payload: error, message: error.response ? error.response.data.error : error.message });
      });
  };
}

// Изменить пароль
export function resetPassword(data, token) {
  const apiSendResetToken = process.env.MOCK_VERSION ? '/mock/resetPassword.json' : `${process.env.API_URL}/api/reset/reset-password/${token}`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_USER' });
    await applicationAPI({
      url: apiSendResetToken,
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
      data,
    })
      .then(({ data }) => {
        dispatch({ type: 'USER_RESET_PASSWORD_SUCCESS', payload: data, message: data.message });
      })
      .catch((error) => {
        console.error('sendResetPassword error.response', error.response);
        dispatch({
          type: 'USER_RESET_PASSWORD_ERROR',
          payload: error.response.data.errors || null,
          message: error.response ? error.response.data.error : error.message,
        });
      });
  };
}
