import applicationAPI from '../api/applicationAPI';

export function fetchPopularTransactions() {
  return async (dispatch) => {
    const apiPopularTransactions = process.env.MOCK_VERSION ? '../../mock/popular.json' : `${process.env.API_URL}/api/transactions/popular`;

    await applicationAPI({
      url: apiPopularTransactions,
      method: 'GET',
    })
      .then((response) => {
        dispatch({ type: 'FETCH_POPULAR-TRANSACTIONS_FULFILLED', payload: response.data });
      }).catch((err) => {
        dispatch({ type: 'FETCH_POPULAR-TRANSACTIONS_REJECTED', payload: err });
      });
  };
}

export function focusPopularTransactions(popularTransaction) {
  return function (dispatch) {
    dispatch({ type: 'FOCUS_POPULAR-TRANSACTIONS', payload: popularTransaction });
  };
}
