import applicationAPI from '../api/applicationAPI';

export function fetchAccounts() {
  const apiAccounts = process.env.MOCK_VERSION ? '../../mock/accounts.json' : `${process.env.API_URL}/api/accounts/`;

  return async (dispatch) => {
    await applicationAPI({
      url: apiAccounts,
      method: 'GET',
    }).then((response) => {
      //* ToDo добавить проверку на ответ /
      if (response.status === 'success') {
        // dispatch(postsSuccess(data.data));
      }
      dispatch({ type: 'FETCH_ACCOUNTS_FULFILLED', payload: response.data });
      dispatch({ type: 'FETCH_AMOUNT_ACCOUNTS', payload: response.data });
    }).catch((err) => {
      dispatch({ type: 'FETCH_ACCOUNTS_REJECTED', payload: err });
    });
  };
}

export function createAccount(account) {
  return async (dispatch) => {
    await applicationAPI({
      url: `${process.env.API_URL}/api/accounts/`,
      method: 'POST',
      data: account,
    }).then((response) => {
      window.console.log(response, 'CREATE ACCOUNT');

      account.id = response.data.account_id;
      dispatch({ type: 'CREATE_ACCOUNT', payload: account });
      // dispatch({ type: 'SHOW_SUCCESS_MODAL', payload: { message: 'Счёт создан' } });
      // dispatch({type: 'UPDATE_AMOUNT_ACCOUNTS_OF_CREATE_ACCOUNT', payload: account})
    }).catch((err) => {
      dispatch({ type: 'CREATE_ACCOUNT_REJECTED', payload: err });
      if (err.response) {
        dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
      }
    });
  };
}

export function deleteAccount(account) {
  return async (dispatch) => {
    await applicationAPI({
      url: `${process.env.API_URL}/api/accounts/${account.id}`,
      method: 'DELETE',
      data: account,
    })
      .then((response) => {
        dispatch({ type: 'DELETE_ACCOUNT', payload: account.id });
        // dispatch({type: 'UPDATE_AMOUNT_ACCOUNTS_OF_DELETE_ACCOUNT', payload: account})
        console.log(response);
      }).catch((err) => {
        dispatch({ type: 'DELETE_ACCOUNT_REJECTED', payload: err });
        if (err.response) {
          dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
        }
      });
    // }
  };
}

export function editAccount(account) {
  return async (dispatch) => {
    /* if (process.env.NODE_ENV === "development") {
      dispatch({type: 'EDIT_ACCOUNT', payload: account});
    } else { */
    await applicationAPI({
      url: `${process.env.API_URL}/api/accounts/${account.id}`,
      method: 'PATCH',
      data: account,
    })
      .then(() => {
        dispatch({ type: 'EDIT_ACCOUNT', payload: account });
      }).catch((err) => {
        dispatch({ type: 'EDIT_ACCOUNT_REJECTED', payload: err });
        if (err.response) {
          dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
        }
      });
  };
  /* } */
}

export function createAccountCorrection(correction) {
  return async (dispatch) => {
    await applicationAPI({
      url: `${process.env.API_URL}/api/accounts/${correction.account}/opening-balances`,
      method: 'POST',
      data: {
        date: correction.date,
        balance: correction.balance,
      },
    }).then(({ data }) => {
      dispatch({ type: 'EDIT_ACCOUNT', payload: data });
    }).catch((err) => {
      dispatch({ type: 'EDIT_ACCOUNT_REJECTED', payload: err });
      if (err.response) {
        dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
      }
    });
  };
}

export function deleteAccountCorrection({ account, correction }) {
  return async (dispatch) => {
    await applicationAPI({
      url: `${process.env.API_URL}/api/accounts/${account.id}/opening-balances/${correction.id}`,
      method: 'DELETE',
    }).then(({ data }) => {
      dispatch({ type: 'EDIT_ACCOUNT', payload: data });
    }).catch((err) => {
      dispatch({ type: 'EDIT_ACCOUNT_REJECTED', payload: err });
      if (err.response) {
        dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
      }
    });
  };
}
