/*
const calendarArrayRejected = [
  {"date":"2018-01-01","expense":0,"profit":0,"id":0},
  {"date":"2018-01-02","expense":0,"profit":0,"id":0},
  {"date":"2018-01-03","expense":0,"profit":0,"id":0},
  {"date":"2018-01-04","expense":0,"profit":0,"id":0},
  {"date":"2018-01-05","expense":0,"profit":0,"id":0},
  {"date":"2018-01-06","expense":0,"profit":0,"id":0},
  {"date":"2018-01-07","expense":0,"profit":0,"id":0},
  {"date":"2018-01-08","expense":0,"profit":0,"id":0},
  {"date":"2018-01-09","expense":0,"profit":0,"id":0},
  {"date":"2018-01-10","expense":0,"profit":0,"id":0},
  {"date":"2018-01-11","expense":0,"profit":0,"id":0},
  {"date":"2018-01-12","expense":0,"profit":0,"id":0},
  {"date":"2018-01-13","expense":0,"profit":0,"id":0},
  {"date":"2018-01-14","expense":0,"profit":0,"id":0},
  {"date":"2018-01-15","expense":0,"profit":0,"id":0},
  {"date":"2018-01-16","expense":0,"profit":0,"id":0},
  {"date":"2018-01-17","expense":0,"profit":0,"id":0},
  {"date":"2018-01-18","expense":0,"profit":0,"id":0},
  {"date":"2018-01-19","expense":0,"profit":0,"id":0},
  {"date":"2018-01-20","expense":0,"profit":0,"id":0},
  {"date":"2018-01-21","expense":0,"profit":0,"id":0}
];
*/

export default function reducer(state = {
  weekCalendar: [],
  fetching: false,
  fetched: false,
  weekDayTo: '',
  weekDayFrom: '',
  error: null,
}, action) {
  switch (action.type) {
    case 'FETCH_WEEK-CALENDAR': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'SET_WEEK_DATES': {
      return {
        ...state,
        weekDayTo: action.payload.weekDayTo,
        weekDayFrom: action.payload.weekDayFrom,
      };
    }
    case 'FETCH_WEEK-CALENDAR_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
      // return {...state, fetched: false, error: action.payload, weekCalendar: calendarArrayRejected}
    }
    case 'FETCH_WEEK-CALENDAR_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        weekCalendar: action.payload,
      };
    }
    default:
      return state;
  }
}
