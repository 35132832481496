import * as d3 from 'd3';

// Добавление в стейт новой категории
const addCategory = (categories, addedCategory) => {
  const newCategories = JSON.parse(JSON.stringify(categories));
  if (addedCategory.parent_id) {
    // Добавляем дочернюю категорию
    const parentIndex = newCategories.findIndex((item) => item.id === +addedCategory.parent_id);
    newCategories[parentIndex].children.push(addedCategory);
  } else {
    // Добавляем родительскую категорию
    addedCategory.children = [];
    newCategories.push(addedCategory);
  }
  return newCategories;
};

// Удаление из стейта выбранной категории
const deleteCategory = (categories, deletedCategory) => {
  const newCategories = JSON.parse(JSON.stringify(categories));
  if (deletedCategory.parent_id) {
    // Удаляем дочернюю категорию
    const parentIndex = newCategories.findIndex((item) => item.id === +deletedCategory.parent_id);
    const childIndex = newCategories[parentIndex].children.findIndex((item) => item.id === +deletedCategory.id);
    newCategories[parentIndex].children.splice(childIndex, 1);
  } else {
    // Удаляем родительскую категорию
    const index = newCategories.findIndex((item) => item.id === +deletedCategory.id);
    newCategories.splice(index, 1);
  }
  return newCategories;
};

// Редактирование выбранной категории
const editCategory = (categories, updatedCategory) => {
  let newCategories = JSON.parse(JSON.stringify(categories));
  if (updatedCategory.parent_id) {
    // Редактируем дочернюю категорию
    const parentIndex = newCategories.findIndex((item) => item.id === +updatedCategory.parent_id);
    const childIndex = newCategories[parentIndex].children.findIndex((item) => item.id === +updatedCategory.id);
    newCategories[parentIndex].children = [...newCategories[parentIndex].children.slice(0, childIndex), updatedCategory, ...newCategories[parentIndex].children.slice(childIndex + 1)];
  } else {
    // Редактируем родительскую категорию
    const index = newCategories.findIndex((item) => item.id === +updatedCategory.id);
    newCategories = [...newCategories.slice(0, index), updatedCategory, ...newCategories.slice(index + 1)];
  }
  return newCategories;
};

// Создание списка цветов для категорий
const createCategoriesColors = (categories) => {
  const newCategories = [];
  categories.forEach((cat) => {
    newCategories.push(cat);
    if (cat.children.length) {
      cat.children.forEach((catChild) => newCategories.push(catChild));
    }
  });

  const color = d3.scaleOrdinal(d3.schemeCategory10);
  return newCategories.map((cat, i) => ({
    value: cat.id,
    color: color(i),
  }));
};

export default function reducer(state = {
  categories: [],
  categoriesColors: [],
  fetching: false,
  fetched: false,
  error: null,
}, action) {
  switch (action.type) {
    /* case 'FETCH_CATEGORIES_PROCESS': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        categories: action.payload
      }
    } */
    case 'FETCH_CATEGORIES_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        categories: action.payload,
        categoriesColors: createCategoriesColors(action.payload),
      };
    }
    case 'FETCH_CATEGORY_PROCESS': {
      return {
        ...state,
        fetching: true,
        fetched: false,
        categories: action.payload,
        categoriesColors: createCategoriesColors(action.payload),
      };
    }
    // case 'FETCH_CATEGORIES': {
    //   return {...state, fetching: true}
    // }
    case 'FETCH_CATEGORIES_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
    }

    case 'CREATE_CATEGORY_PROCESS': {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case 'CREATE_CATEGORY_FULFILLED': {
      const newCategories = addCategory(state.categories, action.payload);
      return {
        ...state,
        categories: newCategories,
        categoriesColors: createCategoriesColors(newCategories),
      };
    }
    case 'CREATE_CATEGORY_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
    }
    case 'DELETE_CATEGORY': {
      const newCategories = deleteCategory(state.categories, action.payload);
      return {
        ...state,
        fetched: false,
        fetching: false,
        categories: newCategories,
        categoriesColors: createCategoriesColors(newCategories),
      };
    }
    case 'DELETE_CATEGORY_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
    }
    case 'EDIT_CATEGORY': {
      const newCategories = editCategory(state.categories, action.payload);
      return {
        ...state,
        categories: newCategories,
        categoriesColors: createCategoriesColors(newCategories),
      };
    }
    case 'EDIT_CATEGORY_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
