import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datetime';
import { useDispatch } from 'react-redux';

import { hideOnboarding } from '../../../actions/modalActions';

import LineChart from '../../LineChart/LineChart';

import 'moment/locale/ru';

export default function Onboarding() {
  const dispatch = useDispatch();

  const [sum, setSum] = useState('5000');
  const [date, setDate] = useState(moment(new Date()));
  const [expensePerDate, setExpensePerDate] = useState(0);
  const [data, setData] = useState([]);

  const getDates = (date) => {
    const currDate = moment();
    const lastDate = moment(date);

    const dates = [currDate.format('DD.MM.YYYY')];

    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      dates.push(currDate.clone().format('DD.MM.YYYY'));
    }

    return dates;
  };

  const onButtonClick = () => {
    const dates = getDates(date);
    const expensePerDate = +sum / dates.length;
    const data = dates.map((date, i) => ({
      date,
      expense: +sum - (expensePerDate * i),
    }));

    setData(data);
    setExpensePerDate(expensePerDate);
  };

  return (
    <div className="onboarding">
      <div className="onboarding__header">
        <h1 className="onboarding__header-title">Сколько можно тратить?</h1>
        <div className="onboarding__header-text">
          <div>Благодаря нашему приложению вести учёт денежных средств стало проще!</div>
          <div>Узнайте сколько можно тратить, заполнив поля ниже.</div>
        </div>
      </div>
      <div className="onboarding__chart">
        <div className="onboarding__chart-controls">
          <div className="onboarding__chart-control">
            <label htmlFor="sum">
              <div className="onboarding__chart-control-label">Сколько осталось денег?</div>
              <input
                id="sum"
                type="text"
                value={sum}
                onChange={(e) => setSum(e.target.value)}
              />
            </label>
          </div>
          <div className="onboarding__chart-control">
            <div className="onboarding__chart-control-label">Когда зарплата?</div>
            <DatePicker
              dateFormat="DD.MM.YYYY"
              timeFormat={false}
              value={date}
              onChange={(value) => setDate(new Date(value.toString()))}
              closeOnSelect
            />
          </div>
          <button type="button" onClick={onButtonClick}>Посчитать</button>
        </div>
        {!!expensePerDate && (
          <div className="onboarding__chart-text">
            Чтобы дожить до зарплаты рекомендуем тратить не более
            <strong>{` ${parseFloat(expensePerDate.toString()).toFixed(2)} ₽ `}</strong>
            в день
          </div>
        )}
        <LineChart
          width={1200}
          data={data}
          noProfit
        />
      </div>
      {!!expensePerDate && (
        <div className="onboarding__footer">
          <div className="onboarding__footer-text">Обалдел от таких сумм? Начни вести учет своих расходов!</div>
          <button className="onboarding__footer-button" type="button" onClick={() => dispatch(hideOnboarding())}>
            Начать вести учёт
          </button>
        </div>
      )}
    </div>
  );
}
