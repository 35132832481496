import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import setScreenDimensions from './actions/appActions';
import { fetchUser } from './actions/userActions';
import About from './pages/About/About';
import Calendar from './pages/Calendar/Calendar';
import CategoriesList from './pages/CategoriesList/CategoriesList';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import Contacts from './pages/Contacts/Contacts';
import Cooperation from './pages/Cooperation/Cooperation';
import Corrections from './pages/Corrections/Corrections';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import ModalDialog from './components/ModalDialog/ModalDialog';
import Nav from './components/Nav/Nav';
import NewPassword from './pages/NewPassword/NewPassword';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import Profile from './pages/Profile/Profile';
import Registration from './pages/Registration/Registration';
import Reports from './pages/Reports/Reports';
import Reset from './pages/Reset/Reset';

import './less/styled.less';

export default function App() {
  const dispatch = useDispatch();
  const fetching = useSelector((store) => store.user.fetching);
  const token = useSelector((store) => store.user.token);

  const [tokenRequestCompleted, setTokenRequestCompleted] = useState(false);
  const loggedIn = !!token && !!localStorage.getItem('jwt');

  const getScreenDimensions = () => dispatch(setScreenDimensions());

  useEffect(() => {
    getScreenDimensions();
    window.addEventListener('resize', getScreenDimensions);
    dispatch(fetchUser());

    return () => window.removeEventListener('resize', getScreenDimensions);
  }, []);

  // Ожидаем окончания самого первого запроса на получения токена
  // Когда значение получено (неважно null или токен), то устанавливаем флаг tokenRequestCompleted в true
  // Теперь будут показываться компоненты согласно значению токена, а лоадер исчезнет
  useEffect(() => {
    if (!fetching && !tokenRequestCompleted) setTokenRequestCompleted(true);
  }, [fetching]);

  // Не показываем контент, пока флаг tokenRequestCompleted не установлен в true
  // В пустом контейнере по умолчанию отображается лоадер
  return (
    tokenRequestCompleted && (
      <Router>
        <div className="wrapper">
          <Nav />
          <div className={['main-content', loggedIn ? '' : 'main-content_unloggedin'].join(' ')}>
            <Switch>
              <Route exact path="/">
                {loggedIn ? <Home /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/categories">
                {loggedIn ? <CategoriesList /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/corrections">
                {loggedIn ? <Corrections /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/calendar">
                {loggedIn ? <Calendar /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/reports">
                {loggedIn ? <Reports /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/profile">
                {loggedIn ? <Profile /> : <Redirect to="/login" />}
              </Route>
              <Route exact path="/cooperation">
                <Cooperation />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/contacts">
                <Contacts />
              </Route>
              <Route exact path="/login">
                {loggedIn ? <Redirect to="/" /> : <Login />}
              </Route>
              <Route exact path="/registration">
                {loggedIn ? <Redirect to="/" /> : <Registration />}
              </Route>
              <Route exact path="/reset">
                <Reset />
              </Route>
              <Route exact path="/new-password/:token">
                <NewPassword />
              </Route>
              <Route exact path="/confirm-email/:token">
                <ConfirmEmail />
              </Route>
              <Route exact path="/404">
                <NotFoundPage />
              </Route>
              <Route exact path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </div>
          <Footer />
          <ModalDialog />
        </div>
      </Router>
    )
  );
}
