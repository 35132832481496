import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

export default function Grid(props) {
  const { h, grid, gridType } = props;

  const gridRef = useRef(null);

  useEffect(() => {
    d3.select(gridRef.current).call(grid).call((g) => g.select('.domain').remove());
  }, [grid]);

  return (
    <g
      ref={gridRef}
      className="y-grid"
      transform={gridType === 'x' ? `translate(0,${h})` : null}
    />
  );
}
