import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const year = moment().format('YYYY');

  return (
    <footer className="main-footer main-footer_fixed">
      <div className="container">
        <div className="main-footer__menu">
          <ul className="menu-horizontal">
            <li className="menu-horizontal__item">
              <Link to="/about">О приложении</Link>
            </li>
            <li className="menu-horizontal__item">
              <Link to="/cooperation">Сотрудничество</Link>
            </li>
            <li className="menu-horizontal__item">
              <Link to="/contacts">Контакты</Link>
            </li>
          </ul>
        </div>

        <div className="main-footer__dev-link">
          Разработка
          &nbsp;
          <a target="_blank" rel="noreferrer noopener" href="https://accurateweb.ru">
            <b>accurateweb.ru</b>
          </a>
        </div>

        <div className="main-footer__copyright">
          {`© ${year} дожитьдозарплаты.рф`}
        </div>
      </div>
    </footer>
  );
}
