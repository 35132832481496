import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactSwipe from 'react-swipe';

export default function Carousel(props) {
  const {
    loadedTablePrev, loadedTableNext, renderLastWeek, renderCurrentWeek, renderNextWeek,
  } = props;

  const weekCalendar = useSelector((store) => store.weekCalendar);

  let reactSwipeEl;
  const [defaultButtonLeft, setDefaultButtonLeft] = useState(false);
  const [defaultButtonRight, setDefaultButtonRight] = useState(false);

  useEffect(() => {
    // Ожидаем окончания загрузки данных календаря, после чего разблокируем кнопки.
    if (!weekCalendar.fetching) {
      setDefaultButtonRight(false);
      setDefaultButtonLeft(false);
    }
  }, [weekCalendar.fetching]);

  const nextSwipe = (e) => {
    e.preventDefault();
    reactSwipeEl.next();
    setDefaultButtonRight(true);
  };

  const prevSwipe = (e) => {
    e.preventDefault();
    reactSwipeEl.prev();
    setDefaultButtonLeft(true);
  };

  return (
    <div>
      <span
        className="arrow arrow__left"
        onClick={defaultButtonLeft ? null : prevSwipe}
      >
        <i className="fa fa-chevron-left" aria-hidden="true" />
      </span>
      <ReactSwipe
        ref={(reactSwipe) => (reactSwipeEl = reactSwipe)}
        className="carousel"
        swipeOptions={{
          continuous: false,
          startSlide: 1,
          speed: 300,
          cellPadding: 0,
          callback(index) {
            // Callback принимает index, который указывает, куда в последний раз сместилась карусель
            // 2 - смещение влево, поэтому загружаем следующую неделю в карусель
            // 0 - смещение вправо, поэтому загружаем предыдущую неделю в карусель
            if (index === 2) loadedTableNext();
            if (index === 0) loadedTablePrev();
          },
        }}
      >
        <div className="calendar-table">
          <div className="table-row__calendar-slick">
            {renderLastWeek()}
          </div>
        </div>
        <div className="calendar-table">
          <div className="table-row__calendar-slick">
            {renderCurrentWeek()}
          </div>
        </div>
        <div className="calendar-table">
          <div className="table-row__calendar-slick">
            {renderNextWeek()}
          </div>
        </div>
      </ReactSwipe>
      <span
        className="arrow arrow__right"
        onClick={defaultButtonRight ? null : nextSwipe}
      >
        <i className="fa fa-chevron-right" aria-hidden="true" />
      </span>
    </div>
  );
}
