import moment from 'moment';
import React from 'react';

import DateInput from './DateInput';

export default function PeriodForm(props) {
  const {
    fromDate, toDate, onFromChange, onToChange, onFormSubmit,
  } = props;

  const disabled = moment(fromDate) >= moment(toDate);

  return (
    <div className="period-form">
      <div className="period-menu__item text-input">
        <span className="period-from-text">С:</span>
      </div>
      <DateInput
        value={fromDate}
        onChangeValue={onFromChange}
      />
      <div className="period-menu__item text-input">
        <span className="period-from-text">По:</span>
      </div>
      <DateInput
        value={toDate}
        onChangeValue={onToChange}
      />
      <button type="button" className="btn" disabled={disabled} onClick={onFormSubmit}>Показать</button>
    </div>
  );
}
