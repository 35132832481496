import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { fetchExpenseDonutTurnover, fetchProfitDonutTurnover } from '../../actions/donutTurnoverActions';
import { fetchLineTurnover } from '../../actions/lineTurnoverActions';

import PeriodForm from './PeriodForm';

const Icon = styled.i`
    cursor: pointer;
    &:hover {
        color: #3e6573;
    }
`;

export default function PeriodFilter() {
  const dispatch = useDispatch();

  const [from, setFrom] = useState(moment().startOf('month'));
  const [to, setTo] = useState(moment());
  const [period, setPeriod] = useState(null);
  const [showInputs, setShowInputs] = useState(false);

  const onPeriodSubmit = (from, to, period) => {
    const dateFrom = moment(from).format('YYYY-MM-DD');
    const dateTo = moment(to).format('YYYY-MM-DD');

    dispatch(fetchExpenseDonutTurnover({ from: dateFrom, to: dateTo, period }));
    dispatch(fetchProfitDonutTurnover({ from: dateFrom, to: dateTo, period }));
    dispatch(fetchLineTurnover({ from: dateFrom, to: dateTo, period }));
  };

  const onWeekSubmit = () => {
    setShowInputs(false);
    setPeriod('week');
    onPeriodSubmit(
      moment().subtract(7, 'days'),
      moment(),
      'week',
    );
  };

  const onMonthSubmit = () => {
    setShowInputs(false);
    setPeriod('month');
    onPeriodSubmit(
      moment().startOf('month'),
      moment(),
      'month',
    );
  };

  const onYearSubmit = () => {
    setShowInputs(false);
    setPeriod('year');
    onPeriodSubmit(
      moment().startOf('year'),
      moment(),
      'year',
    );
  };

  const onToggleClick = () => {
    setShowInputs(!showInputs);
    setPeriod(showInputs ? 'month' : null);
    if (showInputs) {
      onMonthSubmit();
      setFrom(moment().startOf('month'));
      setTo(moment());
    }
  };

  useEffect(() => {
    onMonthSubmit();
  }, []);

  return (
    <div className="period-menu">
      <div className={period === 'week' ? 'period-menu__item period-menu__item_active' : 'period-menu__item'}>
        <span
          className="period-menu__link period-this-week"
          onClick={onWeekSubmit}
        >
          Неделя
        </span>
      </div>
      <div className={period === 'month' ? 'period-menu__item period-menu__item_active' : 'period-menu__item'}>
        <span
          className="period-menu__link period-this-month"
          onClick={onMonthSubmit}
        >
          Месяц
        </span>
      </div>
      <div className={period === 'year' ? 'period-menu__item period-menu__item_active' : 'period-menu__item'}>
        <span
          className="period-menu__link period-this-year"
          onClick={onYearSubmit}
        >
          Год
        </span>
      </div>
      <div className="period-menu__item">
        <Icon
          className="fa fa-calendar"
          style={showInputs ? { color: '#3e6573' } : null}
          aria-hidden="true"
          onClick={onToggleClick}
        />
        {showInputs && (
          <PeriodForm
            fromDate={from}
            toDate={to}
            onFromChange={setFrom}
            onToChange={setTo}
            onFormSubmit={() => onPeriodSubmit(from, to)}
          />
        )}
      </div>
    </div>
  );
}
