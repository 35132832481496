export default function reducer(state = {
  accounts: [],
  fetching: false,
  fetched: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'FETCH_ACCOUNTS': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_ACCOUNTS_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
    }
    case 'FETCH_ACCOUNTS_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        accounts: action.payload,
      };
    }
    case 'CREATE_ACCOUNT': {
      return {
        ...state,
        ...state,
        accounts: [
          ...state.accounts, action.payload,
        ],
      };
    }
    case 'CREATE_ACCOUNT_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
    }
    case 'DELETE_ACCOUNT': {
      return {
        ...state,
        accounts: [
          ...state.accounts.filter((account) => action.payload !== account.id),
        ],
      };
    }
    case 'DELETE_ACCOUNT_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
    }
    case 'EDIT_ACCOUNT': {
      const editedAccountIndex = state.accounts.findIndex((account) => account.id === action.payload.id);
      return {
        ...state,
        accounts: [
          ...state.accounts.slice(0, editedAccountIndex),
          action.payload,
          ...state.accounts.slice(editedAccountIndex + 1),
        ],
      };
    }
    case 'EDIT_ACCOUNT_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
