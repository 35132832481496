import applicationAPI from '../api/applicationAPI';

// Получение данных пользователя
export function fetchProfileInfo() {
  const apiProfileInfo = process.env.MOCK_VERSION ? '/mock/profile/profileInfo.json' : `${process.env.API_URL}/api/profile/info`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_PROFILE' });
    await applicationAPI({
      url: apiProfileInfo,
      method: 'GET',
    }).then((response) => {
      dispatch({ type: 'FETCH_PROFILE_FULFILLED', payload: response.data });
    }).catch((error) => {
      dispatch({ type: 'FETCH_PROFILE_REJECTED', payload: error.message });
    });
  };
}

// Запрос на изменение данных пользователя
export function fetchProfileUpdate(data) {
  const apiProfileUpdate = process.env.MOCK_VERSION ? '/mock/profile/profileUpdate.json' : `${process.env.API_URL}/api/profile/update`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_PROFILE' });
    await applicationAPI({
      url: apiProfileUpdate,
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
      data,
    }).then((response) => {
      dispatch({ type: 'UPDATE_PROFILE_FULFILLED', payload: response.data });
      dispatch({ type: 'RESET_PROFILE_FORM', payload: true });
    })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_PROFILE_REJECTED',
          payload: error.response.data.errors && error.response.data.errors.length ? error.response.data.errors : error.message,
        });
      });
  };
}

// Запрос на обновление фото пользователя
export function fetchProfilePhotoUpdate(data) {
  const apiProfilePhotoUpdate = process.env.MOCK_VERSION ? '/mock/profile/profilePhotoUpdate.json' : `${process.env.API_URL}/api/profile/photo`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_PROFILE' });
    await applicationAPI({
      url: apiProfilePhotoUpdate,
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
      data,
    }).then((response) => {
      dispatch({ type: 'UPDATE_PROFILE_PHOTO_FULFILLED', payload: response.data });
    }).catch((error) => {
      dispatch({
        type: 'UPDATE_PROFILE_PHOTO_REJECTED',
        payload: error.response.data.errors && error.response.data.errors.profile ? error.response.data.errors.profile : error.message,
      });
    }).finally(() => {
      dispatch({ type: 'CLOSE_CROP_PHOTO_MODAL' });
    });
  };
}

// Запрос на обновление фото пользователя
export function fetchProfilePhotoDelete(data) {
  const apiProfilePhotoUpdate = process.env.MOCK_VERSION ? '/mock/profile/profilePhotoUpdate.json' : `${process.env.API_URL}/api/profile/photo`;
  return async (dispatch) => {
    dispatch({ type: 'FETCH_PROFILE' });
    await applicationAPI({
      url: apiProfilePhotoUpdate,
      method: process.env.MOCK_VERSION ? 'GET' : 'DELETE',
      data,
    }).then(() => {
      dispatch({ type: 'DELETE_PROFILE_PHOTO_FULFILLED' });
    })
      .catch((error) => {
        dispatch({
          type: 'DELETE_PROFILE_PHOTO_REJECTED',
          payload: error.response.data.errors && error.response.data.errors.profile ? error.response.data.errors.profile : error.message,
        });
      });
  };
}

// Запрос на отправку письма для подтверждения e-mail
export function sendConfirmEmail() {
  const apiSendConfirmEmail = process.env.MOCK_VERSION
    ? '/mock/profile/sendConfirmEmail.json'
    : `${process.env.API_URL}/api/confirm-email/send`;

  return async (dispatch) => {
    await applicationAPI({
      url: apiSendConfirmEmail,
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
    }).then((response) => {
      dispatch({ type: 'SHOW_SUCCESS_MODAL', payload: response.data });
    }).catch((err) => {
      if (err.response) dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
    });
  };
}

// Запрос на подтверждение e-mail
export function confirmEmail(token) {
  const apiConfirmEmail = process.env.MOCK_VERSION
    ? '/mock/profile/confirmEmail.json'
    : `${process.env.API_URL}/api/confirm-email/${token}`;

  return async (dispatch) => {
    await applicationAPI({
      url: apiConfirmEmail,
      method: process.env.MOCK_VERSION ? 'GET' : 'POST',
    }).then((response) => {
      dispatch({ type: 'SHOW_SUCCESS_MODAL', payload: { message: 'Ваш e-mail успешно подтверждён' } });
      dispatch({ type: 'FETCH_PROFILE_FULFILLED', payload: response.data });
    }).catch((err) => {
      if (err.response) dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
    });
  };
}
