import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import Select, { components } from 'react-select';

class SelectCategory extends Component {
  render() {
    const styles = {
      control: (styles) => ({
        ...styles,
        minHeight: 0,
        height: '24px',
        backgroundColor: '#f1f1f1',
        border: 'none',
        borderRadius: 'none',
        boxShadow: 'none',
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0',
      }),
      indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: '#f1f1f1',
      }),
      menu: (styles) => ({
        ...styles,
        marginTop: '3px',
        borderRadius: 'none',
      }),
      menuList: (styles) => ({
        ...styles,
        padding: '0',
        fontSize: '14px',
      }),
      option: (styles, props) => ({
        ...styles,
        padding: '5px',
        color: props.isSelected ? '#ffffff' : props.data.color,
        backgroundColor: props.isSelected ? '#1d6d7e' : '#ffffff',
      }),
      valueContainer: (styles) => ({
        ...styles,
        display: 'flex',
        flexWrap: 'nowrap',
        height: '24px',
        padding: '1px 8px',
      }),
    };

    const DropdownIndicator = (props) => (
      <components.DropdownIndicator {...props}>
        <div className="dropdown-arrow" />
      </components.DropdownIndicator>
    );

    return (
      <Select
        components={{ DropdownIndicator }}
        options={this.props.options}
        defaultValue={this.props.defaultValue}
        onChange={(e) => this.props.setValue(e.value)}
        isSearchable={false}
        styles={styles}
      />
    );
  }
}

export default withFormsy(SelectCategory);
