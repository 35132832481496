import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { fetchAccounts } from '../../actions/accountsActions';
import { fetchCalendar, setFilterCalendar } from '../../actions/calendarActions';
import { fetchCategories } from '../../actions/categoriesActions';

import CalendarCell from './components/CalendarCell';
import CalendarFilter from './components/CalendarFilter';

const OverlayLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,.25);
`;

const Spinner = styled.span`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%, -50%);
`;

export default function Calendar() {
  const dispatch = useDispatch();
  const date = useSelector((store) => store.calendar.calendar);

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchCategories());
    dispatch(fetchCalendar({ month: moment().format('MM'), year: moment().format('YYYY') }));
    dispatch(setFilterCalendar([moment().format('MM'), moment().format('YYYY')]));
  }, []);

  if (date.length) {
    let firstDay = moment(date[0].date).day();
    if (firstDay === 0) firstDay = 7;

    let lastDay = moment(date[date.length - 1].date).day();
    if (lastDay === 0) lastDay = 7;

    let days = [];

    for (let i = 1; i < firstDay; i++) {
      days.push({
        date: moment(date[0].date).subtract(firstDay - i, 'days').format('YYYY-MM-DD'),
        transactions: [],
        profit: null,
        expense: null,
      });
    }

    days = days.concat(date);

    for (let i = lastDay + 1; i <= 7; i++) {
      days.push({
        date: moment(date[date.length - 1].date).add(i - lastDay, 'days').format('YYYY-MM-DD'),
        transactions: [],
        profit: null,
        expense: null,
      });
    }

    const weeks = [];

    for (let i = 0; i < days.length; i += 7) {
      weeks.push(days.slice(i, Math.min(i + 7, days.length)));
    }

    weeks.forEach((week) => week.forEach((day) => day.visibleTransactions = day.transactions.slice(0, 1)));

    const cellHeight = weeks.length ? 100 / weeks.length : 0;

    return (
      <div className="container-flex">
        <div className="period-menu-navigation">
          <div className="menu-navigation-wrap container_full-width">
            <div className="scroll-period-menu">
              <CalendarFilter />
            </div>
          </div>
        </div>
        <div className="calendar">
          <table className="calendar-table__head">
            <tbody>
              <tr className="calendar-table__month-week">
                <td className="calendar-table__cell-week">пн&nbsp;</td>
                <td className="calendar-table__cell-week">вт&nbsp;</td>
                <td className="calendar-table__cell-week">ср&nbsp;</td>
                <td className="calendar-table__cell-week">чт&nbsp;</td>
                <td className="calendar-table__cell-week">пт&nbsp;</td>
                <td className="calendar-table__cell-week">сб&nbsp;</td>
                <td className="calendar-table__cell-week">вс&nbsp;</td>
              </tr>
            </tbody>
          </table>
          {weeks.map((week, j) => (
            <div className="calendar-month__row" style={{ height: `${cellHeight}%`, minHeight: '120px' }} key={Math.sqrt(j)}>
              <table className="calendar-table">
                <tbody>
                  <tr>
                    {week.map((day, i) => (
                      <CalendarCell
                        key={Math.sqrt(i)}
                        day={day}
                        keyWeek={j}
                        weeks={weeks}
                      />
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <OverlayLoader>
      <Spinner>
        <i className="fa fa-spinner fa-pulse fa-fw" />
      </Spinner>
    </OverlayLoader>
  );
}
