export default function reducer(state = {
  popularTransactions: [],
  focusPopularTransaction: null,
  fetching: false,
  fetched: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'FETCH_POPULAR-TRANSACTIONS': {
      return { ...state, fetching: true };
    }
    case 'FETCH_POPULAR-TRANSACTIONS_REJECTED': {
      return { ...state, fetched: false, error: action.payload };
    }
    case 'FETCH_POPULAR-TRANSACTIONS_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        popularTransactions: action.payload,
      };
    }
    case 'FOCUS_POPULAR-TRANSACTIONS': {
      return {
        ...state,
        focusPopularTransaction: action.payload,
      };
    }
    case '@@redux-form/CHANGE': {
      return {
        ...state,
        focusPopularTransaction: null,
      };
    }
    default:
      return state;
  }
}
