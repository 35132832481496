import React from 'react';

import Amount from '../../UI/Amount';

export default function AccountsItem(props) {
  const {
    name, balance, color, icon, onAdd, onEdit, onDelete,
  } = props;

  return (
    <div className="accounts__row">
      <div className="accounts__name">
        {color ? <i className={`fa ${icon}`} style={{ color }} /> : <i className={`fa ${icon}`} />}
          &nbsp;
        {name}
        <div className="accounts__controls controls">
          <span
            onClick={onAdd}
            className="controls__btn add-account"
          >
            <i
              className="fa fa-plus"
              aria-hidden="true"
            />
          </span>
          <span
            onClick={onEdit}
            className="controls__btn edit-account"
          >
            <i
              className="fa fa-pencil"
              aria-hidden="true"
            />
          </span>
          <span
            onClick={onDelete}
            className="controls__btn delete-account"
            data-id="{{ account.getId() }}"
          >
            <i className="fa fa-times" aria-hidden="true" />
          </span>
        </div>
      </div>
      <div className="accounts__balance">
        {balance !== '' ? (
          <span>
            <Amount amount={balance} />
              &nbsp;&#8381;
          </span>
        ) : (
          <span>
            <i className="fa fa-spinner fa-pulse fa-fw" />
            <span className="sr-only">Loading...</span>
          </span>
        )}
      </div>
    </div>
  );
}
