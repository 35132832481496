import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';

export default function Axis(props) {
  const { h, axis, axisType } = props;

  const axisRef = useRef(null);

  useEffect(() => {
    d3.select(axisRef.current).call(axis);
  }, [axis]);

  return (
    <g
      ref={axisRef}
      className="axis"
      transform={axisType === 'x' ? `translate(0,${h})` : null}
    />
  );
}
