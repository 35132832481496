import React from 'react';
import Accounting from '../../util/accounting';

Accounting.settings = {
  currency: {
    symbol: '₽', // default currency symbol is 'RUB'
    format: '%v %s', // controls output: %s = symbol, %v = value/number (can be object: see below)
    decimal: ',', // decimal point separator
    thousand: ' ', // thousands separator
    precision: 2, // decimal places
  },
  number: {
    precision: 2, // default precision on numbers is 0
    thousand: ' ',
    decimal: ',',
  },
};

Number.prototype.toCurrencyString || (Number.prototype.toCurrencyString = function () {
  /* precision, thousand, decimal */
  const val = (this instanceof Number) ? this.valueOf() : this;
  return Accounting.formatNumber.apply(this, [val].concat(Array.prototype.slice.call(arguments, 0)));
});

export default class Amount extends React.Component {
  render() {
    const type = this.props.type || null;
    let sign = '';
    if (type === 'profit') {
      sign = '+ ';
    } else if (type === 'expense') {
      sign = '− ';
    }

    const amount = +this.props.amount;
    const showCurrency = this.props.showCurrency ? ' ₽' : '';

    const AmountCount = amount.toCurrencyString();
    let newAmount = '';
    const AmountBody = AmountCount.split(',')[0];

    const AmountPrefix = AmountCount.split(',')[1];
    // пробная версия, может не зайти
    if (AmountPrefix !== '00') {
      return (
        <span>
          {sign}
          {AmountBody}
          ,
          <i className="amount-prefix__small">{AmountPrefix}</i>
          {showCurrency}
        </span>
      );
    }
    newAmount = AmountBody;
    return (
      <span>
        {sign}
        {newAmount}
        {/* <i>&nbsp;&nbsp;&nbsp;</i> */}
        {showCurrency}
      </span>
    );
  }
}
