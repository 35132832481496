import applicationAPI from '../api/applicationAPI';

export function fetchCategories() {
  const apiCategory = process.env.MOCK_VERSION
    ? '../../mock/categories.json'
    : `${process.env.API_URL}/api/categories/`;

  return async (dispatch) => {
    await applicationAPI({
      url: apiCategory,
      method: 'GET',
    })
      .then((response) => {
        dispatch({ type: 'FETCH_CATEGORIES_FULFILLED', payload: response.data });
      }).catch((err) => {
        dispatch({ type: 'FETCH_CATEGORIES_REJECTED', payload: err });
      });
  };
}

export function createCategory(category) {
  return async (dispatch) => {
    dispatch({ type: 'CREATE_CATEGORY_PROCESS', payload: category });

    await applicationAPI({
      url: `${process.env.API_URL}/api/categories/`,
      method: 'POST',
      data: category,
    }).then((response) => {
      category.id = response.data.id;
      dispatch({ type: 'CREATE_CATEGORY_FULFILLED', payload: category });
    }).catch((err) => {
      dispatch({ type: 'CREATE_CATEGORY_REJECTED', payload: err });
      if (err.response) {
        dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
      }
    });
  };
}

export function deleteCategory(category) {
  return async (dispatch) => {
    await applicationAPI({
      url: `${process.env.API_URL}/api/categories/${category.id}`,
      method: 'DELETE',
      data: category,
    })
      .then(() => {
        dispatch({ type: 'DELETE_CATEGORY', payload: category });
      })
      .catch((err) => {
        dispatch({ type: 'DELETE_CATEGORY_REJECTED', payload: err });
        if (err.response) {
          dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
        }
      });
  };
}

export function editCategory(category) {
  return async (dispatch) => {
    await applicationAPI({
      url: `${process.env.API_URL}/api/categories/${category.id}`,
      method: 'PATCH',
      data: {
        name: category.name,
        type: category.type,
      },
    })
      .then(() => {
        dispatch({ type: 'EDIT_CATEGORY', payload: category });
      }).catch((err) => {
        dispatch({ type: 'EDIT_CATEGORY_REJECTED', payload: err });
        if (err.response) {
          dispatch({ type: 'SHOW_ERROR_MODAL', payload: err.response });
        }
      });
  };
}
