import React from 'react';

import { Error, Warning } from './Styled';

export default function CheckboxField(props) {
  const {
    input, label, type, meta,
  } = props;
  const { touched, error, warning } = meta;

  return (
    <div>
      <input id={input.name} type={type} {...input} />
      {touched && ((error && <Error>{error}</Error>) || (warning && <Warning>{warning}</Warning>))}
      <label htmlFor={input.name}>{label}</label>
    </div>
  );
}
