export default function reducer(state = {
  amount: null,
  fetching: false,
  fetched: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'FETCH_AMOUNT_ACCOUNTS': {
      const amount = action.payload.reduce((sum, current) => {
        const amount = sum + parseFloat(current.balance);
        return parseFloat(amount);
      }, 0);
      return {
        ...state,
        fetched: true,
        amount,
      };
    }
    case 'UPDATE_AMOUNT_ACCOUNTS_OF_CREATE_ACCOUNT': {
      return {
        ...state,
        amount: state.amount + action.payload.balance,
      };
    }
    case 'UPDATE_AMOUNT_ACCOUNTS_OF_DELETE_ACCOUNT': {
      return {
        ...state,
        amount: state.amount - action.payload.balance,
      };
    }
    case 'UPDATE_AMOUNT_ACCOUNTS_OF_EDIT_ACCOUNT': {
      return {
        ...state,
        amount: state.amount + action.payload,
      };
    }
    default:
      return state;
  }
}
