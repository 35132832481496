export default function reducer(state = {
  calendar: [],
  fetching: false,
  fetched: false,
  filterParam: [],
  error: null,
}, action) {
  switch (action.type) {
    case 'FETCH_CALENDAR': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_CALENDAR_REJECTED': {
      return {
        ...state,
        fetched: false,
        error: action.payload,
      };
      // return {...state, fetched: false, error: action.payload, weekCalendar: calendarArrayRejected}
    }
    case 'FETCH_CALENDAR_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        calendar: action.payload,
      };
    }
    case 'SET_FILTER_CALENDAR': {
      return {
        ...state,
        filterParam: action.payload,
        fetching: true,
      };
    }
    case 'CLEAR_FILTER_CALENDAR': {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
