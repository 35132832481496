export default function reducer(state = {
  expenseDonutTurnover: [],
  expenseSum: '',
  expenseFetching: false,
  expenseFetched: false,
  expenseError: null,
  profitDonutTurnover: [],
  profitSum: '',
  profitFetching: false,
  profitFetched: false,
  profitError: null,
  period: 'month',
  active: true,
}, action) {
  switch (action.type) {
    case 'FETCH_EXPENSE-DONUT-TURNOVER': {
      return { ...state, expenseFetching: true };
    }
    case 'FETCH_PROFIT-DONUT-TURNOVER': {
      return { ...state, profitFetching: true };
    }
    case 'FETCH_EXPENSE-DONUT-TURNOVER_REJECTED': {
      return { ...state, expenseFetched: false, expenseError: action.payload };
    }
    case 'FETCH_PROFIT-DONUT-TURNOVER_REJECTED': {
      return { ...state, profitFetched: false, profitError: action.payload };
    }
    case 'FETCH_EXPENSE-DONUT-TURNOVER_FULFILLED': {
      return {
        ...state,
        expenseFetching: false,
        expenseFetched: true,
        expenseDonutTurnover: action.payload.turnover,
        expenseSum: action.payload.sum,
        period: action.period,
      };
    }
    case 'FETCH_PROFIT-DONUT-TURNOVER_FULFILLED': {
      return {
        ...state,
        profitFetching: false,
        profitFetched: true,
        profitDonutTurnover: action.payload.turnover,
        profitSum: action.payload.sum,
        period: action.period,
      };
    }
    case 'HIDE_DONUT-TURNOVER': {
      return {
        ...state,
        active: false,
      };
    }
    case 'SHOW_DONUT-TURNOVER': {
      return {
        ...state,
        active: true,
      };
    }
    default:
      return state;
  }
}
