import styled from 'styled-components';

export const Error = styled.span`
  display: block;
  color: #ff0000;
  position: relative;
  top: -30px;
`;

export const Warning = styled(Error)`
  display: block;
  color: #ff0000;
  position: relative;
  top: -30px;
`;
