import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import { createUser } from '../../actions/userActions';
import TextField from '../../components/UI/TextField';

const validate = (values) => {
  const errors = {};
  const {
    email, username, password, repeatPassword,
  } = values;
  if (!password) {
    errors.password = 'Обязательно для заполнения';
  } else if (password.trim().length <= 5) {
    errors.password = 'Минимальная длина пароля 6 символов';
  }

  if (!repeatPassword) {
    errors.repeatPassword = 'Обязательно для заполнения';
  } else if (repeatPassword.trim().length <= 5) {
    errors.repeatPassword = 'Минимальная длина пароля 6 символов';
  } else if (password !== repeatPassword) {
    errors.repeatPassword = 'Введенные пароли не совпадают';
  }

  if (!email) {
    errors.email = 'Обязательно для заполнения';
  } else if (!/^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = 'Неверный e-mail';
  }

  if (!username) {
    errors.username = 'Обязательно для заполнения';
  }

  return errors;
};

function Registration(props) {
  const { handleSubmit } = props;

  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);

  const onSubmit = (values) => {
    const data = { ...values };
    delete data.repeatPassword;
    dispatch(createUser(data));
  };

  return (
    <div className="sign-form">
      <h1 className="sign-form__header">Регистрация</h1>
      <div className="error" />
      <form onSubmit={handleSubmit((values) => onSubmit(values))}>
        <Field name="email" type="email" required component={TextField} label="E-mail" />
        <Field name="username" type="text" required component={TextField} label="Логин" />
        <Field name="password" type="password" required component={TextField} label="Пароль" />
        <Field name="repeatPassword" type="password" required component={TextField} label="Пароль еще раз" />

        <div>
          <div className="sign-form__submit">
            <button value="Submit" type="submit" className="submit">Зарегистрироваться</button>
          </div>
        </div>
      </form>
      <div className="sign-form__link_reg">
        <Link className="sign-form__link" to="/login">Вход</Link>
      </div>
      {user.errors && !!Object.keys(user.errors).length
        && <p className="sign-form__message">{user.errors[Object.keys(user.errors)[0]]}</p>}
    </div>
  );
}

export default reduxForm({
  form: 'userRegistration',
  validate,
})(Registration);
