import moment from 'moment';
import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import AccountForm from './components/AccountForm';
import CategoryForm from './components/CategoryForm';
import CorrectionForm from './components/CorrectionForm';
import CropPhotoForm from './components/CropPhotoForm';
import Onboarding from './components/Onboarding';
import TransactionFormWrap from './components/TransactionFormWrap';
import TransactionForm from './components/TransactionForm';

import {
  closeErrorModal,
  closeSuccessModal,
  hideCreateAccount,
  hideCreateAccountCorrection,
  hideCreateCategory,
  hideCreateTransaction,
  hideCropPhotoModal,
  hideDeleteAccount,
  hideDeleteAccountCorrection,
  hideDeleteCategory,
  hideDeleteTransaction,
  hideEditAccount,
  hideEditCategory,
  hideEditTransaction,
} from '../../actions/modalActions';
import { deleteAccount, deleteAccountCorrection, fetchAccounts } from '../../actions/accountsActions';
import { fetchCalendar } from '../../actions/calendarActions';
import { deleteCategory, fetchCategories } from '../../actions/categoriesActions';
import { fetchExpenseDonutTurnover, fetchProfitDonutTurnover } from '../../actions/donutTurnoverActions';
import { fetchLineTurnover } from '../../actions/lineTurnoverActions';
import { deleteTransaction, fetchTransactions } from '../../actions/transactionsActions';
import { fetchWeekCalendarCarousel } from '../../actions/weekCalendarCarouselActions';

// Все модалки будут находиться в одном компоненте и достать их можно будет из любого места
export default function ModalDialog() {
  const dispatch = useDispatch();
  const filterCalendar = useSelector((store) => store.calendar.filterParam);
  const parent = useSelector((store) => store.modalDialog.parent);
  const transactionAccount = useSelector((store) => store.modalDialog.transactionAccount);
  const accountDelete = useSelector((store) => store.modalDialog.accountDelete);
  const accountCorrectionDelete = useSelector((store) => store.modalDialog.accountCorrectionDelete);
  const categoryDelete = useSelector((store) => store.modalDialog.categoryDelete);
  const transactionDelete = useSelector((store) => store.modalDialog.transactionDelete);
  const showModalCreateTransaction = useSelector((store) => store.modalDialog.showModalCreateTransaction);
  const showModalDeleteTransaction = useSelector((store) => store.modalDialog.showModalDeleteTransaction);
  const showModalEditTransaction = useSelector((store) => store.modalDialog.showModalEditTransaction);
  const showModalCreateAccount = useSelector((store) => store.modalDialog.showModalCreateAccount);
  const showModalCreateAccountCorrection = useSelector((store) => store.modalDialog.showModalCreateAccountCorrection);
  const showModalCreateCategory = useSelector((store) => store.modalDialog.showModalCreateCategory);
  const showModalEditAccount = useSelector((store) => store.modalDialog.showModalEditAccount);
  const showModalEditCategory = useSelector((store) => store.modalDialog.showModalEditCategory);
  const showModalDeleteAccount = useSelector((store) => store.modalDialog.showModalDeleteAccount);
  const showModalDeleteAccountCorrection = useSelector((store) => store.modalDialog.showModalDeleteAccountCorrection);
  const showModalDeleteCategory = useSelector((store) => store.modalDialog.showModalDeleteCategory);
  const showModalError = useSelector((store) => store.modalDialog.showModalError);
  const showModalSuccess = useSelector((store) => store.modalDialog.showModalSuccess);
  const showModalCropPhoto = useSelector((store) => store.modalDialog.showModalCropPhoto);
  const showOnboarding = useSelector((store) => store.modalDialog.showOnboarding);
  const weekCalendar = useSelector((store) => store.weekCalendar);

  function fetchAllAccounts() {
    dispatch(fetchAccounts());
  }

  function fetchAllCategories() {
    dispatch(fetchCategories());
  }

  function fetchAllTransactions() {
    dispatch(fetchTransactions());
  }

  function fetchAllCalendar() {
    dispatch(fetchCalendar({ month: filterCalendar[0], year: filterCalendar[1] }));
  }

  function fetchCarousel() {
    dispatch(fetchWeekCalendarCarousel(weekCalendar.weekDayFrom, weekCalendar.weekDayTo));
  }

  function fetchDonutAndLineChart() {
    dispatch(fetchExpenseDonutTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
    dispatch(fetchProfitDonutTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
    dispatch(fetchLineTurnover({
      from: moment().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      period: 'month',
    }));
  }

  const handleSubmitDeleteAccount = () => {
    dispatch(deleteAccount(accountDelete))
      .then(fetchAllAccounts)
      .then(fetchAllTransactions);
    dispatch(hideDeleteAccount());
  };

  const handleSubmitDeleteAccountCorrection = () => {
    dispatch(deleteAccountCorrection(accountCorrectionDelete))
      .then(fetchAllAccounts);
    dispatch(hideDeleteAccountCorrection());
  };

  const handleSubmitDeleteCategory = () => {
    dispatch(deleteCategory(categoryDelete))
      .then(fetchAllCategories);
    dispatch(hideDeleteCategory());
  };

  const handleSubmitDeleteTransaction = () => {
    dispatch(deleteTransaction(transactionDelete))
      .then(fetchAllAccounts)
      .then(fetchAllCalendar)
      .then(fetchCarousel)
      .then(fetchDonutAndLineChart);
    dispatch(hideDeleteTransaction());
  };

  return (
    <>
      <Modal
        isOpen={showModalCreateTransaction}
        onRequestClose={() => dispatch(hideCreateTransaction())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">
            {`Добавление операции ${transactionAccount ? `для счёта "${transactionAccount.name}"` : ''}`}
          </h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideCreateTransaction())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <TransactionFormWrap />
        </div>
      </Modal>

      <Modal
        isOpen={showModalError.show}
        onRequestClose={() => dispatch(closeErrorModal())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">{showModalError.message}</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(closeErrorModal())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <form className="main-form center__button-form">
            <button
              type="button"
              onClick={() => dispatch(closeErrorModal())}
            >
              Понятно
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={showModalSuccess.show}
        onRequestClose={() => dispatch(closeSuccessModal())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">{showModalSuccess.message}</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(closeSuccessModal())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <form className="main-form center__button-form">
            <button
              type="button"
              onClick={() => dispatch(closeSuccessModal())}
            >
              Понятно
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={showModalDeleteCategory}
        onRequestClose={() => dispatch(hideDeleteCategory())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Удалить категорию?</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideDeleteCategory())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <form className="main-form center__button-form">
            <button
              type="button"
              onClick={() => handleSubmitDeleteCategory()}
            >
              Да
            </button>
            &nbsp;
            <button
              type="button"
              onClick={() => dispatch(hideDeleteCategory())}
              className="btn_cancel"
            >
              Отмена
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={showModalDeleteTransaction}
        onRequestClose={() => dispatch(hideDeleteTransaction())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Удалить операцию?</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideDeleteTransaction())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <form className="main-form center__button-form">
            <button
              type="button"
              onClick={() => handleSubmitDeleteTransaction()}
            >
              Да
            </button>
            &nbsp;
            <button
              type="button"
              onClick={() => dispatch(hideDeleteTransaction())}
              className="btn_cancel"
            >
              Отмена
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={showModalEditTransaction}
        onRequestClose={() => dispatch(hideEditTransaction())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Изменение операции</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideEditTransaction())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <TransactionForm edit />
        </div>
      </Modal>

      <Modal
        isOpen={showModalEditCategory}
        onRequestClose={() => dispatch(hideEditCategory())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Изменение категории</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideEditCategory())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <CategoryForm edit />
        </div>
      </Modal>

      <Modal
        isOpen={showModalEditAccount}
        onRequestClose={() => dispatch(hideEditAccount())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Изменение счёта</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideEditAccount())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <AccountForm edit />
        </div>
      </Modal>

      <Modal
        isOpen={showModalDeleteAccount}
        onRequestClose={() => dispatch(hideDeleteAccount())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Удалить счёт?</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideDeleteAccount())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <form className="main-form center__button-form">
            <button
              type="button"
              onClick={() => handleSubmitDeleteAccount()}
            >
              Да
            </button>
            &nbsp;
            <button
              type="button"
              onClick={() => dispatch(hideDeleteAccount())}
              className="btn_cancel"
            >
              Отмена
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={showModalDeleteAccountCorrection}
        onRequestClose={() => dispatch(hideDeleteAccountCorrection())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Удалить корректировку?</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideDeleteAccountCorrection())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <form className="main-form center__button-form">
            <button
              type="button"
              onClick={() => handleSubmitDeleteAccountCorrection()}
            >
              Да
            </button>
            &nbsp;
            <button
              type="button"
              onClick={() => dispatch(hideDeleteAccountCorrection())}
              className="btn_cancel"
            >
              Отмена
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={showModalCreateCategory}
        onRequestClose={() => dispatch(hideCreateCategory())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">{`Добавление ${parent ? 'подкатегории' : 'категории'}`}</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideCreateCategory())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <CategoryForm />
        </div>
      </Modal>

      <Modal
        isOpen={showModalCreateAccount}
        onRequestClose={() => dispatch(hideCreateAccount())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Добавление счёта</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideCreateAccount())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <AccountForm />
        </div>
      </Modal>

      <Modal
        isOpen={showModalCreateAccountCorrection}
        onRequestClose={() => dispatch(hideCreateAccountCorrection())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Добавление корректировки</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideCreateAccountCorrection())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <CorrectionForm />
        </div>
      </Modal>

      <Modal
        isOpen={showOnboarding}
        contentLabel="Modal"
        className="modal-window modal-window--onboarding"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal container_modal--onboarding">
          <Onboarding />
        </div>
      </Modal>

      <Modal
        isOpen={showModalCropPhoto}
        onRequestClose={() => dispatch(hideCropPhotoModal())}
        contentLabel="Modal"
        className="modal-window"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="container container_modal">
          <h3 className="modal-window__title">Изменение фото</h3>
          <div className="modal-window__close">
            <span onClick={() => dispatch(hideCropPhotoModal())}>
              <i className="fa fa-times" aria-hidden="true" />
            </span>
          </div>
          <CropPhotoForm />
        </div>
      </Modal>
    </>
  );
}
