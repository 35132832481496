import * as d3 from 'd3';
import React from 'react';

export default function Dots(props) {
  const {
    data, showToolTip, hideToolTip, x, y, noProfit,
  } = props;

  const profitDots = data.map((d) => d.profit > 0 && (
    <circle
      key={d.dates}
      className="dot_green"
      r="3"
      cx={x(d.dates)}
      cy={y(d.profit)}
      fill="#ffffff"
      stroke="#00c264"
      strokeWidth="3px"
      onMouseOver={(e) => showToolTip('#00c264', e)}
      onMouseOut={hideToolTip}
      data-key={d3.timeFormat('%b %e')(d.dates)}
      data-value={d.profit}
    />
  ));

  const expenseDots = data.map((d) => d.expense > 0 && (
    <circle
      key={d.dates}
      className="dot_red"
      r="3"
      cx={x(d.dates)}
      cy={y(d.expense)}
      fill="#ffffff"
      stroke={noProfit ? '#1d6d7e' : '#ff151e'}
      strokeWidth="3px"
      onMouseOver={(e) => showToolTip(noProfit ? '#1d6d7e' : '#ff151e', e)}
      onMouseOut={hideToolTip}
      data-key={d3.timeFormat('%b %e')(d.dates)}
      data-value={d.expense}
    />
  ));

  if (noProfit) {
    return (
      <g>{expenseDots}</g>
    );
  }

  return (
    <g>
      {profitDots}
      ,
      {expenseDots}
    </g>
  );
}
