import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import { loginUser } from '../../actions/userActions';
import CheckboxField from '../../components/UI/CheckboxField';
import TextField from '../../components/UI/TextField';

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Обязательно для заполнения';
  } else if (!/^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
    errors.username = 'Неверный e-mail';
  }

  if (!values.password) {
    errors.password = 'Обязательно для заполнения';
  }

  return errors;
};

function Login(props) {
  const { handleSubmit } = props;

  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);

  return (
    <div className="sign-form">
      <h3 className="sign-form__header">Вход</h3>
      <form onSubmit={handleSubmit((values) => dispatch(loginUser(values)))}>

        <Field name="username" type="email" required component={TextField} label="E-mail" />
        <Field name="password" type="password" required component={TextField} label="Пароль" />

        <div className="sign-form__submit">
          <button type="submit" id="_submit" name="_submit">Войти</button>

          <div className="sign-form__remember">
            <Field
              id="remember"
              name="remember"
              type="checkbox"
              required
              component={CheckboxField}
              label="Запомнить меня"
            />
            <Link className="sign-form__link" to="/reset">Забыли пароль?</Link>
          </div>

        </div>
      </form>

      <div className="sign-form__link_reg">
        <Link className="sign-form__link" to="/registration">Зарегистрироваться</Link>
      </div>

      {!!user.errorMessage && <span className="sign-form__message">{user.errorMessage}</span>}
    </div>
  );
}

export default reduxForm({
  form: 'userLogin',
  validate,
})(Login);
