export const accountTypes = [
  {
    label: 'Наличные',
    icon: 'fa-money',
  },
  {
    label: 'Банковская карта',
    icon: 'fa-credit-card-alt',
  },
  {
    label: 'Банковский счет',
    icon: 'fa-university',
  },
  {
    label: 'Кредит',
    icon: 'fa-credit-card',
  },
];

export const categoryTypes = [
  {
    label: 'Все типы',
    value: 3,
    color: '#363636',
  },
  {
    label: 'Доход и расход',
    value: 2,
    color: '#ff5722',
  },
  {
    label: 'Доход',
    value: 1,
    color: '#00ae06',
  },
  {
    label: 'Расход',
    value: 0,
    color: '#ff151e',
  },
];

export const transactionTypes = [
  {
    label: 'Расход',
    value: 1,
    color: '#ff151e',
  },
  {
    label: 'Доход',
    value: 2,
    color: '#00ae06',
  },
  {
    label: 'Перевод',
    value: 3,
    color: '#363636',
  },
];

export const transactionFilterTypes = [
  {
    label: 'Все типы',
    value: null,
    color: '#363636',
  },
].concat(transactionTypes);
