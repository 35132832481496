import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { confirmEmail } from '../../actions/profileActions';

export default function ConfirmEmail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    dispatch(confirmEmail(token));
  }, []);

  return (
    <div className="sign-form">
      <h1 className="sign-form__header">Подтверждение e-mail</h1>
      <div className="sign-form__submit">
        <button type="button" onClick={() => history.push('/profile')}>Перейти в профиль</button>
      </div>
    </div>
  );
}
