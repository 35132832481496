import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import app from './appReducer';
import categories from './categoriesReducer';
import transactionsList from './transactionsReducer';
import accounts from './accountsReducer';
import weekCalendar from './weekCalendarCarouselReducer';
import popularTransactions from './popularTransactionsReducer';
import profileInfo from './profileReducer';
import amountAccounts from './amountAccountsReducer';
import donutTurnover from './donutTurnoverReducer';
import lineTurnover from './lineTurnoverReducer';
import calendar from './calendarReducer';
import user from './userReducer';
// держим состояние модалок в глобальном сторе
import modalDialog from './modalReducer';
// для содержания состояния формы в глобальном сторе
// почитать про использование pluginReducer

import formGlobal from './formTransactionsReducer';

export default combineReducers({
  app,
  user,
  categories,
  transactionsList,
  accounts,
  weekCalendar,
  popularTransactions,
  profileInfo,
  amountAccounts,
  lineTurnover,
  donutTurnover,
  calendar,
  formGlobal,
  modalDialog,

  form: formReducer.plugin({
    userPasswordReset: (state, action) => {
      switch (action.type) {
        case '@@redux-form/SET_SUBMIT_SUCCEEDED':
          return {
            ...state,
            formSubmitted: true,
          };
        default:
          return state;
      }
    },
    userRegistration: (state, action) => {
      switch (action.type) {
        case '@@redux-form/SET_SUBMIT_SUCCEEDED':
          return {
            ...state,
            formSubmitted: true,
          };
        default:
          return state;
      }
    },
    account: (state, action) => {
      switch (action.type) {
        // case "@@redux-form/SET_SUBMIT_SUCCEEDED" :
        //   return {
        //     ...state,
        //     values: {
        //
        //     }
        //   }
        default:
          return state;
      }
    },
    transaction: (state, action) => {
      switch (action.type) {
        case '@@redux-form/CHANGE':
          return {
            ...state,
            values: {
              ...state.values,
              formChange: true,
            },
          };
        default:
          return state;
      }
    },
  }),
});
