import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { logoutUser } from '../../actions/userActions';

import LeftSideBar from './components/LeftSideBar';

const LogoImage = styled.img`
  vertical-align: bottom;
  margin-right: 10px;
`;

export default function Nav() {
  const { pathname } = useLocation();
  const isLoginPage = pathname === '/login';

  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const app = useSelector((store) => store.app);

  const [showMenu, setShowMenu] = useState(false);

  const handleClickOutside = (e) => {
    const path = e.path || (e.composedPath && e.composedPath());

    if (path) {
      const leftSidebar = document.getElementsByClassName('left-sidebar')[0];
      const modalView = document.getElementsByClassName('modal-window')[0];
      const buttonShowFixedMenu = document.getElementsByClassName('burger-button')[0];

      if (path.includes(buttonShowFixedMenu)) {
        setShowMenu(true);
      } else if (!path.includes(leftSidebar)) {
        if (!path.includes(modalView)) {
          setShowMenu(false);
        }
      }
    }
  };

  return (
    <>
      {user.token && (
      <LeftSideBar
        windowWidth={app.windowWidth}
        showMenu={showMenu}
        hideMenu={() => setShowMenu(false)}
        handleClickOutside={handleClickOutside}
      />
      )}
      <header className="app-main-header">
        <div className="container container_full-width">
          <div className="app-main-header__fixed">
            {user.token && (
            <span
              className="burger-button"
              onClick={() => setShowMenu(true)}
            >
              <i className="fa fa-bars" aria-hidden="true" />
            </span>
            )}
            {isLoginPage || (
              <div className="smaller-navigation-menu">
                <NavLink
                  exact
                  to="/"
                  className="navigation-menu__item"
                  activeClassName="navigation-menu__item_active"
                  title="Журнал операций"
                >
                  <i className="fa fa-book" aria-hidden="true" />
                </NavLink>
                <NavLink
                  exact
                  to="/reports"
                  className="navigation-menu__item"
                  activeClassName="navigation-menu__item_active"
                  title="Отчет"
                >
                  <i className="fa fa-pie-chart" aria-hidden="true" />
                </NavLink>
                <NavLink
                  exact
                  to="/calendar"
                  className="navigation-menu__item"
                  activeClassName="navigation-menu__item_active"
                  title="Календарь"
                >
                  <i className="fa fa-calendar" aria-hidden="true" />
                </NavLink>
              </div>
            )}
          </div>
          <div className="app-main-header__logo">
            <LogoImage src="/images/logo.png" title="Дожить до зарплаты" />
            Дожить до зарплаты
          </div>
          {isLoginPage || (
            user.token ? (
              <div className="app-main-header__login">
                <Link
                  className="app-login-btn"
                  to="/login"
                  onClick={() => dispatch(logoutUser())}
                >
                  Выйти
                </Link>
              </div>
            ) : (
              <Link className="app-login-btn" to="/login">Войти</Link>
            )
          )}
        </div>
      </header>
      {user.token && (
        <div className="app-navigation">
          <div className="navigation-menu">
            <NavLink
              exact
              to="/"
              className="navigation-menu__item"
              activeClassName="navigation-menu__item_active"
            >
              <i className="fa fa-book" aria-hidden="true" />
                  &nbsp; Журнал операций
            </NavLink>
            <NavLink
              exact
              to="/reports"
              className="navigation-menu__item"
              activeClassName="navigation-menu__item_active"

            >
              <i className="fa fa-pie-chart" aria-hidden="true" />
                  &nbsp; Отчет
            </NavLink>
            <NavLink
              exact
              to="/calendar"
              className="navigation-menu__item"
              activeClassName="navigation-menu__item_active"

            >
              <i className="fa fa-calendar" aria-hidden="true" />
                  &nbsp; Календарь
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
}
