import classNames from 'classnames';
import { withFormsy } from 'formsy-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function TextInput(props) {
  const {
    errorMessage,
    isValue,
    name,
    needToReset,
    placeholder,
    resetValue,
    showError,
    setValue,
    title,
    type,
    value,
  } = props;

  const profile = useSelector((store) => store.profileInfo.profile);

  useEffect(() => {
    if (needToReset && needToReset.resetValue && value) {
      resetValue();
      if (isValue) isValue(false);
    }
  }, [props]);

  return (
    <div className={classNames('form-group wide-form', { 'form-group_error': showError })}>
      <label htmlFor={name}>{title}</label>
      <input
        type={type || 'text'}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          if (isValue) isValue(!!e.target.value.length);
        }}
        value={value || ''}
        autoComplete={type === 'password' ? 'new-password' : 'none'}
      />
      <span className="validation-error">
        {errorMessage}
      </span>
      {!errorMessage && type === 'email' && value === profile.email && (
        <span className={classNames('validation-error', { 'email-confirmed': profile.emailConfirmed })}>
          {profile.emailConfirmed ? '✔ E-mail подтверждён' : '✗ E-mail не подтверждён'}
        </span>
      )}
    </div>
  );
}

export default withFormsy(TextInput);
