import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCalendar, setFilterCalendar } from '../../../actions/calendarActions';
import months from '../../../util/months';

const initialParams = {
  month: moment().format('MM'),
  year: moment().format('YYYY'),
  period: moment().format('MMMM').toLowerCase(),
};

export default function CalendarFilter() {
  const [filterParams, setFilterParams] = useState(initialParams);

  const dispatch = useDispatch();
  const filterCalendar = useSelector((store) => store.calendar.filterParam);

  const setMonth = (month) => {
    setFilterParams({ ...filterParams, month });
    dispatch(fetchCalendar({ month, year: filterParams.year }));
    dispatch(setFilterCalendar([month, filterParams.year]));
  };

  const setYear = (year) => {
    setFilterParams({ ...filterParams, year });
    dispatch(fetchCalendar({ month: filterParams.month, year }));
    dispatch(setFilterCalendar([filterParams.month, year.toString()]));
  };

  useEffect(() => {
    if (filterCalendar.length) dispatch(setFilterCalendar([initialParams.month, initialParams.year]));
  }, []);

  return (
    <div className="scroll-period-menu_filter">
      <div className="scroll-period__item  item__year">
        <span
          className="previous-year"
          onClick={() => setYear(+filterParams.year - 1)}
        >
          &#60;
        </span>
        &nbsp;
        {filterParams.year}
        &nbsp;
        <span
          className="next-year"
          onClick={() => setYear(+filterParams.year + 1)}
        >
          &#62;
        </span>
      </div>
      {months.map((month) => (
        <div className="scroll-period__item" key={month.number}>
          <span
            className={filterParams.month === month.number ? 'item__month item__month_active' : 'item__month'}
            onClick={() => setMonth(month.number)}
          >
            {month.name}
          </span>
        </div>
      ))}
    </div>
  );
}
