import React from 'react';

import Accounting from '../../../util/accounting';

Accounting.settings = {
  currency: {
    symbol: '₽',
    format: '%v %s',
    decimal: ',',
    thousand: ' ',
    precision: 2,
  },
  number: {
    precision: 2,
    thousand: ' ',
    decimal: ',',
  },
};

Number.prototype.toCurrencyString || (Number.prototype.toCurrencyString = function () {
  const val = (this instanceof Number) ? this.valueOf() : this;
  return Accounting.formatNumber.apply(this, [val].concat(Array.prototype.slice.call(arguments, 0)));
});

export default function ToolTip(props) {
  const { tooltip } = props;

  const width = 120;
  const height = 32;
  const transformText = `translate(${width / 2},${height / 2 - 5})`;

  let visibility = 'hidden';
  let transform = '';

  let x = 0;
  let y = 0;

  const amount = +tooltip.data.value;

  if (tooltip.display === true) {
    const position = tooltip.pos;

    x = position ? position.clientX : 0;
    y = position ? position.clientY : 0;

    visibility = 'visible';

    if (y > height) {
      transform = `translate(${x - 450},${y - 550})`;
    } else if (y < 865) {
      transform = `translate(${x - 450},${y - 250}) rotate(180,20,0)`;
    }
  } else {
    visibility = 'hidden';
  }

  return (
    <g transform={transform} dy="10">
      <rect
        className="rect-bg"
        width={width + 2}
        height={height + 2}
        x="-1"
        y="-1"
        visibility={visibility}
        opacity="0.5"
      />
      <rect
        className="shadow"
        width={width}
        height={height}
        x="0"
        y="0"
        visibility={visibility}
        opacity="0.1"
      />
      <text
        visibility={visibility}
        transform={transformText}
      >
        <tspan
          textAnchor="middle"
          x="0"
          y="15"
          fontSize="14px"
          style={{ fontWeight: 700 }}
        >
          {amount.toCurrencyString()}
        </tspan>
        <tspan
          textAnchor="middle"
          x="0"
          y="2"
          fontSize="14px"
        >
          {tooltip.data.key}
        </tspan>
      </text>
    </g>
  );
}
