import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TransactionsList from './TransactionsList/TransactionsList';

import { fetchAccounts } from '../../../actions/accountsActions';
import { fetchCategories } from '../../../actions/categoriesActions';
import {
  showCreateTransaction,
  showDeleteTransaction,
  showEditTransaction,
} from '../../../actions/modalActions';
import {
  fetchTransactions,
} from '../../../actions/transactionsActions';
import Loader from '../../../components/UI/Loader';

const TransactionContentLeft = styled.div`
  flex-grow: 2;
  padding-bottom: 100px;
  left: 65px;
  right: 717px;
  bottom: 0;
  top: 230px;
  height: 100%;
  margin-right: 650px;
  
  @media(max-width: 1680px) {
    margin-right: 550px;
  }
  
  @media(max-width: 1200px) {
    margin-right: 0;
  }
`;

const TransactionsListWrapper = styled.div`
  position: relative;
`;

const ContentHeadLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  background-color: #fff;
  text-align: right;
  
  @media(max-width: 1680px) {
    right: 630px;
  }
  
  @media(max-width: 1200px) {
    right: 65px;
  }
`;

const initialFilters = {
  page: 1,
  perPage: 15,
};

export default function Transactions(props) {
  const {
    dateFromCalendarCell,
    defaultDateFromCalendarCell,
    idFromCalendarCell,
    defaultIdFromCalendarCell,
    targetId,
  } = props;

  const dispatch = useDispatch();
  const [filters, setFilters] = useState(initialFilters);
  const [canShowTransactions, setCanShowTransactions] = useState(false);
  const transactionsList = useSelector((store) => store.transactionsList.transactionsList);
  const pager = useSelector((store) => store.transactionsList.pager);
  const fetching = useSelector((store) => store.transactionsList.fetching);
  const isTargetId = targetId !== undefined && targetId !== null;

  const onDeleteTransactionClick = (transaction) => {
    dispatch(showDeleteTransaction(transaction));
  };

  const onEditTransactionClick = (transaction) => {
    dispatch(showEditTransaction(transaction));
  };

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchCategories());
    return () => dispatch({ type: 'RESET_TRANSACTIONS' });
  }, []);

  useEffect(() => {
    if (!transactionsList.length) {
      if (isTargetId) {
        const transactionFilters = { ...filters, transaction: targetId };
        dispatch(fetchTransactions(transactionFilters));
      } else {
        dispatch(fetchTransactions(filters));
        setCanShowTransactions(true);
      }
    } else if (!canShowTransactions) {
      const index = transactionsList.findIndex((trans) => trans.id === targetId);
      // В массиве должно быть как минимум ещё 10 транзакций после требуемой, чтобы можно было её прокрутить до верха.
      // Если она ближе к концу массива и есть возможность загрузить ещё одну страницу, то делаем это...
      if (index > transactionsList.length - 10 && pager.page < pager.lastPage) {
        const nextPageFilters = { ...filters, page: pager.page + 1 };
        dispatch(fetchTransactions(nextPageFilters, true));
      } else {
        // В противном случае показываем транзакции
        setCanShowTransactions(true);
      }
    }
  }, [transactionsList]);

  useEffect(() => {
    if (filters.page !== 1) dispatch(fetchTransactions(filters, true));
  }, [filters]);

  return (
    <TransactionContentLeft>
      <ContentHeadLeft>
        <span className="content-caption-head">Операции</span>
        <div className="add-transaction">
          <button
            type="button"
            className="btn add-transaction__btn"
            onClick={() => dispatch(showCreateTransaction())}
          >
            <i className="fa fa-plus-circle" aria-hidden="true" />
            &nbsp; Добавить операцию
          </button>
        </div>
      </ContentHeadLeft>
      <TransactionsListWrapper>
        {fetching && <Loader />}
        {canShowTransactions && (
          <TransactionsList
            dateFromCalendarCell={dateFromCalendarCell}
            defaultDateFromCalendarCell={defaultDateFromCalendarCell}
            idFromCalendarCell={idFromCalendarCell}
            defaultIdFromCalendarCell={defaultIdFromCalendarCell}
            onDeleteTransactionClick={onDeleteTransactionClick}
            onEditTransactionClick={onEditTransactionClick}
            setFilters={setFilters}
          />
        )}
      </TransactionsListWrapper>
    </TransactionContentLeft>
  );
}
