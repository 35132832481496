export default function reducer(state = {
  activeAccount: '',
  accountsFiltered: [],
  activeType: '1',
  categoriesFiltered: [],
  transitionType: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'SET_ACTIVE_ACCOUNT': {
      return {
        ...state,
        activeAccount: action.payload,
        accountsFiltered: action.accounts.filter((account) => account.id !== +action.payload),
      };
    }
    case 'SET_ACTIVE_TYPE': {
      return {
        ...state,
        activeType: action.payload,
        categoriesFiltered: action.categories.filter((category) => category.type === +action.payload - 1),
      };
    }
    case 'CLEAR_ACTIVE_TYPE': {
      return {
        ...state,
        activeType: '1',
        categoriesFiltered: [],
      };
    }
    case 'SET_TRANSITION_TYPE': {
      return {
        ...state,
        transitionType: true,
      };
    }
    case 'CLEAR_TRANSITION_TYPE': {
      return {
        ...state,
        transitionType: false,
      };
    }
    default: {
      return state;
    }
  }
}
