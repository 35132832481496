import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import getWeekDayName from '../../../../../data/weekDayNames';

import TransactionsItem from './TransactionsItem';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  &:hover {
    thead {
      background-color: #1d6d7e;
      color: #ffffff;
    }
  }
`;

const Header = styled.div`
  width: 100%;
  background-color: #d9e2e5;
  display: flex;
  justify-content: space-between;
  padding: 7px 20px;
`;

const Date = styled.span`
  text-align: left;
  &:before {
    content: '';
    display: ${({ isToday }) => (isToday ? 'inline-block' : 'none')};
    width: 10px; 
    height: 10px;
    border-radius: 50%;
    margin-right 6px;
    background-color: rgb(0, 194, 100);
  }
`;

const Day = styled.span`
  text-align: right;
`;

const List = styled.ul`
  z-index:100;
  position: relative;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  text-transform: none;
`;

export default function TransactionsDate(props) {
  const {
    transactions,
    dateFromCalendarCell,
    idFromCalendarCell,
    onDeleteTransactionClick,
    onEditTransactionClick,
    transactionIsFound,
    setTransactionIsFound,
  } = props;
  const transactionDateRef = useRef(null);

  useEffect(() => {
    if (idFromCalendarCell) return;
    if (dateFromCalendarCell === transactions.dateTogether) {
      if (!transactionIsFound) setTransactionIsFound(transactionDateRef);
    }
  }, [dateFromCalendarCell]);

  const isTransactionDayToday = moment(transactions.dateTogether).locale('ru').format('DD.MM.YYYY') === moment().locale('ru').format('DD.MM.YYYY');
  const isTransactionYearToday = moment(transactions.dateTogether).locale('ru').format('YYYY') === moment().format('YYYY');

  const transactionFormattedDate = () => {
    if (isTransactionYearToday) {
      return moment(transactions.dateTogether).locale('ru').format('D MMMM');
    }
    return moment(transactions.dateTogether).locale('ru').format('DD MMMM YYYY');
  };

  return (
    <Wrapper ref={transactionDateRef}>
      <Header>
        <Date isToday={isTransactionDayToday}>
          { transactionFormattedDate() }
        </Date>
        <Day>
          {getWeekDayName(moment(transactions.dateTogether))}
        </Day>
      </Header>
      <List>
        {transactions.transactions.map((transaction) => (
          <TransactionsItem
            key={transaction.id}
            transaction={transaction}
            idFromCalendarCell={idFromCalendarCell}
            transactionIsFound={transactionIsFound}
            setTransactionIsFound={setTransactionIsFound}
            onEditTransactionClick={onEditTransactionClick}
            onDeleteTransactionClick={onDeleteTransactionClick}
          />
        ))}
      </List>
    </Wrapper>
  );
}
