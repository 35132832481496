import _ from 'lodash';

const initialPager = {
  firstPage: 1,
  lastPage: 1,
  maxPerPage: 10,
  more: 0,
  nbResults: 0,
  page: 1,
};

export default function reducer(state = {
  transactionsList: [],
  fetching: false,
  fetched: false,
  positionTop: '',
  error: null,
  pager: initialPager,
}, action) {
  switch (action.type) {
    case 'FETCH_TRANSACTIONS': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_TRANSACTIONS_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    }
    case 'FETCH_TRANSACTIONS_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        transactionsList: action.payload.items,
        pager: action.payload.pager,
      };
    }
    case 'FETCH_TRANSACTIONS_MORE_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        transactionsList: state.transactionsList.concat(action.payload.items),
        pager: action.payload.pager,
      };
    }
    case 'RESET_TRANSACTIONS': {
      return {
        ...state,
        transactionsList: [],
      };
    }
    case 'CREATE_TRANSACTION_FETCHING': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'CREATE_TRANSACTION_SUCCESS': {
      const manipulateCurrentTrans = {
        ...state,
        fetching: false,
        transactionsList: [
          ...state.transactionsList,
        ],
      };
      const lengthObjectsDateCreate = [];
      let enableTransactionsWithNewTransDate = false;

      state.transactionsList.forEach((trans) => {
        if (trans.dateOther === action.payload.dateOther) {
          lengthObjectsDateCreate.push(trans);
        }
        if (trans.date === action.payload.dateOther) {
          action.payload.date = null;
          enableTransactionsWithNewTransDate = true;
        }
      });
      let sort = [];
      let indexPasteDisabledTransactionsDate = null;

      manipulateCurrentTrans.transactionsList.splice(lengthObjectsDateCreate.length, 0, action.payload);
      sort = _.reverse(_.sortBy(manipulateCurrentTrans.transactionsList, ['dateTogether'], ['decs']));
      indexPasteDisabledTransactionsDate = _.findIndex(sort, (transaction) => action.payload.id === transaction.id);

      const newArrayTransactions = {
        ...state,
        fetching: false,
        transactionsList: [
          ...state.transactionsList,
        ],
      };

      if (!enableTransactionsWithNewTransDate) {
        newArrayTransactions.transactionsList.splice(indexPasteDisabledTransactionsDate, 0, action.payload);
        return newArrayTransactions;
      }

      const correctIndexPast = indexPasteDisabledTransactionsDate === 0
        ? indexPasteDisabledTransactionsDate + lengthObjectsDateCreate.length
        : indexPasteDisabledTransactionsDate;
      newArrayTransactions.transactionsList.splice(correctIndexPast, 0, action.payload);
      return newArrayTransactions;
    }
    case 'CREATE_TRANSACTION_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    }
    case 'EDIT_TRANSACTION_FETCHING': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'EDIT_TRANSACTION_SUCCESS': {
      if (action.dateBeforeChange !== null) {
        // объект без текущей транзакции, для следующей манипуляции
        let clear = [];
        // скопировали текущий state
        const manipulateCurrentTrans = {
          ...state,
          fetching: false,
          transactionsList: [
            ...state.transactionsList,
          ],
        };

        // если после удаляемой транзакции есть транзакции с такой же датой, присваиваем значение date
        manipulateCurrentTrans.transactionsList.forEach((trans, index) => {
          if (action.payload.id === trans.id) {
            if (action.payload.date !== null) {
              if (manipulateCurrentTrans.transactionsList[index + 1].dateOther === trans.date) {
                manipulateCurrentTrans.transactionsList[index + 1].date = trans.date;
              }
            }
          }
        });

        clear = {
          ...state,
          fetching: false,
          transactionsList: [
            ...manipulateCurrentTrans.transactionsList.filter((trans) => trans.id !== action.payload.id),
          ],
        };
        const lengthObjectsDateCreate = [];
        const lengthOther = [];

        state.transactionsList.forEach((trans) => {
          if (trans.dateOther !== action.payload.dateOther && !lengthObjectsDateCreate.length) {
            lengthOther.push(trans);
          } else if (trans.dateOther === action.payload.dateOther) {
            lengthObjectsDateCreate.push(trans);
          }
          if (trans.date === action.payload.dateOther) {
            action.payload.date = null;
          }
        });
        // ToDo: -1 компромисное решение, пересмотреть, работает норм перемещая транзакцию вниз по календарю
        console.log('Транзакция вниз по календарю');
        clear.transactionsList.splice((lengthObjectsDateCreate.length + lengthOther.length) - 1, 0, action.payload);
        return clear;
      }

      const stateTest = {
        ...state,
        fetching: false,
      };

      const clearTest = {
        ...stateTest,
        transactionsList: [
          ...stateTest.transactionsList.filter((trans) => trans.id !== action.payload.id),
        ],
      };

      const index = _.findIndex(stateTest.transactionsList, (transaction) => action.payload.id === transaction.id);
      stateTest.transactionsList.filter((trans) => trans.id !== action.payload.id);

      clearTest.transactionsList.splice(index, 0, action.payload);
      // если у транзакции была дата - присвоим старое значение
      clearTest.transactionsList[index].date = state.transactionsList[index].date;
      window.console.log(clearTest);
      return clearTest;
    }
    case 'EDIT_TRANSACTION_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    }
    case 'DELETE_TRANSACTION_SUCCESS': {
      const manipulateCurrentTrans = {
        ...state,
        fetching: false,
        transactionsList: [
          ...state.transactionsList,
        ],
      };

      if (manipulateCurrentTrans.transactionsList.length > 2) {
        manipulateCurrentTrans.transactionsList.forEach((trans, index) => {
          if (action.payload.id === trans.id) {
            if (action.payload.date !== null) {
              if (manipulateCurrentTrans.transactionsList[index + 1].dateOther === action.payload.date) {
                manipulateCurrentTrans.transactionsList[index + 1].date = action.payload.date;
              }
            }
          }
        });
      }

      return {
        ...manipulateCurrentTrans,
        transactionsList: [
          ...manipulateCurrentTrans.transactionsList.filter((trans) => trans.id !== action.payload.id),
        ],
      };
    }
    case 'DELETE_TRANSACTION_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    }
    case 'SET_TRANSACTIONS_LIST_POSITION_TOP': {
      return {
        ...state,
        fetching: false,
        positionTop: action.payload,
      };
    }
    default:
      return state;
  }
}
