export function setActiveAccount(accountId, accounts) {
  return function (dispatch) {
    dispatch({ type: 'SET_ACTIVE_ACCOUNT', payload: accountId, accounts });
  };
}

export function setActiveType(transactionType, categories) {
  return function (dispatch) {
    dispatch({ type: 'SET_ACTIVE_TYPE', payload: transactionType, categories });
  };
}

export function clearActiveType() {
  return function (dispatch) {
    dispatch({ type: 'CLEAR_ACTIVE_TYPE' });
  };
}

export function setTransitionType() {
  return function (dispatch) {
    dispatch({ type: 'SET_TRANSITION_TYPE' });
  };
}

export function clearTransitionType() {
  return function (dispatch) {
    dispatch({ type: 'CLEAR_TRANSITION_TYPE' });
  };
}
