import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCategories } from '../../actions/categoriesActions';
import DonutChart from '../../components/DonutChart/DonutChart';
import LineChart from '../../components/LineChart/LineChart';
import PeriodFilter from '../../components/UI/PeriodFilter';

export default function Reports() {
  const dispatch = useDispatch();
  const donutTurnover = useSelector((store) => store.donutTurnover);
  const lineTurnover = useSelector((store) => store.lineTurnover);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  return (
    <>
      <PeriodFilter />
      <div className="container_full-width">
        <div className="reports">
          <div className="reports__linear">
            <LineChart
              data={lineTurnover.lineTurnover}
            />
          </div>
          <div className="reports__donut">
            <DonutChart
              amount={donutTurnover.expenseSum}
              data={donutTurnover.expenseDonutTurnover}
            />
            <DonutChart
              amount={donutTurnover.profitSum}
              data={donutTurnover.profitDonutTurnover}
              isProfit
            />
          </div>
        </div>
      </div>
    </>
  );
}
